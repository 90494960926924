
import { APP_WILL_MOUNT, APP_WILL_UNMOUNT } from '../base/app';
import {
    CONFERENCE_JOINED,
    getCurrentConference
} from '../base/conference';
import { MiddlewareRegistry, StateListenerRegistry } from '../base/redux';







StateListenerRegistry.register(
    state => getCurrentConference(state),
    (conference, { dispatch, getState }, previousConference) => {
        if (conference !== previousConference) {          

            if (getState()['features/callend'].show) {               
                dispatch(toggleReduxPoll());
            }

        }
    });


