// @flow

import { FieldTextAreaStateless } from '@atlaskit/field-text-area';
import StarIcon from '@atlaskit/icon/glyph/star';
import StarFilledIcon from '@atlaskit/icon/glyph/star-filled';
import React, { Component, useEffect } from 'react';
import { connect } from '../../base/redux';
import type { Dispatch } from 'redux';
import QuizModal from './QuizModal';
import { Dialog } from '../../base/dialog';
import { translate } from '../../base/i18n';
import { cancelPoll, submitFeedback } from '../actions';
import axios from 'axios';
import { getAPIURL, getConferenceName } from '../../base/conference';
import { jitsiLocalStorage } from '@jitsi/js-utils';
import { getProfileTabProps } from '../../settings/functions';
import { getNumericCodes } from 'i18n-iso-countries';
import { hideDialog } from '../../../features/base/dialog';
declare var APP: Object;
declare var interfaceConfig: Object;

const scoreAnimationClass
    = interfaceConfig.ENABLE_FEEDBACK_ANIMATION ? 'shake-rotate' : '';

/**
 * The scores to display for selecting. The score is the index in the array and
 * the value of the index is a translation key used for display in the dialog.
 *
 * @types {string[]}
 */


/**
 * The type of the React {@code Component} props of {@link PollDialog}.
 */
type Props = {

    /**
     * The cached feedback message, if any, that was set when closing a previous
     * instance of {@code PollDialog}.
     */
    _message: string,

    /**
     * The cached feedback score, if any, that was set when closing a previous
     * instance of {@code PollDialog}.
     */
    _score: number,

    /**
     * The JitsiConference that is being rated. The conference is passed in
     * because feedback can occur after a conference has been left, so
     * references to it may no longer exist in redux.
     */
    conference: Object,

    openModal: Object,

    /**
     * Invoked to signal feedback submission or canceling.
     */
    dispatch: Dispatch<any>,

    /**
     * Callback invoked when {@code PollDialog} is unmounted.
     */
    onClose: Function,

    /**
     * Invoked to obtain translated strings.
     */
    t: Function,
    _username: string,
    _email: string,
    _defaultAPIUrl: string
};

/**
 * The type of the React {@code Component} state of {@link PollDialog}.
 */


/**
 * A React {@code Component} for displaying a dialog to rate the current
 * conference quality, write a message describing the experience, and submit
 * the feedback.
 *
 * @extends Component
 */
class PollDialog extends Component<Props, State> {
    /**
     * An array of objects with click handlers for each of the scores listed in
     * the constant SCORES. This pattern is used for binding event handlers only
     * once for each score selection icon.
     */
    _scoreClickConfigurations: Array<Object>;

    /**
     * Initializes a new {@code PollDialog} instance.
     *
     * @param {Object} props - The read-only React {@code Component} props with
     * which the new instance is to be initialized.
     */
    constructor(props: Props) {
        super(props);
        const { _message, _score } = this.props;

        this.state = {
            /**
             * The currently entered feedback message.
             *
             * @type {string}
             */
            isLoaded: false,
            item: '',
            show: false,
            title: '',
            question: '',
            radioButton: '',
            singleAns1: '',
            singleAns2: '',
            multipleAns1: '',
            multipleAns2: '',
            multipleAns3: '',
            multipleAns4: '',
            multipleAns5: '',
            multipleAns6: '',
            freeAns: ["null"],
            singleChoice: [],
            multipleChoice: [],
            addQuestionOnClick: 'click',
            arraytest: [],
            getQuestion: [],
            setQuestion: [],
            setQuestionError: [],
            setQuestionMultiple: [],
            setQuestionFreeText: [],
            setSingleAns: "",
            radioChecked: "",
            CheckBoxAns: [],
            freeTextValue: "",
            titleError: "",
            finalRecordsArray: [],
            myArr: [],
            setAddQuestionArray: [],
            questionNo: 1,
            person: [],
            quizIds: [],
            quizData: [],
            _isSubmiting: false


        };

        this.handelOnchange = this.handelOnchange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.handelQuesOnChange = this.handelQuesOnChange.bind(this);
        this.handelSingleChange = this.handelSingleChange.bind(this);
        this.AddQuestion = this.AddQuestion.bind(this);
        this.submitAnswer = this.submitAnswer.bind(this);
        this.hiddenValue = this.hiddenValue.bind(this);
        this.setCheckBoxAnswer = this.setCheckBoxAnswer.bind(this);
        this.setFreetextAnswer = this.setFreetextAnswer.bind(this);
        this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
        this.handleAnswerOptionClick = this.handleAnswerOptionClick.bind(this);
        this.AddMoreQuestion = this.AddMoreQuestion.bind(this);
        this.clearAllFileds = this.clearAllFileds.bind(this);
        this._onCancel = this._onCancel.bind(this);
        this._onSubmit = this._onSubmit.bind(this);

    }


    componentDidMount() {
        var apiDomain = this.props._defaultAPIUrl;
        fetch(apiDomain + "/quiz/getQuizNameMeetingCode/" + APP.conference.roomName)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.length > 0) {
                        result.forEach(element => {
                            this.setState({ quizIds: element })
                        });
                        //  console.log(this.state.quizIds.quizid);
                        fetch(apiDomain + "/quiz/getQuizQuestionFullData/" + this.state.quizIds.quizid)
                            .then(res => res.json())
                            .then(
                                (result) => {
                                    result.quizAndPollNews.forEach(element => {
                                        this.setState({ setQuestion: [...this.state.setQuestion, element] })
                                    });
                                    this.setState({ quizData: result.quizData })
                                    // console.log(this.state.quizData);
                                    //console.log(JSON.stringify(result.quizAndPollNews))
                                }
                            )
                    }
                    else {
                        this.setState({
                            setQuestion: [
                                {
                                    question: "Quiz not data found...",
                                    questionType: "Error"
                                }
                            ]
                        }
                        )

                        console.log(this.state.setQuestion)


                    }
                })

    }

    componentWillUnmount() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    handelOnchange(event) {
        event.preventDefault();
        this.state.title = event.target.value
        this.onChangeValue(event);
    }
    handleSubmit(event) {


    }
    AddQuestion(event) {

        if (event.type == 'click') {
            this.state.addQuestionOnClick = event.type

        }

    }
    onChangeValue(event) {
        this.setState({
            radioButton: event.target.value
        });
        this.state.radioButton = event.target.value
        if (this.state.radioButton == "single") {
            this.state.multipleAns1 = '';
            this.state.multipleAns2 = '';
            this.state.multipleAns3 = '';
            this.state.multipleAns4 = '';
            this.state.multipleAns5 = '';
            this.state.multipleAns6 = '';
            this.state.freeAns = '';
        } else if (this.state.radioButton == "multiple") {
            this.state.freeAns = '';
            this.state.singleAns1 = '';
            this.state.singleAns2 = '';
        } else if (this.state.radioButton == "free") {
            this.state.multipleAns1 = '';
            this.state.multipleAns2 = '';
            this.state.multipleAns3 = '';
            this.state.multipleAns4 = '';
            this.state.multipleAns5 = '';
            this.state.multipleAns6 = '';
            this.state.singleAns1 = '';
            this.state.singleAns2 = '';
        }
    }
    handelQuesOnChange(e) {
        e.preventDefault();
        this.state.question = e.target.value;
        this.onChangeValue(e);
    }

    handelSingleChange(e) {
        e.preventDefault();
        this.state.singleAns = e.target.value;

    }
    AddMoreQuestion = (event) => {
        let setAddQuestion = [...this.state.setAddQuestionArray];
    }
    submitAnswer(event) {
        if (this.state._isSubmiting) {
            return;
        }

        if (this.state.finalRecordsArray.length != this.state.setQuestion.length) {
            alert('Please give your answers.!');
            return;
        }


        this.setState({ _isSubmiting: true });

        let arr = this.state.finalRecordsArray;
        //console.log(arr);
        let obj = [];
        if (arr != null) {
            for (var i = 0; i < arr.length; i++) {
                obj.push({
                    id: arr[i].id,
                    question: arr[i].que,
                    answer: arr[i].ans,
                    questiontype: arr[i].questionType,
                    answertime: arr[i].answertime
                }
                )
            }
        }
        var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        const datetime = date + " " + time;

        const payload = {
            userid: this.state.quizData.user_id,
            org_id: this.state.quizData.org_id,
            quizdate: datetime,
            meetingcode: APP.conference.roomName,
            quizid: this.state.quizData.id,
            usermail: this.props._email,
            user_name: this.props._username,
            quizdata: obj
        }
        var apiDomain = this.props._defaultAPIUrl;
        axios.post(apiDomain + '/quiz/insertUserPollData', payload)
            .then(response => {
                console.log(response)
                alert("Thanks for showing interest");
                this.setState({ _isSubmiting: false });
                this.props.dispatch(hideDialog());
            })
            .catch(error => {
                console.log(error)
                this.setState({ _isSubmiting: false });

            })

    }
    hiddenValue = ({ target }) => {
        const { value: freeAns } = target;
        this.setState({ freeAns })

    }
    //......................set the  Radio button values ---------------------
    setRadioAnswers = (id, target, question, questionType, answertime) => {
        this.setState({
            radioChecked: target.target.value
        });
        this.state.setSingleAns = target.target.value;
        let ansCheckList = [];
        let finalRecordsArrays = [...this.state.finalRecordsArray];
        let resultArray = [];
        let obj = {};
        if (finalRecordsArrays.length > 0) {
            // let newArray = finalRecordsArrays.filter((item)=> item.id !== id &&  item.questionType === 'singleChoice' );
            let newArray = finalRecordsArrays.filter((item) => item.id !== id);
            obj['id'] = id;
            obj['que'] = question;
            ansCheckList.push(target.target.value);
            obj['ans'] = ansCheckList;
            obj['questionType'] = questionType;
            obj['answertime'] = answertime;
            newArray.push(obj);

            resultArray = newArray;

        }
        else {
            obj['id'] = id;
            obj['que'] = question;
            ansCheckList.push(target.target.value);
            obj['ans'] = ansCheckList;
            obj['questionType'] = questionType;
            finalRecordsArrays.push(obj);
            obj['answertime'] = answertime;
            resultArray = finalRecordsArrays
        }
        this.setState({ finalRecordsArray: resultArray })
        // console.log(JSON.stringify(this.state.finalRecordsArray));

    }
    //---------------------set the checkbox value here ------------------------
    setCheckBoxAnswer(id, event, question, questionType, answertime) {
        let recordList = [];
        let finalRecordsArray = [...this.state.finalRecordsArray];
        let obj = {};
        let ansCheckList = [];
        let newArray = finalRecordsArray.filter((item) => item.id !== id);

        if (finalRecordsArray.length >= 0) {

            //  let checkBoxArray = finalRecordsArray.filter((item) => item.questionType === 'multipleChoice' && item.id===id) ;
            let checkBoxArray = finalRecordsArray.filter((item) => item.id === id);
            if (event.target.checked == false) {
                for (var i = 0; i < checkBoxArray.length; i++) {
                    let quesArray = [];
                    quesArray = checkBoxArray[i].ans;
                    for (var j = 0; j < quesArray.length; j++) {
                        let name = quesArray[j]
                        if (name == event.target.value) {
                            quesArray.splice(j, 1);
                        }
                    }
                }
            }
            if (event.target.checked == true) {
                if (checkBoxArray.length <= 0) {
                    obj['id'] = id;
                    obj['que'] = question;
                    ansCheckList.push(event.target.value);
                    obj['ans'] = ansCheckList;
                    obj['answertime'] = answertime;
                    obj['questionType'] = questionType;
                    finalRecordsArray.push(obj);
                    recordList = finalRecordsArray;
                } else {
                    checkBoxArray.forEach((item) => {
                        if (item.id === id) {
                            ansCheckList = item.ans;
                            ansCheckList.push(event.target.value);
                            obj['ans'] = ansCheckList;
                        }
                    })

                }
            }
        }
        this.setState({ finalRecordsArray: finalRecordsArray })
        //console.log(JSON.stringify(this.state.finalRecordsArray));
    }

    //-------------------------------Check box end  -------------------

    //---------------set the free text value here ----------------------

    setFreetextAnswer = (id, event, question, questionType, answertime) => {
        let recordTextFreeList = [];
        let ansCheckList = [];
        let finalRecordsArray = [...this.state.finalRecordsArray];
        let obj = {};
        //let newArray = finalRecordsArray.filter((item)=> item.question !== question && item.questionType === 'textFree');
        let newArray = finalRecordsArray.filter((item) => item.question !== question);
        if (finalRecordsArray.length > 0) {
            for (var i = 0; i < newArray.length; i++) {
                let ques = newArray[i].que;
                if (ques == question) {
                    finalRecordsArray.splice(i, 1);
                }
            }
        }
        if (event.target.value !== "") {
            obj['id'] = id;
            obj['que'] = question;
            ansCheckList.push(event.target.value);
            obj['ans'] = ansCheckList;
            obj['questionType'] = questionType;
            obj['answertime'] = answertime;
            finalRecordsArray.push(obj);
            recordTextFreeList = finalRecordsArray;
        }
        this.setState({ finalRecordsArray: finalRecordsArray })
        this.state.freeTextValue = event.target.value;
        // console.log(JSON.stringify(this.state.finalRecordsArray));

    }


    handleAnswerSelected(event) {

        const click = event.type;
        alert(click);
        this.state.setQuestion.map((value, index) => {

            alert(value.question);
        });
        //  console.log( this.state.setQuestion);
    }
    handleAnswerOptionClick(event) {

    }
    clearAllFileds = (e) => {
        this.setState({
            title: e.target.value,
            question: e.target.value,
            radioButton: e.target.value

        });
    }


    render() {
        const { t } = this.props;

        return (
            <Dialog
                disableBlanketClickDismiss={true}
                // cancelDisabled={false}
                onCancel={this._onCancel}
                submitDisabled={false}
                backdrop='static'
                // isModal={true}
                // titleKey = 'startPoll.rateExperience'
                titleKey='Starting Quiz Now'
            >
                <QuizModal data={this.state.setQuestion}
                    multidata={this.state.setQuestionMultiple}
                    textFree={this.state.setQuestionFreeText}
                    setRadioAnswers={this.setRadioAnswers}
                    submitAnswer={this.submitAnswer}
                    chekedvalue={this.state.radioChecked}
                    hiddenValue={this.hiddenValue}
                    onchangeCheckBox={this.setCheckBoxAnswer}
                    setFreetextAns={this.setFreetextAnswer}
                    onAnswerSelected={this.handleAnswerSelected}
                    handleAnswerOptionClick={this.handleAnswerOptionClick}
                    validatedata={this.state.finalRecordsArray}
                    QuizTimer={this.state.quizData.duration}
                    onCancelClick={this.props.onClose}
                />
            </Dialog>
        );
    }

    _onCancel: () => boolean;

    /**
     * Dispatches an action notifying feedback was not submitted. The submitted
     * score will have one added as the rest of the app does not expect 0
     * indexing.
     *
     * @private
     * @returns {boolean} Returns true to close the dialog.
     */
    _onCancel() {
        console.log('abcdd')
        const { message, score } = this.state;
        const scoreToSubmit = score > -1 ? score + 1 : score;
        this.props.dispatch(cancelPoll(scoreToSubmit, message));
        return true;
    }


    _onSubmit: () => void;

    /**
     * Dispatches the entered feedback for submission. The submitted score will
     * have one added as the rest of the app does not expect 0 indexing.
     *
     * @private
     * @returns {boolean} Returns true to close the dialog.
     */
    _onSubmit() {
        const { conference, dispatch } = this.props;
        const { message, score } = this.state;

        const scoreToSubmit = score > -1 ? score + 1 : score;

        dispatch(submitFeedback(scoreToSubmit, message, conference));

        return true;
    }


}

/**
 * Maps (parts of) the Redux state to the associated {@code PollDialog}'s
 * props.
 *
 * @param {Object} state - The Redux state.
 * @private
 * @returns {{
 * }}
 */
function _mapStateToProps(state) {

    const { quizUserName, quizUserEmail } = state['features/reduxPoll'];
    const jwt = state['features/base/jwt'];

    return {
        _meetingName: getConferenceName(state),
        _profileData: getProfileTabProps(state),
        _username: quizUserName,
        _email: quizUserEmail,
        _defaultAPIUrl: getAPIURL(state)
    };
}

export default translate(connect(_mapStateToProps)(PollDialog));
