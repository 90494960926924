// @flow

import _ from 'lodash';
import React from 'react';

import VideoLayout from '../../../../../modules/UI/videolayout/VideoLayout';

import { connect, disconnect } from '../../../base/connection';
import { translate } from '../../../base/i18n';
import { connect as reactReduxConnect } from '../../../base/redux';
import { getConferenceNameForTitle } from '../../../base/conference';
import { Chat } from '../../../chat';
import { Filmstrip } from '../../../filmstrip';
import { CalleeInfoContainer } from '../../../invite';
import { LargeVideo } from '../../../large-video';
import { KnockingParticipantList } from '../../../lobby';

import { ParticipantsPane } from '../../../participants-pane/components';
import { getParticipantsPaneOpen } from '../../../participants-pane/functions';

import { Prejoin, isPrejoinPageVisible, LoadingView, isConfigurationPageVisible, isLocationPermissionViewRequired, isBrandPageRequired } from '../../../prejoin';
import { LAYOUTS, getCurrentLayout } from '../../../video-layout';
import { NoteP } from '../../../NoteP';
import {
    Toolbox,
    fullScreenChanged,
    setToolboxAlwaysVisible,
    showToolbox
} from '../../../toolbox';

import { maybeShowSuboptimalExperienceNotification } from '../../functions';

import Labels from './Labels';
import { default as Notice } from './Notice';
import { default as Subject } from './Subject';
import {
    AbstractConference,
    abstractMapStateToProps
} from '../AbstractConference';
import type { AbstractProps } from '../AbstractConference';
import EndCallBanner from '../../../callend/components/EndCallBanner'
import ScheduleEndingBanner from '../../../callend/components/ScheduleEndingBanner';
import EventToolbox from '../../../toolbox/components/web/EventToolbox';
import { VideoContainer } from '../../../../../modules/UI/videolayout/VideoContainer';
import LargeVideoManager from '../../../../../modules/UI/videolayout/LargeVideoManager';
import WebinarSidebar from '../../../webinar-sidebar/components/web/WebinarSidebar';
import WebinarToolbox from '../../../toolbox/components/web/WebinarToolbox';
import { isWebinar, isUserJoined } from '../../../webinar-sidebar/functions.web';
import { Button } from 'react-bootstrap';
import { getLocalParticipant } from '../../../base/participants';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    ModalTransition,
} from '@atlaskit/modal-dialog';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { blue, N500 } from '@atlaskit/theme/colors';
import BrandPrejoin from '../../../prejoin/components/BrandPrejoin';

declare var APP: Object;
declare var config: Object;
declare var interfaceConfig: Object;

/**
 * DOM events for when full screen mode has changed. Different browsers need
 * different vendor prefixes.
 *
 * @private
 * @type {Array<string>}
 */
const FULL_SCREEN_EVENTS = [
    'webkitfullscreenchange',
    'mozfullscreenchange',
    'fullscreenchange'
];

/**
 * The CSS class to apply to the root element of the conference so CSS can
 * modify the app layout.
 *
 * @private
 * @type {Object}
 */
const LAYOUT_CLASSNAMES = {
    [LAYOUTS.HORIZONTAL_FILMSTRIP_VIEW]: 'horizontal-filmstrip',
    [LAYOUTS.TILE_VIEW]: 'tile-view',
    [LAYOUTS.VERTICAL_FILMSTRIP_VIEW]: 'vertical-filmstrip'
};



/**
 * The type of the React {@code Component} props of {@link Conference}.
 */
type Props = AbstractProps & {

    /**
     * Whether the local participant is recording the conference.
     */
    _iAmRecorder: boolean,

    /**
     * The CSS class to apply to the root of {@link Conference} to modify the
     * application layout.
     */
    _layoutClassName: string,

    /**
     * Name for this conference room.
     */
    _roomName: string,


    /**
     * If participants pane is visible or not.
     */
    _isParticipantsPaneVisible: boolean,

    /**
     * If prejoin page is visible or not.
     */
    _showPrejoin: boolean,
    _showConfiguration: boolean,
    isEvent: boolean,
    dispatch: Function,
    t: Function,
    _showNamePrompt: boolean,
    locationPermission: boolean,
    isLocationPermissionViewRequired: boolean,
    isBrandPageRequired: boolean
}

/**
 * The conference page of the Web application.
 */
class Conference extends AbstractConference<Props, *> {
    _onFullScreenChange: Function;
    _onShowToolbar: Function;
    _originalOnShowToolbar: Function;

    /**
     * Initializes a new Conference instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props) {
        super(props);

        // Throttle and bind this component's mousemove handler to prevent it
        // from firing too often.
        this._originalOnShowToolbar = this._onShowToolbar;
        this._onShowToolbar = _.throttle(
            () => this._originalOnShowToolbar(),
            100,
            {
                leading: true,
                trailing: false
            });
        this._notificationTime = new Date().getTime() + (1 * 20 * 1000);
        // Bind event handler so it is only bound once for every instance.
        this._onFullScreenChange = this._onFullScreenChange.bind(this);
        this.state = {
            locationDenied: false
        }
    }

    /**
     * Start the connection and get the UI ready for the conference.
     *
     * @inheritdoc
     */
    componentDidMount() {
        document.title = `${this.props._roomName} | ${interfaceConfig.APP_NAME}`;
        this._start();
        this.getLocation();
        let data = APP.conference.getWelcomePageData();
        data.then(res => {
            console.log("xyzzz", res.data)
            this.setState({
                prejoinRoomData: res.data
            })
        })
    }

    getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition, (error) => {
                if (error.code == error.PERMISSION_DENIED) {
                    console.log("denied")
                    this.setState({
                        locationDenied: true
                    })
                }
            });
        } else {
            console.log("called")
            console.log("Geolocation is not supported by this browser.");
        }
    }

    showPosition = (position) => {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
        console.log("latitude longitude", latitude, longitude)
    }

    locDeniedCallback = (error) => {
        if (error.code == error.PERMISSION_DENIED) {
            alert("location denined")
        }
    }

    /**
     * Calls into legacy UI to update the application layout, if necessary.
     *
     * @inheritdoc
     * returns {void}
     */
    componentDidUpdate(prevProps) {

        if (this.props.isEvent) {
            this.updateViewForWebinar();
        }
        if (this.props._shouldDisplayTileView
            === prevProps._shouldDisplayTileView) {
            return;
        }

        // TODO: For now VideoLayout is being called as LargeVideo and Filmstrip
        // sizing logic is still handled outside of React. Once all components
        // are in react they should calculate size on their own as much as
        // possible and pass down sizings.
        VideoLayout.refreshLayout();



    }

    /**
     * Disconnect from the conference when component will be
     * unmounted.
     *
     * @inheritdoc
     */
    componentWillUnmount() {
        APP.UI.unbindEvents();

        FULL_SCREEN_EVENTS.forEach(name =>
            document.removeEventListener(name, this._onFullScreenChange));

        APP.conference.isJoined() && this.props.dispatch(disconnect());

    }


    updateViewForWebinar() {
        VideoLayout.resizeViewForWebinar();
    }


    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */


    render() {
        const {
            VIDEO_QUALITY_LABEL_DISABLED,

            // XXX The character casing of the name filmStripOnly utilized by
            // interfaceConfig is obsolete but legacy support is required.
            //   filmStripOnly: filmstripOnly
        } = interfaceConfig;
        const {
            _iAmRecorder,
            _layoutClassName,
            _showPrejoin,
            _showConfiguration,
            _isParticipantsPaneVisible,
            isLoadingView,
            isEvent,
            _showNamePrompt
        } = this.props;

        const filmstripOnly = false;
        const hideVideoQualityLabel
            = filmstripOnly
            || VIDEO_QUALITY_LABEL_DISABLED
            || _iAmRecorder;

        //const isEvent=false;   
        console.log("prejoinRoomData", this.state.prejoinRoomData)

        const hideLabels = filmstripOnly || _iAmRecorder;
        const appName = interfaceConfig.APP_NAME
        return (
            <div id='layout_wrapper'>
                <div
                    className={_layoutClassName}
                    id='videoconference_page'
                    onMouseMove={this._onShowToolbar}>

                    <Notice />
                    <Subject />
                    {
                        this.state.locationDenied && this.props.isLocationPermissionViewRequired ? (
                            <ModalTransition >
                                <Modal onClose={() => this.setState({
                                    locationDenied: false
                                })} width="large" className="xyz" shouldCloseOnOverlayClick={false}>
                                    <ModalHeader>
                                        <ModalTitle>Location Denied</ModalTitle>
                                        <Button appearance="link" onClick={() => this.setState({
                                            locationDenied: false
                                        })}>
                                            <CrossIcon label="Close Modal" primaryColor={N500} />
                                        </Button>
                                    </ModalHeader>
                                    <ModalBody>
                                        <h5>Please allow location!</h5>
                                        <p style={{ marginBottom: 10 }}>Location permission is required to join meeting. Please enable location by using guide given below.</p>
                                        <p>
                                            Step 1: Open browser Settings
                                        </p>
                                        <p>
                                            Step 2: Go to Privacy and security
                                        </p>
                                        <p>
                                            Step 3: Go to Site settings<br />
                                        </p>
                                        <img width={700} src="https://www.lifewire.com/thmb/N9ofILFkAFZUDaLWdJphFdH3qKo=/2106x1312/filters:no_upscale():max_bytes(150000):strip_icc()/001-denying-access-to-your-location-4027789-aac660e9d8364a0ba3c2ddd16cdb65ba.jpg" />
                                        <p>
                                            Step 4: Select this website
                                        </p>
                                        <p>
                                            Step 5: Change Location from block to allow
                                        </p>
                                    </ModalBody>
                                    <ModalFooter>
                                    </ModalFooter>
                                </Modal>
                            </ModalTransition>
                        ) : null
                    }
                    <div id='videospace'>
                        {/* TO Show End call banner for end call for all functionality */}
                        <EndCallBanner />
                        {/* TO Show Scheduled call information and controls */}
                        <ScheduleEndingBanner notifyTime={this._notificationTime} />
                        <LargeVideo />
                        {hideVideoQualityLabel
                            || <Labels />}
                        <KnockingParticipantList />
                        {hideLabels || <Labels />}
                        <Filmstrip filmstripOnly={filmstripOnly} />

                    </div>
                    {filmstripOnly || _showPrejoin || isEvent == false && <Toolbox />}
                    {filmstripOnly || !isEvent && <Chat />}
                    {filmstripOnly || <NoteP />}
                    {this.renderNotificationsContainer()}

                    {!filmstripOnly && _showPrejoin && !_showConfiguration && !this.props.locationPermission && <Prejoin />}
                    {!filmstripOnly && !_showConfiguration && this.props.isBrandPageRequired && <BrandPrejoin prejoinRoomData={this.state.prejoinRoomData} />}

                    {!filmstripOnly && _showConfiguration && <LoadingView />}
                    <CalleeInfoContainer />
                    {isEvent && <WebinarToolbox />}
                </div>
                <ParticipantsPane />
                {isEvent && !_showNamePrompt && (!_showPrejoin || isLoadingView) && <WebinarSidebar />}

            </div>
        );
    }

    /**
     * Updates the Redux state when full screen mode has been enabled or
     * disabled.
     *
     * @private
     * @returns {void}
     */
    _onFullScreenChange() {
        this.props.dispatch(fullScreenChanged(APP.UI.isFullScreen()));
    }

    /**
     * Displays the toolbar.
     *
     * @private
     * @returns {void}
     */
    _onShowToolbar() {
        this.props.dispatch(showToolbox());

    }


    /**
     * Until we don't rewrite UI using react components
     * we use UI.start from old app. Also method translates
     * component right after it has been mounted.
     *
     * @inheritdoc
     */
    _start() {
        APP.UI.start();

        APP.UI.registerListeners();
        APP.UI.bindEvents();

        FULL_SCREEN_EVENTS.forEach(name =>
            document.addEventListener(name, this._onFullScreenChange));

        const { dispatch, t } = this.props;

        dispatch(connect());

        maybeShowSuboptimalExperienceNotification(dispatch, t);

        //        interfaceConfig.filmStripOnly&& 
        // dispatch(setToolboxAlwaysVisible(true));
    }
}

/**
 * Maps (parts of) the Redux state to the associated props for the
 * {@code Conference} component.
 *
 * @param {Object} state - The Redux state.
 * @private
 * @returns {Props}
 */
function _mapStateToProps(state) {
    const localParticipant = getLocalParticipant(state);
    return {
        ...abstractMapStateToProps(state),
        _iAmRecorder: state['features/base/config'].iAmRecorder,
        _layoutClassName: LAYOUT_CLASSNAMES[getCurrentLayout(state)],
        _roomName: getConferenceNameForTitle(state),
        _showPrejoin: isPrejoinPageVisible(state),
        _showConfiguration: isConfigurationPageVisible(state),
        _isParticipantsPaneVisible: getParticipantsPaneOpen(state),
        isEvent: isWebinar(state),
        isLoadingView: isUserJoined(state),
        _showNamePrompt: !localParticipant.name,
        locationPermission: state['features/webinar'].locationPermission,
        isLocationPermissionViewRequired: isLocationPermissionViewRequired(state),
        isBrandPageRequired: isBrandPageRequired(state)
    };
}

export default reactReduxConnect(_mapStateToProps)(translate(Conference));
