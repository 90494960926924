import React, { Component,useEffect } from 'react';
import { connect } from '../../base/redux';
import type { Dispatch } from 'redux';
import { Dialog } from '../../base/dialog';
import { translate } from '../../base/i18n';
import { cancelPoll, submitFeedback } from '../actions';
import { getConferenceName } from '../../base/conference';
import { getProfileTabProps } from '../../settings/functions';

declare var APP: Object;
declare var interfaceConfig: Object;

const scoreAnimationClass
    = interfaceConfig.ENABLE_FEEDBACK_ANIMATION ? 'shake-rotate' : '';


type Props = {

   
    _message: string,

   
    _score: number,

    conference: Object,

    openModal:Object,

    /**
     * Invoked to signal feedback submission or canceling.
     */
    dispatch: Dispatch<any>,

    /**
     * Callback invoked when {@code PollDialog} is unmounted.
     */
    onClose: Function,

    /**
     * Invoked to obtain translated strings.
     */
    t: Function
};

class QuizDialogShow extends Component<Props, State> {   
    _scoreClickConfigurations: Array<Object>;   
    constructor(props: Props) {
        super(props);     
        const { _message, _score } = this.props;
        this.state = {           
            isLoaded:false,
            item:'',
            show:false,            
                title:''
        };
        this._onCancel = this._onCancel.bind(this);     
        this._onSubmit = this._onSubmit.bind(this);
      
    }


    
       
    render() {        
         const { t } = this.props;     
        return (
            <Dialog  
                onSubmit={this._onSubmit}
                cancelDisabled={true}
                width='small'
                titleKey = 'startPoll.startQuiz'            
               
                >          
            <div style={{fontSize: "14px"}} >The quiz has been started. The questions will be displayed on participants screens. All new joinees will received questions till you are not stopping running quiz.</div>
            </Dialog>
        );
    }

    _onCancel: () => boolean;

    /**
     * Dispatches an action notifying feedback was not submitted. The submitted
     * score will have one added as the rest of the app does not expect 0
     * indexing.
     *
     * @private
     * @returns {boolean} Returns true to close the dialog.
     */
    _onCancel() {
        const { message, score } = this.state;
        const scoreToSubmit = score > -1 ? score + 1 : score;
        this.props.dispatch(cancelPoll(scoreToSubmit, message));
        return true;
    }


    _onSubmit: () => void;

    /**
     * Dispatches the entered feedback for submission. The submitted score will
     * have one added as the rest of the app does not expect 0 indexing.
     *
     * @private
     * @returns {boolean} Returns true to close the dialog.
     */
    _onSubmit() {
      
        return true;
    }

   
}

/**
 * Maps (parts of) the Redux state to the associated {@code PollDialog}'s
 * props.
 *
 * @param {Object} state - The Redux state.
 * @private
 * @returns {{
 * }}
 */
function _mapStateToProps(state) {
    const { message, score } = state['features/reduxPoll'];
    const jwt = state['features/base/jwt'];

    return {
        _meetingName: getConferenceName(state),
        _profileData:getProfileTabProps(state),

        /**
         * The cached feedback message, if any, that was set when closing a
         * previous instance of {@code PollDialog}.
         *
         * @type {string}
         */
        _message: message,

        /**
         * The currently selected score selection index.
         *
         * @type {number}
         */
        _score: score
    };
}

export default translate(connect(_mapStateToProps)(QuizDialogShow));
