import React, { Component } from 'react';
import LaunchPoll from './LaunchPoll';
import { connect } from 'react-redux';
import { toggleReduxPoll } from '../middleware';


const mapDispatchToProps = dispatch => ({
    hideModal: () => dispatch(toggleReduxPoll()),
    showModal: () => {
        dispatch(toggleReduxPoll())
    }
})




class QuizModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false
        }
    }

    handelModel() {

        this.setState({ show: !this.props.showModal() })

    }



    render() {

        let single = "";
        if (this.props.data !== "") {

            this.props.data.map(ele => {
                if (ele.questionType == "singleChoice" || ele.questionType == "multipleChoice" || ele.questionType == "textFree" || ele.questionType == "Error") {
                    single = <LaunchPoll data={this.props.data}
                        setRadioAnswers={this.props.setRadioAnswers}
                        chekedvalue={this.props.chekedvalue}
                        hiddenValue={this.props.hiddenValue}
                        onchangeCheckBox={this.props.onchangeCheckBox}
                        setFreetextAns={this.props.setFreetextAns}
                        clickNextButton={this.props.handleAnswerOptionClick}
                        validatedata={this.props.validatedata}
                        submitAnswer={this.props.submitAnswer}
                        Quiztimer={this.props.QuizTimer}
                        onCancelClick={this.props.onCancelClick}
                    />
                }
            })
        }

        return (
            <>




                {single}


            </>);
    }
}


export default connect(null, mapDispatchToProps)(QuizModal)