/**
 * Action type to add a video track to the store.
 */
export const ADD_PREJOIN_VIDEO_TRACK = "ADD_PREJOIN_VIDEO_TRACK";

/**
 * Action type to add an audio track to the store.
 */
export const ADD_PREJOIN_AUDIO_TRACK = "ADD_PREJOIN_AUDIO_TRACK";

/**
 * Action type to add a content sharing track to the store.
 */
export const ADD_PREJOIN_CONTENT_SHARING_TRACK =
    "ADD_PREJOIN_CONTENT_SHARING_TRACK";

/**
 * Action type to signal the start of the conference.
 */
export const PREJOIN_START_CONFERENCE = "PREJOIN_START_CONFERENCE";

/**
 * Action type to set the status of the device.
 */
export const SET_DEVICE_STATUS = "SET_DEVICE_STATUS";

/**
 * Action type to set the visiblity of the prejoin page for the future.
 */
export const SET_SKIP_PREJOIN = "SET_SKIP_PREJOIN";

/**
 * Action type to set the country to dial out to.
 */
export const SET_DIALOUT_COUNTRY = "SET_DIALOUT_COUNTRY";

/**
 * Action type to set the dial out number.
 */
export const SET_DIALOUT_NUMBER = "SET_DIALOUT_NUMBER";

/**
 * Action type to set the dial out status while dialing.
 */
export const SET_DIALOUT_STATUS = "SET_DIALOUT_STATUS";

/**
 * Action type to set the visiblity of the 'JoinByPhone' dialog.
 */
export const SET_JOIN_BY_PHONE_DIALOG_VISIBLITY =
    "SET_JOIN_BY_PHONE_DIALOG_VISIBLITY";

/**
 * Action type to disable the audio while on prejoin page.
 */
export const SET_PREJOIN_AUDIO_DISABLED = "SET_PREJOIN_AUDIO_DISABLED";

/**
 * Action type to mute/unmute the audio while on prejoin page.
 */
export const SET_PREJOIN_AUDIO_MUTED = "SET_PREJOIN_AUDIO_MUTED";

/**
 * Action type to set the errors while creating the prejoin streams.
 */
export const SET_PREJOIN_DEVICE_ERRORS = "SET_PREJOIN_DEVICE_ERRORS";

/**
 * Action type to set the visibility of the prejoin page.
 */
export const SET_PREJOIN_PAGE_VISIBILITY = "SET_PREJOIN_PAGE_VISIBILITY";

/**
 * Action type to mute/unmute the video while on prejoin page.
 */
export const SET_PREJOIN_VIDEO_DISABLED = "SET_PREJOIN_VIDEO_DISABLED";

/**
 * Action type to mute/unmute the video while on prejoin page.
 */
export const SET_PREJOIN_VIDEO_MUTED = "SET_PREJOIN_VIDEO_MUTED";

/**
 * Set Session ALLOWED
 */
export const SET_SESSION_ALLOWED = "SET_SESSION_ALLOWED";

/**
 * Action type to set the visibility of the Configuration page.
 */
export const SET_CONFIGURATION_PAGE_VISIBILITY =
    "SET_CONFIGURATION_PAGE_VISIBILITY";

export const SET_LOADING_VIEW_MSG = "SET_LOADING_VIEW_MSG";

export const SET_LOCATION_PERMISSION_REQUIRED =
    "SET_LOCATION_PERMISSION_REQUIRED";

export const SET_BRAND_THANK_PAGE_REQUIRED = "SET_BRAND_THANK_PAGE_REQUIRED";
export const SET_BRAND_PAGE_ENABLED = "SET_BRAND_PAGE_ENABLED";
export const SET_IS_JIBRI = "SET_IS_JIBRI";
export const SET_THANK_PAGE_LOGO = "SET_THANK_PAGE_LOGO";
export const SET_THANK_PAGE_TAGLINE = "SET_THANK_PAGE_TAGLINE";
