// @flow

import { ReducerRegistry } from '../base/redux';

import {
    SET_FILMSTRIP_ENABLED,
    SET_FILMSTRIP_HOVERED,
    SET_FILMSTRIP_VISIBLE,
    SET_HORIZONTAL_VIEW_DIMENSIONS,
    SET_TILE_VIEW_DIMENSIONS,
    SHOW_WEBINAR_PARTICIPANT_OPTIONS,
    UPDATE_AUDIOBUTTON_STATUS,
    UPDATE_VIDEOBUTTON_STATUS,
    UPDATE_SCREEN_SHARE_STATUS,
    SET_IS_WEBINAR,
    SET_IS_USER_JOINED,
    TOGGLE_PARTICIPANT_PANE,
    SET_IS_HOST,
    TOGGLE_BUGER_MENU_MOBILE,
    SET_SCREEN_CONFIGURATION,
    SET_SCREENSHOT_CONFIGURATION,
    SET_LOCATION_CONFIGURATION,
    SET_FILES_CONFIGURATION
} from './actionTypes';

const DEFAULT_STATE = {
    /**
     * The indicator which determines whether the {@link Filmstrip} is enabled.
     *
     * @public
     * @type {boolean}
     */
    enabled: true,

    /**
     * The horizontal view dimensions.
     *
     * @public
     * @type {Object}
     */
    horizontalViewDimensions: {},

    /**
     * The tile view dimensions.
     *
     * @public
     * @type {Object}
     */
    tileViewDimensions: {},

    /**
     * The indicator which determines whether the {@link Filmstrip} is visible.
     *
     * @public
     * @type {boolean}
     */
    popmenu_isVisible: true,
    visibleMenuId: '',
    _isAudioBtnVisible: false,
    _isVideoBtnVisible: false,
    _ScreenShareBtnVisiblity: false,
    _isWebinar: false,
    isUserJoined: false,
    _isWbParticipantPaneOpen: true,
    isHost: false,
    isInitialState: true,
    screenSharePermissions: [],
};

ReducerRegistry.register(
    'features/webinar',
    (state = DEFAULT_STATE, action) => {
        switch (action.type) {
            case SHOW_WEBINAR_PARTICIPANT_OPTIONS:
                return {
                    ...state,
                    popmenu_isVisible: action.isVisible,
                    visibleMenuId: action.menuid
                }
            case UPDATE_AUDIOBUTTON_STATUS:
                return {
                    ...state,
                    _isAudioBtnVisible: action._isAudioBtnVisible,
                }
            case UPDATE_VIDEOBUTTON_STATUS:
                return {
                    ...state,
                    _isVideoBtnVisible: action._isVideoBtnVisible
                }
            case UPDATE_SCREEN_SHARE_STATUS:
                return {
                    ...state,
                    _ScreenShareBtnVisiblity: action._isScreenShareBtnVisible
                }

            case SET_IS_WEBINAR:
                return {
                    ...state,
                    _isWebinar: action.isWebinar
                }
            case SET_IS_HOST:
                return {
                    ...state,
                    isHost: action.isHost
                }

            case SET_IS_USER_JOINED:
                return {
                    ...state,
                    isUserJoined: action.isUserJoined
                }
            case TOGGLE_PARTICIPANT_PANE:
                return {
                    ...state,
                    _isWbParticipantPaneOpen: action.isOpen
                }

            case TOGGLE_BUGER_MENU_MOBILE:
                return {
                    ...state,
                    isInitialState: action.updateState
                }
            case SET_SCREEN_CONFIGURATION:
                return {
                    ...state,
                    screenSharePermissions: action.screenConfig
                }

            case SET_SCREENSHOT_CONFIGURATION:
                return {
                    ...state,
                    screenshotPermission: action.screenshotPermission
                }

            case SET_LOCATION_CONFIGURATION:
                return {
                    ...state,
                    locationPermission: action.locationPermission
                }

            case SET_FILES_CONFIGURATION:
                return {
                    ...state,
                    filePermission: action.filePermission
                }

        }
        return state;
    });
