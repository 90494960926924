/**
 * The type of (redux) action which sets whether the filmstrip is enabled.
 *
 * {
 *     type: SET_FILMSTRIP_ENABLED,
 *     enabled: boolean
 * }
 */
export const SET_FILMSTRIP_ENABLED = 'SET_FILMSTRIP_ENABLED';

/**
 * The type of (redux) action which sets whether or not the filmstrip is being
 * hovered with the cursor.
 *
 * {
 *     type: SET_FILMSTRIP_HOVERED,
 *     hovered: boolean
 * }
 */
export const SET_FILMSTRIP_HOVERED = 'SET_FILMSTRIP_HOVERED';

/**
 * The type of (redux) action which sets whether the filmstrip is visible.
 *
 * {
 *     type: SET_FILMSTRIP_VISIBLE,
 *     visible: boolean
 * }
 */
export const SET_FILMSTRIP_VISIBLE = 'SET_FILMSTRIP_VISIBLE';

/**
 * The type of (redux) action which sets the dimensions of the tile view grid.
 *
 * {
 *     type: SET_TILE_VIEW_DIMENSIONS,
 *     dimensions: Object
 * }
 */
export const SET_TILE_VIEW_DIMENSIONS = 'SET_TILE_VIEW_DIMENSIONS';

/**
 * The type of (redux) action which sets the dimensions of the thumbnails in horizontal view.
 *
 * {
 *     type: SET_HORIZONTAL_VIEW_DIMENSIONS,
 *     dimensions: Object
 * }
 */
export const SET_HORIZONTAL_VIEW_DIMENSIONS = 'SET_HORIZONTAL_VIEW_DIMENSIONS';



export const SHOW_WEBINAR_PARTICIPANT_OPTIONS = 'SHOW_WEBINAR_PARTICIPANT_OPTIONS';

export const UPDATE_AUDIOBUTTON_STATUS = 'UPDATE_AUDIOBUTTON_STATUS';

export const UPDATE_VIDEOBUTTON_STATUS = 'UPDATE_VIDEOBUTTON_STATUS';

export const UPDATE_SCREEN_SHARE_STATUS = 'UPDATE_SCREENSHARE_STATUS';

export const SET_IS_WEBINAR = 'SET_IS_WEBINAR';

export const SET_IS_USER_JOINED = 'SET_IS_USER_JOINED';

export const TOGGLE_PARTICIPANT_PANE = 'TOGGLE_PARTICIPANT_PANE';

export const SET_IS_HOST = 'SET_IS_HOST';

export const TOGGLE_BUGER_MENU_MOBILE = 'TOGGLE_BUGER_MENU_MOBILE';

export const SET_SCREEN_CONFIGURATION ='SET_SCREEN_CONFIGURATION';

export const SET_SCREENSHOT_CONFIGURATION ='SET_SCREENSHOT_CONFIGURATION';
export const SET_LOCATION_CONFIGURATION ='SET_LOCATION_CONFIGURATION';
export const SET_FILES_CONFIGURATION ='SET_FILES_CONFIGURATION';