// @flow


import {
   SHOW_POLL,
   CANCEL_POLL,
   SHOW_ENDCALL_BANNER,
   SHOW_SCHEDULE_BANNER
   
} from './actionTypes';

import { callend } from './components';
import { openDialog } from '../base/dialog';

    export function toggleReduxPoll() {debugger
        return {
            type: SHOW_POLL
        };
    }
  
    export function openCallEnd(openModal: Object, ) {debugger
        return openDialog(callend, {
            
                });
    }

    export function cancelPoll(score: number, message: string) {debugger
        return {
            type: CANCEL_POLL,
            message,
            score
        };
    }

    export function toggleEndCallBanner(score: number, message: string) {
        return {
            type: SHOW_ENDCALL_BANNER,
            message,
            score
        };
    }


    export function showScheduleBanner(time: Date,waitingTime:Number,autoEndCall:Boolean,allowCancel:Boolean) {
        return {
            type: SHOW_SCHEDULE_BANNER,
            time,
            waitingTime,
            autoEndCall,
            allowCancel
        };
    }

    export function submitFeedback(
        score: number,
        message: string,
        conference: Object) {debugger
    return (dispatch: Dispatch<any>) => conference.sendFeedback(score, message)
        .then(
            () => dispatch({ type: SUBMIT_FEEDBACK_SUCCESS }),
            error => {
                dispatch({
                    type: SUBMIT_FEEDBACK_ERROR,
                    error
                });

                return Promise.reject(error);
            }
        );
}
