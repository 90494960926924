// @flow


import {
    SHOW_VOTE_POLL,
   CANCEL_POLL,
   STOP_POLL
   
} from './actionTypes';

import { VoteingPoll ,DialogMessage} from './components';
import { openDialog } from '../base/dialog';

    export function ToggleVotingPoll() {
        return {
            type: SHOW_VOTE_POLL
        };
    }
    export function stopPoll(){debugger
        return {
            type: STOP_POLL
        };
    }

    export function openVoteDialog(openModal: Object, ) { 
        return openDialog(VoteingPoll, {
            
                });
    }
    export function openPollDialogMessage(openModal: Object, ) {
        return openDialog(DialogMessage, {
            
                });
    }
    export function cancelPoll(score: number, message: string) {
        return {
            type: CANCEL_POLL,
            message,
            score
        };
    }
    export function submitFeedback(
        score: number,
        message: string,
        conference: Object) {
    return (dispatch: Dispatch<any>) => conference.sendFeedback(score, message)
        .then(
            () => dispatch({ type: SUBMIT_FEEDBACK_SUCCESS }),
            error => {
                dispatch({
                    type: SUBMIT_FEEDBACK_ERROR,
                    error
                });

                return Promise.reject(error);
            }
        );
}
