import { SET_ACTIVE_MODAL_ID } from '../base/modal';
import { ReducerRegistry } from '../base/redux';

import {
   
    SHOW_POLL,
    SET_TOOLBOX_VISIBLE,
    SHOW_ENDCALL_BANNER,
    SHOW_SCHEDULE_BANNER
} from './actionTypes';

const DEFAULT_STATE = {
    show: false
}

ReducerRegistry.register('features/callend', (state = DEFAULT_STATE, action) => {
    switch (action.type) {    
    case  SHOW_ENDCALL_BANNER :
        
        return updateEndCallBanner(state);
    case  SHOW_SCHEDULE_BANNER :
            
            return updateScheduleBanner(state,action.time,action.waitingTime,action.autoEndCall,action.allowCancel);
    
    }

    return state;
});

/**
 * Updates the chat status on opening the chat view.
 *
 * @param {Object} state - The Redux state of the feature.
 * @returns {Object}
 */
function updateEndCallBanner(state) {
    return {
        ...state,
        isOpen: !state.isOpen     
    };
}

/**
 * Update status for Show Schedule Banner.
 *
 * @param {Object} state - The Redux state of the feature.
 * @returns {Object}
 */
function updateScheduleBanner(state,time,waitingTime,autoEndCall,_allowCancel) {
    return {
        ...state,
        isScheduleNotificationRequired: true,
        scheduleNotifyTime:time,
        warningWaitTime:waitingTime,
        shouldAutoEnd:autoEndCall,
        allowCancel:_allowCancel,

    };
}
