// @flow

import React, { Component } from 'react';
import type { Dispatch } from 'redux';
import { translate } from '../../../base/i18n';
import { connect } from '../../../base/redux';
import { HighlightWithinTextarea } from 'react-highlight-within-textarea';
import {CLEAR_MESSAGES} from '../../actions';
import { getProfileTabProps } from '../../../settings/functions';
import { getAPIURL, getConferenceName } from '../../../base/conference';
import axios from 'axios';
/**
 * The type of the React {@code Component} props of {@link TextArea}.
 */
type Props = {

    /**
     * Invoked to send chat messages.
     */
    dispatch: Dispatch<any>,
    _defaultAPIURL:string
};

/**
 * The type of the React {@code Component} state of {@link TextArea}.
 */
type State = {

    /**
     * User provided nickname when the input text is provided in the view.
     */
    message: string
   
};

/**
 * Implements a React Component for drafting and submitting a chat message.
 *
 * @extends Component
 */
class TextArea extends Component<Props, State> {
    _textArea: ?HTMLTextAreaElement;

    state = {       
        title:'' ,
        notesText: "",
        inputValue:"",
        text:"",
        onSearch:""      
    };
    
    
    clearInput = () => {
      this.setState((prev) => ({onSearch: prev.onSearch+1}))
    }
    constructor(props: Props) {
        super(props);
        this._textArea = null;
        this._onSubmitLocal = this._onSubmitLocal.bind(this);   
        this._onSubmitServer = this._onSubmitServer.bind(this);   
        this._onClearAll=this._onClearAll.bind(this);
  
    }




    /**
     * Implements React's {@link Component#componentDidMount()}.
     *
     * @inheritdoc
     */
    componentDidMount() {
        /**
         * HTML Textareas do not support autofocus. Simulate autofocus by
         * manually focusing.
         */
        this._focus();
        var note = sessionStorage.getItem('note');
        if(note)
        {
          this.setState({notesText: note});
        }
    }

    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */

    componentWillUnmount() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }
    onChangeValue = () => {
        const notes = document.getElementById("notepad").value; 
        this.setState({
          notesText: notes
        },()=>{
          sessionStorage.setItem('note', this.state.notesText)
        });
      };
      changeInput = (e) => {
        let value = e.target.value;       
          this.setState({
            notesText: this.state.notesText.concat(' ')
          })
        this.setState({inputValue:value})        
      }
      onSaveNotes = () => {
        const notes = document.getElementById("notepad").value;
        const obj = { notes };
        this.setState({
          notesText: "",
          noteList: this.state.notesText.concat(obj)
        });        
      };  
    render() {
       
        return (
            <>        
               <input type="text" id="searching" placeholder="Searching...."  onChange={this.changeInput} value={this.state.inputValue}  />     
              <HighlightWithinTextarea              
              rows="24"
              cols="51"                  
              placeholder=" Enter Notes here"
              value={this.state.notesText}
              highlight={this.state.inputValue}
              id="notepad"      
              onChange={this.onChangeValue}
              selectionColor={'green'}        
             /> 
             <div className="d-flex btn-outer">
             <button onClick={this._onSubmitLocal}>Save on system</button>
             <button onClick={this._onSubmitServer}>Save on server</button>
             <button onClick={this._onClearAll}>Clear all</button>
             </div>
            </>
        );
    }
 
    /**
     * Place cursor focus on this component's text area.
     *
     * @private
     * @returns {void}
     */
    _focus() {
        this._textArea && this._textArea.focus();
    }



     onChange(newName) {  
       
      this.setState({ parentName: newName });}

    _onClearAll:() => void;

    _onClearAll(){
      this.setState({
        notesText: "",
        inputValue:""
      });
      sessionStorage.clear();
    }
   
      //----------------Notes save on your local system ----------------------

    _onSubmitLocal: () => void;

    _onSubmitLocal() {       
        debugger
      if(this.state.notesText.length > 1){
          const notes = document.getElementById("notepad").value;
          const obj = { notes };
          this.setState({            
            noteList: this.state.notesText.concat(obj)
          });                  
          const element = document.createElement("a");
          const file = new Blob([document.getElementById('notepad').value], {type: 'text/plain'});
          element.href = URL.createObjectURL(file);
          element.download = "DialtmNotes.txt";
          document.body.appendChild(element); // Required for this to work in FireFox
          element.click();
  
          alert("Notes saved successfully!");
          this._onClearAll();
       }
       else{
           alert("Please write some notes first...");
       }
}

   //------------------------Save Notes on server ------------------------------

   _onSubmitServer: () => void;

   _onSubmitServer() {  
     if(this.state.notesText.length > 1){
      const notes = document.getElementById("notepad").value;
      const obj = { notes };
      this.setState({       
        noteList: this.state.notesText.concat(obj)
      });                   
      const payload ={        
        meetingcode: APP.conference.roomName,    
        user_mail: this.props._profileData.email,  
       notes:notes
            }
       console.log(payload)
       console.log(JSON.stringify(payload))

       var apiDomain=this.props._defaultAPIURL;
       axios.post(apiDomain+'/meetingnote/insertnote',payload)
       .then(response =>{
         if(response.status==200){
           alert("Notes saved successfully!");         
           this._onClearAll();
         }                  
       })
       .catch(error =>{
           console.log(error)
       })         
      }
      else{
          alert("Please write some notes first...");
      }
}

}
function mapStateToProps(state) {
  const { search } = state
 
    return { searchList: search ,
  _meetingName: getConferenceName(state),
  _profileData:getProfileTabProps(state),
  _defaultAPIURL:getAPIURL(state)
  }
}
export default translate(connect (mapStateToProps)(TextArea));
