import { SET_ACTIVE_MODAL_ID } from '../base/modal';
import { ReducerRegistry } from '../base/redux';

import {
   
    SHOW_ENDCALL,
    SET_TOOLBOX_VISIBLE
} from './actionTypes';

const DEFAULT_STATE = {
    show: false
}

ReducerRegistry.register('features/endcall-confermation-dialog', (state = DEFAULT_STATE, action) => {
    switch (action.type) {    
    case  SHOW_ENDCALL :
        return updatePollState(state);
    }

    return state;
});

/**
 * Updates the chat status on opening the chat view.
 *
 * @param {Object} state - The Redux state of the feature.
 * @returns {Object}
 */
function updatePollState(state) {debugger
    return {
        ...state,
        show: !state.show
        
    };
}