// @flow

//import { IconMicrophone } from '../../../base/icons';
import { AbstractButton } from '../../../base/toolbox';
import { IconCamera, IconCameraDisabled, IconMicDisabled, IconMicrophone } from '../../../base/icons';
import { SEND_MUTEVIDEO_COMMAND } from '../../constants';


/**
 * An abstract implementation of a button for toggling audio mute.
 */
export default class ParticipantVideoMuteButton<P: Props, S: *>
    extends AbstractButton<P, S> {

    icon = IconCamera;
    toggledIcon = IconCameraDisabled;
   
    constructor(props: Props) {
        super(props);

        this.state = {
            videoAvailable: false,
            videoMuted: true
        };

        APP.conference.addUserStatChangeListener(this);
    }



     onParticipantState(data)
    {
        var _participantId = data.participantId;

        if(_participantId==null)
        {
            _participantId = data.jitsiTrack.ownerEndpointId;
        }

        if(_participantId == this.props._id)
        {
            var muted = data.jitsiTrack.muted;
            var videoStarted = data.jitsiTrack.videoStarted;

            if(data.jitsiTrack.type=="video")
            {
                var videoType = data.jitsiTrack.videoType;
                var isMuted =data.jitsiTrack.muted;
                this._setVideoMuted(isMuted);    
            }
            // try {
            //     APP.conference.sendEndpointMessage(this.props._id,{
            //         name: SEND_MUTE_COMMAND,
            //         text:{'video_muted':isMuted}
            //      });
            //     } 
            // catch (err) {
            //        console.log("Error while sending command for audio :: "+err);
            //     }


        }
      
    }
    /**
     * Handles clicking / pressing the button, and toggles the audio mute state
     * accordingly.
     *
     * @override
     * @protected
     * @returns {void}
     */
    _handleClick() {
       // alert(this.props._id);
        try {
            APP.conference.sendEndpointMessage(this.props._id,{
                name: SEND_MUTEVIDEO_COMMAND,
                text:{'video_muted':!this._isVideoMuted()}
             });
            } catch (err) {
               console.log("Error while sending command for audio :: "+err);
              // APP.conference.endCallForAll();
            }
        this._setVideoMuted(!this._isVideoMuted());
    }

    /**
     * Helper function to be implemented by subclasses, which must return a
     * boolean value indicating if audio is muted or not.
     *
     * @protected
     * @returns {boolean}
     */
     _isVideoMuted() {
        return this.state.videoMuted;
    }

    /**
     * Indicates whether this button is in toggled state or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _isToggled() {
        return this._isVideoMuted();
    }

    /**
     * Helper function to perform the actual setting of the audio mute / unmute
     * action.
     *
     * @param {boolean} audioMuted - Whether video should be muted or not.
     * @protected
     * @returns {void}
     */
     _setVideoMuted(_isMuted: boolean) { // eslint-disable-line no-unused-vars
        // To be implemented by subclass.
        this.setState({videoMuted:_isMuted});
        
    }
}
