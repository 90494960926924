// @flow


import {
    SHOW_POLL,
    CANCEL_POLL,
    STOP_QUIZ,
    SET_QUIZ_USER
} from './actionTypes';

import { PollDialog } from './components';
import { QuizDialogShow } from './components';
import { openDialog } from '../base/dialog';
import QuizUserDialog from './components/QuizUserDialog';

export function toggleReduxPoll() {
    return {
        type: SHOW_POLL
    };
}

export function openPollDialog(openModal: Object,) {
    return openDialog(PollDialog, {

    });
}


export function openQuizUserInfoDialog(openModal: Object,) {
    return openDialog(QuizUserDialog, {

    });
}
export function stopQuiz() {
    debugger
    return {
        type: STOP_QUIZ
    };
}


export function openQuizDialog(openModal: Object,) {
    return openDialog(QuizDialogShow, {

    });
}

export function cancelPoll(score: number, message: string) {
    return {
        type: CANCEL_POLL,
        // type: 'STOP_QUIZ',
        message,
        score
    };
}
export function cancelQuizDialog(score: number, message: string) {
    return {
        type: CANCEL_POLL,
        message,
        score
    };
}
export function setQuizUser(username: string, email: string) {
    return {
        type: SET_QUIZ_USER,
        username,
        email
    };
}


export function submitFeedback(
    score: number,
    message: string,
    conference: Object) {
    return (dispatch: Dispatch<any>) => conference.sendFeedback(score, message)
        .then(
            () => dispatch({ type: SUBMIT_FEEDBACK_SUCCESS }),
            error => {
                dispatch({
                    type: SUBMIT_FEEDBACK_ERROR,
                    error
                });

                return Promise.reject(error);
            }
        );
}
