
import React, { Component} from 'react';
import { connect } from '../../base/redux';
import type { Dispatch } from 'redux';
import { Dialog } from '../../base/dialog';
import { translate } from '../../base/i18n';
import { cancelPoll } from '../actions';
import "./styles.css";
import { hideDialog } from '../../../features/base/dialog';
import { HighlightWithinTextarea } from 'react-highlight-within-textarea';

declare var APP: Object;
declare var interfaceConfig: Object;

const scoreAnimationClass
    = interfaceConfig.ENABLE_FEEDBACK_ANIMATION ? 'shake-rotate' : '';

/**
 * The scores to display for selecting. The score is the index in the array and
 * the value of the index is a translation key used for display in the dialog.
 *
 * @types {string[]}
 */


/**
 * The type of the React {@code Component} props of {@link notepad}.
 */
type Props = {

    /**
     * The cached feedback message, if any, that was set when closing a previous
     * instance of {@code notepad}.
     */
    _message: string,

    /**
     * The cached feedback score, if any, that was set when closing a previous
     * instance of {@code notepad}.
     */
    _score: number,

    /**
     * The JitsiConference that is being rated. The conference is passed in
     * because feedback can occur after a conference has been left, so
     * references to it may no longer exist in redux.
     */
    conference: Object,

    openModal:Object,

    /**
     * Invoked to signal feedback submission or canceling.
     */
    dispatch: Dispatch<any>,

    /**
     * Callback invoked when {@code notepad} is unmounted.
     */
    onClose: Function,

    /**
     * Invoked to obtain translated strings.
     */
    t: Function
};

/**
 * The type of the React {@code Component} state of {@link notepad}.
 */


/**
 * A React {@code Component} for displaying a dialog to rate the current
 * conference quality, write a message describing the experience, and submit
 * the feedback.
 *
 * @extends Component
 */
class notepad extends Component<Props, State> {
    /**
     * An array of objects with click handlers for each of the scores listed in
     * the constant SCORES. This pattern is used for binding event handlers only
     * once for each score selection icon.
     */
    _scoreClickConfigurations: Array<Object>;

    /**
     * Initializes a new {@code notepad} instance.
     *
     * @param {Object} props - The read-only React {@code Component} props with
     * which the new instance is to be initialized.
     */
    constructor(props: Props) {
        super(props);       
        const { _message, _score } = this.props;
               this.state = {
            /**
             * The currently entered feedback message.
             *
             * @type {string}
             */
            isLoaded:false,
            item:'',
            show:false,            
            title:'' ,
            notesText: "",
            inputValue:"",
            text:""            
                            
        };
       
        this._onCancel = this._onCancel.bind(this);     
        this._onSubmit = this._onSubmit.bind(this);      
    }
   
    componentWillUnmount() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }
    onChangeValue = () => {
        const notes = document.getElementById("notepad").value; 
        this.setState({
          notesText: notes
        });
      };
      
      onSaveNotes = () => {
        const notes = document.getElementById("notepad").value;
        const obj = { notes };
        this.setState({
          notesText: "",
          noteList: this.state.notesText.concat(obj)
        });        
      };


      changeInput = (e) => {
        let value = e.target.value;       
          this.setState({
            notesText: this.state.notesText.concat(' ')
          })
        this.setState({inputValue:value})        
      }
    

    render() {             
        return (
            <>            
             <Dialog
             okKey = 'dialog.Save'
                 onSubmit = { this._onSubmit }
                 onCancel = { this._onCancel }                      
                 titleKey = 'notepad.rateExperience'
                 width = 'large'  
               >    
                 <p  hidden={true} id="myText">{this.state.text}</p>
              <input type="text" id="searching" placeholder="Searching...."  onChange={this.changeInput} value={this.state.inputValue}  />
            
              <div className="Note-pad">
             <div className="some-test">
            <div>
              {/* <textarea             
                rows="20"
                cols="105"
                placeholder="Enter Notes here"
                id="notepad"              
                value={this.state.notesText}
                onChange={this.onChangeValue}            
              />  
                             */}
              <div className="notepad">
              
               <HighlightWithinTextarea              
              rows="20"
              cols="105"                  
              placeholder=" Enter Notes here"
              value={this.state.notesText}
                highlight={this.state.inputValue}
                id="notepad"      
                onChange={this.onChangeValue}        
    /> 
            </div>
            </div>
          </div>         
          
        </div> 
    </Dialog>             
</>
           
        );
    }

    _onCancel: () => boolean;

    /**
     * Dispatches an action notifying feedback was not submitted. The submitted
     * score will have one added as the rest of the app does not expect 0
     * indexing.
     *
     * @private
     * @returns {boolean} Returns true to close the dialog.
     */
    _onCancel() {
        const { message, score } = this.state;
        const scoreToSubmit = score > -1 ? score + 1 : score;

        this.props.dispatch(cancelPoll(scoreToSubmit, message));

        return true;
    }
    _onSubmit: () => void;

    /**
     * Dispatches the entered feedback for submission. The submitted score will
     * have one added as the rest of the app does not expect 0 indexing.
     *
     * @private
     * @returns {boolean} Returns true to close the dialog.
     */
    _onSubmit() {       
        
        if(this.state.notesText.length > 1){
            const notes = document.getElementById("notepad").value;
            const obj = { notes };
            this.setState({
              notesText: "",
              noteList: this.state.notesText.concat(obj)
            });                  
            const element = document.createElement("a");
            const file = new Blob([document.getElementById('notepad').value], {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = "DialtmNotes.txt";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
    
            alert("Notes saved successfully!");
            this.setState({inputValue : ""});
            this.props.dispatch(hideDialog());    
         }
         else{
             alert("Please write some notes first...");
         }
}

    
}


/**
 * Maps (parts of) the Redux state to the associated {@code notepad}'s
 * props.
 *
 * @param {Object} state - The Redux state.
 * @private
 * @returns {{
 * }}
 */
function _mapStateToProps(state) {
    const { message, score } = state['features/notepad'];

    return {
        /**
         * The cached feedback message, if any, that was set when closing a
         * previous instance of {@code notepad}.
         *
         * @type {string}
         */
        _message: message,

        /**
         * The currently selected score selection index.
         *
         * @type {number}
         */
        _score: score
    };
}

export default translate(connect(_mapStateToProps)(notepad));
