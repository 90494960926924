import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import '../components/Quiz.css';


let lastAnswerTime = new Date().getTime();


function LaunchPoll(props) {
  const questions = props.data

  console.log('props', props)
  // 
  // if(typeof quizTimerStr !='undefined')
  // {
  //   quizTimer=convertHMSTOSeconds(quizTimerStr);
  // }
  let time = "";
  const [currentQuestionIndex, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [isSubmited, setisSubmited] = useState(false);

  const nextQuestion = currentQuestionIndex + 1;
  let radiocheck = "";
  let errorLog = "";

  let answertime = 0;

  //--------------------------------------------------Timer-----------------------
  const [timer, setTimer] = React.useState(1);
  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };

  React.useEffect(() => {
    let isTimeUp = false;
    id.current = window.setInterval(() => {
      setTimer((time) => time + 1);
      let quizTimer = 0;
      const quizTimerStr = props.Quiztimer;
      if (typeof quizTimerStr != 'undefined') {
        quizTimer = convertHMSTOSeconds(quizTimerStr);
      }

      if (quizTimer > 0 && quizTimer < timer && !isSubmited) {
        setisSubmited(true);
        clear();
        alert("Time is up");
        props.submitAnswer();
      }
    }, 1000);
    return () => clear();
  }, [props.Quiztimer, timer, props.submitAnswer]);

  React.useEffect(() => {
    if (timer === 0) {
      clear();
    }
  }, [timer]);

  time = timer;
  let quizTime = convertHMS(time);

  function convertHMSTOSeconds(hms) {
    if (hms) {
      var a = hms.split(':'); // split it at the colons
      // minutes are worth 60 seconds. Hours are worth 60 minutes.
      var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
      return seconds;
    }

    return 0;
  }


  function convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
  }

  //--------------------------------------------------------------------


  if (props.validatedata.length == nextQuestion) {

    props.validatedata.map((e1, i) => {
      if (props.data.filter(e2 => e2.id === e1.id).length > 0) {
        radiocheck = props.chekedvalue;
      }
    })
  }


  const handleAnswerOptionClick = (id) => {
    if (props.validatedata.length <= 0) {
      alert('Please give your answers.!')
      return false;

    }
    let newArray = props.validatedata.filter((item) => item.id === id);
    if (newArray.length <= 0) {
      radiocheck = props.chekedvalue;
      alert('Please give your answers.!')
      return false;
    }
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
      //  setTimer(1);
      //  

      lastAnswerTime = new Date().getTime();


    } else {
      setShowScore(true);
    }
  };

  /**
   *  This method will be called for final question and will return if option is not selected
   * @param {*} id 
   * @returns 
   */

  const validateAndSubmit = (id) => {
    if (props.validatedata.length <= 0) {
      alert('Please give your answers.!')
      return false;
    }
    props.submitAnswer();
  };

  function getAnswerTime() {
    let answertime = parseInt((new Date().getTime() - lastAnswerTime) / 1000);
    return convertHMS(answertime);
  }



  return (
    <>
      {questions[currentQuestionIndex].questionType == "Error" ? <h2> Quiz data not found...</h2> :
        <div style={{
          position: 'relative'
        }}>
          <div >
            <div className="st_1">
              <span>Question {currentQuestionIndex + 1}</span>/{questions.length} {errorLog}
              <span style={{ color: "#9c4141c7", fontSize: "16px", paddingLeft: "50%" }}>Time : {quizTime}</span>

            </div>
            <p className="title-red st_1" key={Math.floor(Math.random() * 1020)}>Title {questions[currentQuestionIndex].title} </p>
            <div className="st_1 st_2">{questions[currentQuestionIndex].question}</div>
          </div>

          {/* onChangeText={(text, inputType) => this.props.onChangeText(text, inputType, this.props)} */}

          <div className="st_3">
            {questions[currentQuestionIndex].ans.map((answerOption, index) => (
              <>

                {questions[currentQuestionIndex].questionType == "singleChoice" ? <div className="d-flex"> <input className="radio-control"
                  name={answerOption} value={answerOption} key={questions[currentQuestionIndex].id + "_" + index} type="radio" checked={radiocheck == answerOption}
                  onChange={(ans) => props.setRadioAnswers(questions[currentQuestionIndex].id, ans, questions[currentQuestionIndex].question, questions[currentQuestionIndex].questionType, getAnswerTime())} /> <label className="label-control">{answerOption}</label></div> : null}

                {questions[currentQuestionIndex].questionType == "multipleChoice" && answerOption != "" ? <div className="d-flex">  <input className="st_6 radio-control" key={questions[currentQuestionIndex].id + "_" + index} type="checkbox" id={questions[currentQuestionIndex].id} name={answerOption} value={answerOption}
                  onChange={(ans) => props.onchangeCheckBox(questions[currentQuestionIndex].id, ans, questions[currentQuestionIndex].question, questions[currentQuestionIndex].questionType, getAnswerTime())} />
                  <div className="form-check form-check-inline custom-input line-height-div">
                    <label className="label-control">{answerOption}</label>
                  </div>
                </div> : null}

                {questions[currentQuestionIndex].questionType == "textFree" ? <div className="form-group">
                  <textarea key={questions[currentQuestionIndex].id + "_" + index} className="form-control text_1 textarea-control" id="Textarea1" rows="2"
                    onChange={(ans) => props.setFreetextAns(questions[currentQuestionIndex].id, ans, questions[currentQuestionIndex].question, questions[currentQuestionIndex].questionType, getAnswerTime())} ></textarea>
                </div> : null}

              </>
            ))}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            {nextQuestion < questions.length ?
              <button type="button" style={{
                marginTop: 0
              }} className="btn btn-primary btn_1" onClick={() => { handleAnswerOptionClick(questions[currentQuestionIndex].id) }}>
                Next</button>
              :
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
                <button style={{
                  padding: '4px 20px',
                  marginRight: 10
                }} type="button" onClick={() => { validateAndSubmit(questions[currentQuestionIndex].id) }}>Submit</button>
              </div>
            }
            <button
              style={{
                padding: '4px 20px',
              }}
              type="button"
              onClick={() => props.onCancelClick()}
            >
              Cancel
            </button>
          </div>
        </div>}


    </>
  );

}

export default LaunchPoll


