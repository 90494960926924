import { openDialog } from '../base/dialog';

import { RemoteControlAuthorizationDialog } from './components';

/**
 * Signals that the remote control authorization dialog should be displayed.
 *
 * @param {string} participantId - The id of the participant who is requesting
 * the authorization.
 * @returns {{
 *     type: OPEN_DIALOG,
 *     component: {RemoteControlAuthorizationDialog},
 *     componentProps: {
 *         participantId: {string}
 *      }
 * }}
 * @public
 */
export function openRemoteControlAuthorizationDialog(participantId) {
    return openDialog(RemoteControlAuthorizationDialog, { participantId });
}


/**
 * Stops processing the mouse and keyboard events. Removes added listeners.
 * Enables the keyboard shortcuts. Displays dialog to notify the user that remote control session has ended.
 *
 * @param {boolean} notifyRemoteParty - If true a endpoint message to the controlled participant will be sent.
 * @returns {void}
 */
 export function stopController(notifyRemoteParty: boolean = false) {
    return (dispatch: Function, getState: Function) => {
        const state = getState();
        const { controlled } = state['features/remote-control'].controller;

        if (!controlled) {
            return;
        }

        const { conference } = state['features/base/conference'];

        if (notifyRemoteParty) {
            sendRemoteControlEndpointMessage(conference, controlled, {
                type: EVENTS.stop
            });
        }

        logger.log('Stopping remote control controller.');

        conference.off(JitsiConferenceEvents.ENDPOINT_MESSAGE_RECEIVED, stopListener);
        stopListener = undefined;

        dispatch(pause());

        dispatch({
            type: SET_CONTROLLED_PARTICIPANT,
            controlled: undefined
        });

        dispatch(setRemoteControlActive(false));
        dispatch(showNotification({
            descriptionKey: 'dialog.remoteControlStopMessage',
            titleKey: 'dialog.remoteControlTitle'
        }));
    };
}


/**
 * Requests permissions from the remote control receiver side.
 *
 * @param {string} userId - The user id of the participant that will be
 * requested.
 * @returns {Function}
 */
 export function requestRemoteControl(userId: string) {
    return (dispatch: Function, getState: Function) => {
        const state = getState();
        const enabled = isRemoteControlEnabled(state);

        if (!enabled) {
            return Promise.reject(new Error('Remote control is disabled!'));
        }

        dispatch(setRemoteControlActive(true));

        logger.log(`Requsting remote control permissions from: ${userId}`);

        const { conference } = state['features/base/conference'];


        permissionsReplyListener = (participant, event) => {
            dispatch(processPermissionRequestReply(participant.getId(), event));
        };

        conference.on(JitsiConferenceEvents.ENDPOINT_MESSAGE_RECEIVED, permissionsReplyListener);

        dispatch({
            type: SET_REQUESTED_PARTICIPANT,
            requestedParticipant: userId
        });

        if (!sendRemoteControlEndpointMessage(
            conference,
            userId,
            {
                type: EVENTS.permissions,
                action: PERMISSIONS_ACTIONS.request
            })) {
            dispatch(clearRequest());
        }
    };
}