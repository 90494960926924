// @flow

import { SET_FILES_CONFIGURATION, SET_HORIZONTAL_VIEW_DIMENSIONS, SET_IS_HOST, SET_IS_USER_JOINED, SET_IS_WEBINAR, SET_LOCATION_CONFIGURATION, SET_SCREENSHOT_CONFIGURATION, SET_SCREEN_CONFIGURATION, SET_TILE_VIEW_DIMENSIONS, SHOW_WEBINAR_PARTICIPANT_OPTIONS, TOGGLE_BUGER_MENU_MOBILE, TOGGLE_PARTICIPANT_PANE, UPDATE_AUDIOBUTTON_STATUS, UPDATE_SCREEN_SHARE_STATUS, UPDATE_VIDEOBUTTON_STATUS } from './actionTypes';
import { calculateThumbnailSizeForHorizontalView, calculateThumbnailSizeForTileView } from './functions';

/**
 * The size of the side margins for each tile as set in CSS.
 */
const TILE_VIEW_SIDE_MARGINS = 10 * 2;

/**
 * Sets the dimensions of the tile view grid.
 *
 * @param {Object} dimensions - Whether the filmstrip is visible.
 * @param {Object} windowSize - The size of the window.
 * @returns {{
 *     type: SET_TILE_VIEW_DIMENSIONS,
 *     dimensions: Object
 * }}
 */
export function setTileViewDimensions(dimensions: Object, windowSize: Object) {
    const thumbnailSize = calculateThumbnailSizeForTileView({
        ...dimensions,
        ...windowSize
    });
    const filmstripWidth = dimensions.columns * (TILE_VIEW_SIDE_MARGINS + thumbnailSize.width);

    return {
        type: SET_TILE_VIEW_DIMENSIONS,
        dimensions: {
            gridDimensions: dimensions,
            thumbnailSize,
            filmstripWidth
        }
    };
}

/**
 * Sets the dimensions of the thumbnails in horizontal view.
 *
 * @param {number} clientHeight - The height of the window.
 * @returns {{
 *     type: SET_HORIZONTAL_VIEW_DIMENSIONS,
 *     dimensions: Object
 * }}
 */
export function setHorizontalViewDimensions(clientHeight: number = 0) {
    return {
        type: SET_HORIZONTAL_VIEW_DIMENSIONS,
        dimensions: calculateThumbnailSizeForHorizontalView(clientHeight)
    };
}

export function setWebinarPopupMenuVisibilty(visible: boolean, _menuid:string)
{
    return {
        type:SHOW_WEBINAR_PARTICIPANT_OPTIONS,
        isVisible:visible,
        menuid:_menuid
    }
}


export function updateAudioBtnVisibility(visible: boolean)
{
    return {
        type:UPDATE_AUDIOBUTTON_STATUS,
        _isAudioBtnVisible:visible
    }
}


export function updateVideoBtnVisibility(visible: boolean)
{
    return {
        type:UPDATE_VIDEOBUTTON_STATUS,
        _isVideoBtnVisible:visible
    }
}


export function updateScreenShareBtnVisibility(visible: boolean)
{
    return {
        type:UPDATE_SCREEN_SHARE_STATUS,
        _isScreenShareBtnVisible:visible
    }
}


export function updateIsWebinar(_isWebinar: boolean)
{
    return {
        type:SET_IS_WEBINAR,
        isWebinar:_isWebinar
    }
}

export function updateIsUserJoined(_isUserJoined: boolean)
{
    return {
        type:SET_IS_USER_JOINED,
        isUserJoined:_isUserJoined
    }
}


export function toggleParticipantPanel(open: boolean)
{
    return {
        type:TOGGLE_PARTICIPANT_PANE,
        isOpen:open
    }
}



export function setIsHost()
{
    return {
        type:SET_IS_HOST,
        isHost:true
    }
}



export function updateInitialWebinarState()
{
    return {
        type:TOGGLE_BUGER_MENU_MOBILE,
        updateState:false
    }
}


export function setScreenConfiguration(_screenConfig)
{
    return {
        type:SET_SCREEN_CONFIGURATION,
        screenConfig:_screenConfig
    }
}


export function setScreenShotConfiguration(_screenshotPermission)
{
    return {
        type:SET_SCREENSHOT_CONFIGURATION,
        screenshotPermission:_screenshotPermission
    }
}

export function setLocationConfiguration(_locationPermission)
{
    return {
        type:SET_LOCATION_CONFIGURATION,
        locationPermission:_locationPermission
    }
}

export function setFilesConfiguration(_filePermission)
{
    return {
        type:SET_FILES_CONFIGURATION,
        filePermission:_filePermission
    }
}





export * from './actions.native';
