// @flow

import React, { Component } from 'react';

import {
    ACTION_SHORTCUT_TRIGGERED,
    createShortcutEvent,
    createToolbarEvent,
    sendAnalytics
} from '../../../analytics';
import { getToolbarButtons } from '../../../base/config';
import { openDialog, toggleDialog } from '../../../base/dialog';
import { isMobileBrowser } from '../../../base/environment/utils';
import { translate } from '../../../base/i18n';
import {
    IconChat,
    IconCodeBlock,
    IconDeviceDocument,
    IconExitFullScreen,
    IconFeedback,
    IconFullScreen,
    IconInviteMore,
    IconPresentation,
    IconRaisedHand,
    IconVote,
    IconShareVideo,
    IconRec,
    IconShareDesktop,
    Notepad,
    IconParticipants
} from '../../../base/icons';
import JitsiMeetJS from '../../../base/lib-jitsi-meet';
import {
    getLocalParticipant,
    getParticipants,
    isLocalParticipantModerator,
    participantUpdated,
    PARTICIPANT_ROLE
} from '../../../base/participants';
import { connect, equals } from '../../../base/redux';
import { OverflowMenuItem } from '../../../base/toolbox/components';
import { getLocalVideoTrack, toggleScreensharing } from '../../../base/tracks';
import { isVpaasMeeting } from '../../../billing-counter/functions';
import { ChatCounter, toggleChat } from '../../../chat';
//import { InviteMore } from '../../../conference';
import { EmbedMeetingDialog } from '../../../embed-meeting';
import { SharedDocumentButton } from '../../../etherpad';
import { openFeedbackDialog } from '../../../feedback';
import {
    beginAddPeople,
    InfoDialogButton,
    isAddPeopleEnabled,
    isDialOutEnabled
} from '../../../invite';
import { openKeyboardShortcutsDialog } from '../../../keyboard-shortcuts';
import { LocalRecordingInfoDialog } from '../../../local-recording';
import {
    LiveStreamButton,
    RecordButton
} from '../../../recording';
import { SecurityDialogButton } from '../../../security';
import {
    SETTINGS_TABS,
    SettingsButton,
    openSettingsDialog
} from '../../../settings';
import { SharedVideoButton } from '../../../shared-video/components';
import { toggleSharedVideo } from '../../../shared-video';
import { SpeakerStats } from '../../../speaker-stats';
import {
    ClosedCaptionButton
} from '../../../subtitles';
import { openPollDialogMessage,stopPoll, ToggleVotingPoll } from '../../../voting';
import {
    TileViewButton,
    shouldDisplayTileView,
    toggleTileView
} from '../../../video-layout';
import {
    OverflowMenuVideoQualityItem,
    VideoQualityDialog
} from '../../../video-quality';
import { VideoBackgroundButton } from '../../../virtual-background';
import { checkBlurSupport } from '../../../virtual-background/functions';
import {
    setFullScreen,
    setOverflowMenuVisible,
    setToolbarHovered,
    setToolboxVisible
} from '../../actions';
import { getToolbarAdditionalButtons, isToolboxVisible } from '../../functions';
import DownloadButton from '../DownloadButton';
import HangupButton from '../HangupButton';
import HelpButton from '../HelpButton';
import MuteEveryoneButton from '../MuteEveryoneButton';
import MuteEveryonesVideoButton from '../MuteEveryonesVideoButton';

import AudioSettingsButton from './AudioSettingsButton';
import OverflowMenuButton from './OverflowMenuButton';
import OverflowMenuProfileItem from './OverflowMenuProfileItem';
import ToggleCameraButton from './ToggleCameraButton';
import ToolbarButton from './ToolbarButton';
import VideoSettingsButton from './VideoSettingsButton';
import EndCallOptionsDialog from './EndCallOptionsDialog';

import { toggleReduxPoll } from '../../../reduxPoll';
import { openPollDialog ,openQuizDialog,stopQuiz} from '../../../reduxPoll'
import VideoMuteButton from '../VideoMuteButton';
import { showScheduleBanner, toggleEndCallBanner } from '../../../callend';
import {openNotepad} from '../../../notepad'
import { toggleNotes } from '../../../NoteP'; 
import { getParticipantsPaneOpen } from '../../../participants-pane/functions';
import { toggleParticipantPanel } from '../../../webinar-sidebar/actions.web';
import VideoLayout from '../../../../../modules/UI/videolayout/VideoLayout';
import { getAPIURL } from '../../../base/conference';
import { stat } from 'fs';

/**
 * The type of the React {@code Component} props of {@link Toolbox}.
 */
type Props = {

    /**
     * Whether or not the chat feature is currently displayed.
     */
    _chatOpen: boolean,

    /**
     * The width of the client.
     */
    _clientWidth: number,

    /**
     * The {@code JitsiConference} for the current conference.
     */
    _conference: Object,

    /**
     * The tooltip key to use when screensharing is disabled. Or undefined
     * if non to be shown and the button to be hidden.
     */
    _desktopSharingDisabledTooltipKey: boolean,

    /**
     * Whether or not screensharing is initialized.
     */
    _desktopSharingEnabled: boolean,

    /**
     * Whether or not a dialog is displayed.
     */
    _dialog: boolean,

    /**
     * Whether or not call feedback can be sent.
     */
    _feedbackConfigured: boolean,

    /**
     * Whether or not the app is currently in full screen.
     */
    _fullScreen: boolean,

    /**
     * Whether or not the app is running in mobile browser.
     */
    _isMobile: boolean,

    /**
     * Whether or not the profile is disabled.
     */
    _isProfileDisabled: boolean,

    /**
     * Whether or not the tile view is enabled.
     */
    _tileViewEnabled: boolean,

    /**
     * Whether or not the current meeting belongs to a JaaS user.
     */
    _isVpaasMeeting: boolean,

    /**
     * The ID of the local participant.
     */
    _localParticipantID: String,

    /**
     * The subsection of Redux state for local recording
     */
    _localRecState: Object,

    /**
     * The value for how the conference is locked (or undefined if not locked)
     * as defined by room-lock constants.
     */
    _locked: boolean,

    /**
     * Whether or not the overflow menu is visible.
     */
    _overflowMenuVisible: boolean,

    /**
     * Whether or not the local participant's hand is raised.
     */
    _raisedHand: boolean,

    /**
     * Whether or not the local participant is screensharing.
     */
    _screensharing: boolean,

    /**
     * Whether or not the local participant is sharing a YouTube video.
     */
    _sharingVideo: boolean,

    /**
     * Flag showing whether toolbar is visible.
     */
    _visible: boolean,

    /**
     * Array with the buttons which this Toolbox should display.
     */
     _visibleButtons: Set<string>,

    /**
     * Invoked to active other features of the app.
     */
    dispatch: Function,

    /**
     * Invoked to obtain translated strings.
     */
    t: Function,
    AudioBtnVisiblity:boolean,
    VideoBtnVisiblity:boolean,
    ScreenShareBtnVisiblity:boolean,
    _localParticipant:Object,
    _isQuizRunning:boolean,
    _isPollRunning:boolean,
    _participantsPaneOpen:boolean,
    _isHost: boolean,
    _isNotesOpen:boolean,
    _defaultAPIUrl:string
};

declare var APP: Object;
const visibleButtons = new Set(interfaceConfig.TOOLBAR_BUTTONS);
/**
 * Implements the conference toolbox on React/Web.
 *
 * @extends Component
 */
class WebinarToolbox extends Component<Props> {
    /**
     * Initializes a new {@code Toolbox} instance.
     *
     * @param {Props} props - The read-only React {@code Component} props with
     * which the new instance is to be initialized.
     */
    constructor(props: Props) {
        super(props);

        // Bind event handlers so they are only bound once per instance.
        this._onMouseOut = this._onMouseOut.bind(this);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onSetOverflowVisible = this._onSetOverflowVisible.bind(this);
        this._onTabIn = this._onTabIn.bind(this);

        this._onShortcutToggleChat = this._onShortcutToggleChat.bind(this);
        this._onShortcutToggleFullScreen = this._onShortcutToggleFullScreen.bind(this);
        this._onShortcutToggleRaiseHand = this._onShortcutToggleRaiseHand.bind(this);
        this._onShortcutToggleScreenshare = this._onShortcutToggleScreenshare.bind(this);
        this._onShortcutToggleVideoQuality = this._onShortcutToggleVideoQuality.bind(this);
        this._onToolbarOpenFeedback = this._onToolbarOpenFeedback.bind(this);
        this._onToolbarOpenInvite = this._onToolbarOpenInvite.bind(this);
        this._onToolbarOpenKeyboardShortcuts = this._onToolbarOpenKeyboardShortcuts.bind(this);
        this._onToolbarOpenSpeakerStats = this._onToolbarOpenSpeakerStats.bind(this);
        this._onToolbarOpenEmbedMeeting = this._onToolbarOpenEmbedMeeting.bind(this);
        this._onToolbarOpenVideoQuality = this._onToolbarOpenVideoQuality.bind(this);
        this._onToolbarToggleChat = this._onToolbarToggleChat.bind(this);
        this._onToolbarToggleFullScreen = this._onToolbarToggleFullScreen.bind(this);
        this._onToolbarToggleProfile = this._onToolbarToggleProfile.bind(this);
        this._onToolbarToggleRaiseHand = this._onToolbarToggleRaiseHand.bind(this);
        this._onToolbarToggleScreenshare = this._onToolbarToggleScreenshare.bind(this);
        this._onToolbarOpenLocalRecordingInfoDialog = this._onToolbarOpenLocalRecordingInfoDialog.bind(this);
        this._onShortcutToggleTileView = this._onShortcutToggleTileView.bind(this);
        this._onToolbarVoting = this._onToolbarVoting.bind(this);
        this._onToolbarReduxPoll = this._onToolbarReduxPoll.bind(this);
        this._onToolbarCallEnd = this._onToolbarCallEnd.bind(this);
        this._onToolbarNotepad=this._onToolbarNotepad.bind(this);
        this._onToolbarToggleNotes = this._onToolbarToggleNotes.bind(this);
        this._stopQuiz=this._stopQuiz.bind(this);
        this._onToolbarToggleSharedVideo = this._onToolbarToggleSharedVideo.bind(this);
        this._onToolbarToggleParticipantsPane = this._onToolbarToggleParticipantsPane.bind(this);
        this.state = {
            windowWidth: window.innerWidth,
            quizIds:[],
            isQuiz:false,
            _isQuizInfoReceived:false,
            isPoll:false,
            isPollDataReceived:false,
            lastCheck: 0,
            pollid: "",
            count: "",
            cursorShared: false,
            lastCheckForPoll:0,
        };
    }

    /**
     * Sets keyboard shortcuts for to trigger ToolbarButtons actions.
     *
     * @inheritdoc
     * @returns {void}
     */
    componentDidMount() {
        const KEYBOARD_SHORTCUTS = [
            this._shouldShowButton('videoquality') && {
                character: 'A',
                exec: this._onShortcutToggleVideoQuality,
                helpDescription: 'toolbar.callQuality'
            },
            this._shouldShowButton('chat') && {
                character: 'C',
                exec: this._onShortcutToggleChat,
                helpDescription: 'keyboardShortcuts.toggleChat'
            },
            this._shouldShowButton('desktop') && this.props.ScreenShareBtnVisiblity && {
                character: 'D',
                exec: this._onShortcutToggleScreenshare,
                helpDescription: 'keyboardShortcuts.toggleScreensharing'
            } ,
            this._shouldShowButton('raisehand') && {
                character: 'R',
                exec: this._onShortcutToggleRaiseHand,
                helpDescription: 'keyboardShortcuts.raiseHand'
            },
            this._shouldShowButton('fullscreen') && {
                character: 'S',
                exec: this._onShortcutToggleFullScreen,
                helpDescription: 'keyboardShortcuts.fullScreen'
            },
            this._shouldShowButton('tileview') && {
                character: 'W',
                exec: this._onShortcutToggleTileView,
                helpDescription: 'toolbar.tileViewToggle'
            }
        ];

        KEYBOARD_SHORTCUTS.forEach(shortcut => {
            if (typeof shortcut === 'object') {
                APP.keyboardshortcut.registerShortcut(
                    shortcut.character,
                    null,
                    shortcut.exec,
                    shortcut.helpDescription);
            }
        });

   
    }

    /**
     * Update the visibility of the {@code OverflowMenuButton}.
     *
     * @inheritdoc
     */
    componentDidUpdate(prevProps) {
        // Ensure the dialog is closed when the toolbox becomes hidden.
        if (prevProps._overflowMenuVisible && !this.props._visible) {
            this._onSetOverflowVisible(false);
        }

        if (prevProps._overflowMenuVisible
            && !prevProps._dialog
            && this.props._dialog) {
            this._onSetOverflowVisible(false);
            this.props.dispatch(setToolbarHovered(false));
        }

        // if(!this.state._isQuizInfoReceived )
        // {
        //     this.getQuizInformation();
        // }

        // if(!this.state.isPoll && typeof(APP.conference.roomName)!="undefined")
        // {
        //     this.loadPollInformation();
        // }

        if ((this.state._isQuizInfoReceived != null || typeof (this.state._isQuizInfoReceived) == "undefined") && this.isUpdateRequired()) {
            this.setState({ lastCheck: new Date().getTime() });   
            this.getQuizInformation();
           
        }


        if (!this.state.isPoll && typeof (APP.conference.roomName) != "undefined"  && this.isUpdateRequiredForPoll()) {
            this.setState({ lastCheckForPoll: new Date().getTime() });            
            this.loadPollInformation();
        }

    }




    isUpdateRequired() {
        const diff = new Date().getTime() - this.state.lastCheck;
        const isRequied = diff > 30 * 1000;
        return isRequied;
    }

    isUpdateRequiredForPoll() {
        const diff = new Date().getTime() - this.state.lastCheckForPoll;
        const isRequied = diff > 30 * 1000;
        return isRequied;
    }

    loadPollInformation()
    {
        var apiDomain=this.props._defaultAPIUrl;
        fetch(apiDomain+"/poll/getPollData/"+APP.conference.roomName)   
        .then(res => res.json())
        .then(
          (result) => {  
              if(result.pollData != null||result[0]!=null){ 
                this.setState({pollid:typeof(result.pollData)!='undefined'?result.pollData.id:result[0].pollData.id,isPollDataReceived:true})
              fetch(apiDomain+"/poll/getPollQuestionFullData/"+this.state.pollid)
              .then(res => res.json())
              .then(
                (result) => { 
                    if(result.pollQuestionAns != null){                             
                    this.setState({isPoll : true})                          
                   }   
                  })        
               }     
      }) 
    }
    /**
     * Removes keyboard shortcuts registered by this component.
     *
     * @inheritdoc
     * @returns {void}
     */
    componentWillUnmount() {
        [ 'A', 'C', 'D', 'R', 'S' ].forEach(letter =>
            APP.keyboardshortcut.unregisterShortcut(letter));
    }

    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { _chatOpen, _visible, _visibleButtons,_isNotesOpen, _participantsPaneOpen } = this.props;
        const rootClassNames = `new-toolbox ${_visible ? 'visible' : ''} ${_visibleButtons.length ? '' : 'visible'} ${_chatOpen ? 'shift-right' : ''} ${_isNotesOpen?'opennotes':''} ${_participantsPaneOpen?' openpane':''}`;
        

        // if(this.props._participantsPaneOpen)
        // {
        //     var width=$(".participants_pane-content").width();
        //     $("#remoteVideos").css({
        //         'right':width
        //     });
        // }
        // else
        // {
        //     $("#remoteVideos").css({
        //         'right':0
        //     }); 
        // }

        return (
            <div
                className={rootClassNames}
                id='new-toolbox'
                onFocus={this._onTabIn}
                onMouseOut={this._onMouseOut}
                onMouseOver={this._onMouseOver}>
                { this._renderToolboxContent()}
            </div>
        );
    }

    /**
     * Closes the overflow menu if opened.
     *
     * @private
     * @returns {void}
     */
    _closeOverflowMenuIfOpen() {
        const { dispatch, _overflowMenuVisible } = this.props;

        _overflowMenuVisible && dispatch(setOverflowMenuVisible(false));
    }

    /**
     * Callback invoked to display {@code FeedbackDialog}.
     *
     * @private
     * @returns {void}
     */
    _doOpenFeedback() {
        const { _conference } = this.props;

        this.props.dispatch(openFeedbackDialog(_conference));
    }

    /**
     * Callback invoked to display {@code FeedbackDialog}.
     *
     * @private
     * @returns {void}
     */
    _doOpenEmbedMeeting() {
        this.props.dispatch(openDialog(EmbedMeetingDialog));
    }

    /**
     * Dispatches an action to display {@code KeyboardShortcuts}.
     *
     * @private
     * @returns {void}
     */
    _doOpenKeyboardShorcuts() {
        this.props.dispatch(openKeyboardShortcutsDialog());
    }

    /**
     * Callback invoked to display {@code SpeakerStats}.
     *
     * @private
     * @returns {void}
     */
    _doOpenSpeakerStats() {
        this.props.dispatch(openDialog(SpeakerStats, {
            conference: this.props._conference
        }));
    }

    /**
     * Dispatches an action to open the video quality dialog.
     *
     * @private
     * @returns {void}
     */
    _doOpenVideoQuality() {
        this.props.dispatch(openDialog(VideoQualityDialog));
    }

    /**
     * Dispatches an action to toggle the display of chat.
     *
     * @private
     * @returns {void}
     */
    _doToggleChat() {
        this.props.dispatch(toggleChat());
    }




    _doToggleNotes() {	
        this.props.dispatch(toggleNotes());	
    }	
    _doToggleReduxPoll() {    
     
        this.props.dispatch(openQuizDialog());
        this.props.dispatch(updatePollState())	
    }


    _doToggleVoting() {
        this.props.dispatch(ToggleVotingPoll())    	
        this.props.dispatch(openPollDialogMessage());
    }


    _onToolbarToggleNotes: () => void;	
    /**	
     * Creates an analytics toolbar event and dispatches an action for toggling	
     * the display of chat.	
     *	
     * @private	
     * @returns {void}	
     */	
    _onToolbarToggleNotes() {	
        sendAnalytics(createToolbarEvent(	
            'toggle.notes',	
            {	
                enable: !this.props._NoteOpen	
            }));	
        this._doToggleNotes();	
    }

    _stopQuiz: () => void;
    _stopQuiz()
    {
        
        this.props.dispatch(stopQuiz())    
    }

    _onToolbarReduxPoll: () => void;
    _onToolbarReduxPoll() {        
        if(!this.props._isQuizRunning)
        {
            sendAnalytics(createToolbarEvent(
                'toggle.poll',
                {
                    enable: !this.props._showPoll
                }
            ));
            this._doToggleReduxPoll();
            APP.conference.ShowPollForAll();
        }
        else
        {
            this._stopQuiz();
        }

    }

    
    


    //---------------------call end for all -------
    _onToolbarCallEnd: () => void;
    _onToolbarCallEnd() {
        this._doToggleCallEnd();

    }

        // ---------------- Notepad code here --------     
        _onToolbarNotepad:() => void;
        _onToolbarNotepad(){
            this._doToggleNotepad();
    
        }


    /**
     * Dispatches an action to toggle screensharing.
     *
     * @private
     * @returns {void}
     */
    _doToggleFullScreen() {
        const fullScreen = !this.props._fullScreen;

        this.props.dispatch(setFullScreen(fullScreen));
    }

    /**
     * Dispatches an action to show or hide the profile edit panel.
     *
     * @private
     * @returns {void}
     */
    _doToggleProfile() {
        this.props.dispatch(openSettingsDialog(SETTINGS_TABS.PROFILE));
    }

    /**
     * Dispatches an action to toggle the local participant's raised hand state.
     *
     * @private
     * @returns {void}
     */
    _doToggleRaiseHand() {
        const { _localParticipantID, _raisedHand } = this.props;
        const newRaisedStatus = !_raisedHand;

        this.props.dispatch(participantUpdated({
            // XXX Only the local participant is allowed to update without
            // stating the JitsiConference instance (i.e. participant property
            // `conference` for a remote participant) because the local
            // participant is uniquely identified by the very fact that there is
            // only one local participant.

            id: _localParticipantID,
            local: true,
            raisedHand: newRaisedStatus
        }));

        APP.API.notifyRaiseHandUpdated(_localParticipantID, newRaisedStatus);
    }

    /**
     * Dispatches an action to toggle screensharing.
     *
     * @private
     * @returns {void}
     */
    _doToggleScreenshare() {
        if (this.props._desktopSharingEnabled) {
            this.props.dispatch(toggleScreensharing());
        }
    }

    /**
     * Dispatches an action to toggle the video quality dialog.
     *
     * @private
     * @returns {void}
     */
    _doToggleVideoQuality() {
        this.props.dispatch(toggleDialog(VideoQualityDialog));
    }

    /**
     * Dispaches an action to toggle tile view.
     *
     * @private
     * @returns {void}
     */
    _doToggleTileView() {
        this.props.dispatch(toggleTileView());
    }

    _onMouseOut: () => void;

    /**
     * Dispatches an action signaling the toolbar is not being hovered.
     *
     * @private
     * @returns {void}
     */
    _onMouseOut() {
        this.props.dispatch(setToolbarHovered(false));
    }

    _onMouseOver: () => void;

    /**
     * Dispatches an action signaling the toolbar is being hovered.
     *
     * @private
     * @returns {void}
     */
    _onMouseOver() {
        this.props.dispatch(setToolbarHovered(true));
    }


    _onSetOverflowVisible: (boolean) => void;

    /**
     * Sets the visibility of the overflow menu.
     *
     * @param {boolean} visible - Whether or not the overflow menu should be
     * displayed.
     * @private
     * @returns {void}
     */
    _onSetOverflowVisible(visible) {
        this.props.dispatch(setOverflowMenuVisible(visible));
    }

    _onShortcutToggleChat: () => void;

    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling the display of chat.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleChat() {
        sendAnalytics(createShortcutEvent(
            'toggle.chat',
            {
                enable: !this.props._chatOpen
            }));

        this._doToggleChat();
    }

    _onShortcutToggleVideoQuality: () => void;

    /**
    * Creates an analytics keyboard shortcut event and dispatches an action for
    * toggling the display of Video Quality.
    *
    * @private
    * @returns {void}
    */
    _onShortcutToggleVideoQuality() {
        sendAnalytics(createShortcutEvent('video.quality'));

        this._doToggleVideoQuality();
    }

    _onShortcutToggleTileView: () => void;

    /**
     * Dispatches an action for toggling the tile view.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleTileView() {
        sendAnalytics(createShortcutEvent(
            'toggle.tileview',
            {
                enable: !this.props._tileViewEnabled
            }));

        this._doToggleTileView();
    }

    _onShortcutToggleFullScreen: () => void;

    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling full screen mode.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleFullScreen() {
        sendAnalytics(createShortcutEvent(
            'toggle.fullscreen',
            {
                enable: !this.props._fullScreen
            }));

        this._doToggleFullScreen();
    }

    _onShortcutToggleRaiseHand: () => void;

    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling raise hand.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleRaiseHand() {
        sendAnalytics(createShortcutEvent(
            'toggle.raise.hand',
            ACTION_SHORTCUT_TRIGGERED,
            { enable: !this.props._raisedHand }));

        this._doToggleRaiseHand();
    }

    _onShortcutToggleScreenshare: () => void;

    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling screensharing.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleScreenshare() {
        sendAnalytics(createToolbarEvent(
            'screen.sharing',
            {
                enable: !this.props._screensharing
            }));

        this._doToggleScreenshare();
    }

    _onTabIn: () => void;

    /**
     * Toggle the toolbar visibility when tabbing into it.
     *
     * @returns {void}
     */
    _onTabIn() {
        if (!this.props._visible) {
            this.props.dispatch(setToolboxVisible(true));
        }
    }

    _onToolbarOpenFeedback: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * display of feedback.
     *
     * @private
     * @returns {void}
     */
    _onToolbarOpenFeedback() {
        sendAnalytics(createToolbarEvent('feedback'));

        this._doOpenFeedback();
    }

    _onToolbarOpenInvite: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for opening
     * the modal for inviting people directly into the conference.
     *
     * @private
     * @returns {void}
     */
    _onToolbarOpenInvite() {
        sendAnalytics(createToolbarEvent('invite'));
        this.props.dispatch(beginAddPeople());
    }

    _onToolbarOpenKeyboardShortcuts: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for opening
     * the modal for showing available keyboard shortcuts.
     *
     * @private
     * @returns {void}
     */
    _onToolbarOpenKeyboardShortcuts() {
        sendAnalytics(createToolbarEvent('shortcuts'));

        this._doOpenKeyboardShorcuts();
    }

    _onToolbarOpenEmbedMeeting: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for opening
     * the embed meeting modal.
     *
     * @private
     * @returns {void}
     */
    _onToolbarOpenEmbedMeeting() {
        sendAnalytics(createToolbarEvent('embed.meeting'));

        this._doOpenEmbedMeeting();
    }

    _onToolbarOpenSpeakerStats: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for opening
     * the speaker stats modal.
     *
     * @private
     * @returns {void}
     */
    _onToolbarOpenSpeakerStats() {
        sendAnalytics(createToolbarEvent('speaker.stats'));

        this._doOpenSpeakerStats();
    }

    _onToolbarOpenVideoQuality: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * open the video quality dialog.
     *
     * @private
     * @returns {void}
     */
    _onToolbarOpenVideoQuality() {
        sendAnalytics(createToolbarEvent('video.quality'));

        this._doOpenVideoQuality();
    }

    _onToolbarToggleChat: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * the display of chat.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleChat() {
        sendAnalytics(createToolbarEvent(
            'toggle.chat',
            {
                enable: !this.props._chatOpen
            }));
        this._closeOverflowMenuIfOpen();
        this._doToggleChat();
    }



    _doToggleNotes() {	
        this.props.dispatch(toggleNotes());	
    }	
    _doToggleReduxPoll() {    
        this.props.dispatch(toggleReduxPoll())    	
        this.props.dispatch(openQuizDialog());	
    }



    _onToolbarToggleNotes: () => void;	
    /**	
     * Creates an analytics toolbar event and dispatches an action for toggling	
     * the display of chat.	
     *	
     * @private	
     * @returns {void}	
     */	
    _onToolbarToggleNotes() {	
        sendAnalytics(createToolbarEvent(	
            'toggle.notes',	
            {	
                enable: !this.props._NoteOpen	
            }));	
        this._doToggleNotes();	
    }

    _stopQuiz: () => void;
    _stopQuiz()
    {
        
        this.props.dispatch(stopQuiz())    
    }

    _onToolbarReduxPoll: () => void;
    _onToolbarReduxPoll() {        
        if(!this.props._isQuizRunning)
        {
            sendAnalytics(createToolbarEvent(
                'toggle.poll',
                {
                    enable: !this.props._showPoll
                }
            ));
            this._doToggleReduxPoll();
            APP.conference.ShowPollForAll();
        }
        else
        {
            this._stopQuiz();
        }

    }

    
    


    //---------------------call end for all -------
    _onToolbarCallEnd: () => void;
    _onToolbarCallEnd() {
        this._doToggleCallEnd();

    }

        // ---------------- Notepad code here --------     
        _onToolbarNotepad:() => void;
        _onToolbarNotepad(){
            this._doToggleNotepad();
    
        }


    _onToolbarToggleFullScreen: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * full screen mode.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleFullScreen() {
        sendAnalytics(createToolbarEvent(
            'toggle.fullscreen',
                {
                    enable: !this.props._fullScreen
                }));
        this._closeOverflowMenuIfOpen();
        this._doToggleFullScreen();
    }

    _onToolbarToggleProfile: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for showing
     * or hiding the profile edit panel.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleProfile() {
        sendAnalytics(createToolbarEvent('profile'));

        this._doToggleProfile();
    }

    _onToolbarToggleRaiseHand: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * raise hand.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleRaiseHand() {
        sendAnalytics(createToolbarEvent(
            'raise.hand',
            { enable: !this.props._raisedHand }));

        this._doToggleRaiseHand();
    }
    stopPoll: () => void;
    _stopPoll()
    {
        
        this.props.dispatch(stopPoll())    
    }

        //------------------- Voting -----------------------
        _onToolbarVoting: () => void;
        _onToolbarVoting() {
            if(!this.props._isPollRunning)
            {
            sendAnalytics(createToolbarEvent(
                'toggle.vote',
                {
                    enable: !this.props._voting
                }
            ));
            this._doToggleVoting();
            APP.conference.ShowVotePollForAll();
            }else{
                this._stopPoll();
            }
        }

        

    _onToolbarToggleScreenshare: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * screensharing.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleScreenshare() {
        if (!this.props._desktopSharingEnabled) {
            return;
        }

        sendAnalytics(createShortcutEvent(
            'toggle.screen.sharing',
            ACTION_SHORTCUT_TRIGGERED,
            { enable: !this.props._screensharing }));

        this._closeOverflowMenuIfOpen();
        this._doToggleScreenshare();
    }


    _onToolbarToggleSharedVideo: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * the sharing of a YouTube video.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleSharedVideo() {
        sendAnalytics(createToolbarEvent('shared.video.toggled',
            {
                enable: !this.props._sharingVideo
            }));

        this._doToggleSharedVideo();
    }


  /**
     * Dispatches an action to toggle YouTube video sharing.
     *
     * @private
     * @returns {void}
     */
   _doToggleSharedVideo() {
    this.props.dispatch(toggleSharedVideo());
}



    _onToolbarOpenLocalRecordingInfoDialog: () => void;

    /**
     * Opens the {@code LocalRecordingInfoDialog}.
     *
     * @private
     * @returns {void}
     */
    _onToolbarOpenLocalRecordingInfoDialog() {
        sendAnalytics(createToolbarEvent('local.recording'));

        this.props.dispatch(openDialog(LocalRecordingInfoDialog));
    }

    /**
     * Returns true if the desktop sharing button should be visible and
     * false otherwise.
     *
     * @returns {boolean}
     */
    _showDesktopSharingButton() {
        const {
            _desktopSharingEnabled,
            _desktopSharingDisabledTooltipKey,
            _isMobile
        } = this.props;

        return (
            (_desktopSharingEnabled
            || _desktopSharingDisabledTooltipKey)
            &&!_isMobile 
            && this._shouldShowButton('desktop') && (this.props.ScreenShareBtnVisiblity )
        );
    }

    /**
     * Returns true if the embed meeting button is visible and false otherwise.
     *
     * @returns {boolean}
     */
    _isEmbedMeetingVisible() {
        return !this.props._isVpaasMeeting
            && !this.props._isMobile
            && this._shouldShowButton('embedmeeting');
    }

    /**
     * Returns true if the profile button is visible and false otherwise.
     *
     * @returns {boolean}
     */
    _isProfileVisible() {
        return !this.props._isProfileDisabled && this._shouldShowButton('profile');
    }

    updateView()
    {
        /**
         *  Let us put some delay as view might take 
         */
        setTimeout(() => {
            VideoLayout.onResize();            
        }, 50);
    }

    _onToolbarToggleParticipantsPane: () => void;

    _onToolbarToggleParticipantsPane() {
        const { dispatch, _participantsPaneOpen } = this.props;
        if (this.props._participantsPaneOpen) {
           dispatch(toggleParticipantPanel(false));
        } else {
            dispatch(toggleParticipantPanel(true));
        }
        this.updateView();
    }


    /**
     * Renders the list elements of the overflow menu.
     *
     * @private
     * @param {Array<React$Element>} additionalButtons - Additional buttons to be displayed.
     * @returns {Array<React$Element>}
     */
    _renderOverflowMenuContent(additionalButtons: Array<React$Element<any>>) {
        const {
            _feedbackConfigured,
            _fullScreen,
            _isMobile,
            _screensharing,
            _sharingVideo,
            t
        } = this.props;

        const group1 = [
            ...additionalButtons,

            this._shouldShowButton('toggle-camera')
                && <ToggleCameraButton
                    key = 'toggle-camera'
                    showLabel = { true } />,
            this._shouldShowButton('videoquality')
                && <OverflowMenuVideoQualityItem
                    key = 'videoquality'
                    onClick = { this._onToolbarOpenVideoQuality } />,
            this._shouldShowButton('voting-poll')&&
            this.props._isHost &&
             this.state.isPoll == true &&              
                      <OverflowMenuItem
                            accessibilityLabel = { t('toolbar.accessibilityLabel.vote') }
                            icon = { IconVote }
                            key = 'vote'
                            onClick = { this._onToolbarVoting }                           
                            text={this.props._isPollRunning ? t('toolbar.stopPoll') : t('toolbar.startPoll')} />,
            this._shouldShowButton('fullscreen')
                && !_isMobile
                && <OverflowMenuItem
                    accessibilityLabel = { t('toolbar.accessibilityLabel.fullScreen') }
                    icon = { _fullScreen ? IconExitFullScreen : IconFullScreen }
                    key = 'fullscreen'
                    onClick = { this._onToolbarToggleFullScreen }
                    text = { _fullScreen ? t('toolbar.exitFullScreen') : t('toolbar.enterFullScreen') } />,
            /* Button for NotPad functionality */
                            this._shouldShowButton('notepad')&&
                               <OverflowMenuItem
                                   accessibilityLabel={t('toolbar.accessibilityLabel.notepad')}
                                   icon={Notepad}
                                   key='notepad'
                                   onClick={this._onToolbarToggleNotes}
                                   text={t('toolbar.notepad')} />,
                   
                               //    <OverflowMenuItem
                               //     accessibilityLabel = { t('toolbar.accessibilityLabel.notepad') }
                               //     icon = { Notepad }
                               //     key = 'notepad'
                               //     onClick = { this._onToolbarNotepad }
                               //     text = { t('toolbar.notepad') } />,
                   
                               /**
                                * Button for poll start functionality
                                */
                               this.state.isQuiz == true
                               && this.props._isHost
                               && this._shouldShowButton('Quiz')&& 
                               <OverflowMenuItem
                                   accessibilityLabel={t('toolbar.accessibilityLabel.poll')}
                                   icon={IconPolls}
                                   key='poll'
                                   onClick={this._onToolbarReduxPoll}
                                   text={this.props._isQuizRunning ? t('toolbar.stopQuiz') : t('toolbar.startQuiz')} />,
                     
            this._shouldShowButton('recording')&&
            this.props._isHost
            && <RecordButton
                    key = 'record'
                    showLabel = { true } />,
            this._shouldShowButton('mute-everyone')&&
            this.props._isHost
                && <MuteEveryoneButton
                    key = 'mute-everyone'
                    showLabel = { true } />,

            this._shouldShowButton('mute-video-everyone')&&
            this.props._isHost
                && <MuteEveryonesVideoButton
                    key = 'mute-everyones-video'
                    showLabel = { true } />,
        ];

        const group2 = [
            this._shouldShowButton('sharedvideo')
            && <OverflowMenuItem
                accessibilityLabel = { t('toolbar.accessibilityLabel.sharedvideo') }
                icon = { IconShareVideo }
                key = 'sharedvideo'
                onClick = { this._onToolbarToggleSharedVideo }
                text = { _sharingVideo ? t('toolbar.stopSharedVideo') : t('toolbar.sharedvideo') } />, 
            this._shouldShowButton('etherpad')
                && <SharedDocumentButton
                    key = 'etherpad'
                    showLabel = { true } />,
            (this._shouldShowButton('select-background') || this._shouldShowButton('videobackgroundblur'))
                && <VideoBackgroundButton
                    key = { 'select-background' }
                    showLabel = { true }
                    visible = { !_screensharing && checkBlurSupport() } />,
            this._shouldShowButton('stats')
                && <OverflowMenuItem
                    accessibilityLabel = { t('toolbar.accessibilityLabel.speakerStats') }
                    icon = { IconPresentation }
                    key = 'stats'
                    onClick = { this._onToolbarOpenSpeakerStats }
                    text = { t('toolbar.speakerStats') } />
        ];


        return [
            this._isProfileVisible()
                && <OverflowMenuProfileItem
                    key = 'profile'
                    onClick = { this._onToolbarToggleProfile } />,
            this._isProfileVisible()
                && <hr
                    className = 'overflow-menu-hr'
                    key = 'hr1' />,

            ...group1,
            group1.some(Boolean)
            && <hr
                className = 'overflow-menu-hr'
                key = 'hr2' />,

            ...group2,
            group2.some(Boolean)
            && <hr
                className = 'overflow-menu-hr'
                key = 'hr3' />,

            this._shouldShowButton('settings')
                && <SettingsButton
                    key = 'settings'
                    showLabel = { true } />,

            this._shouldShowButton('help')
                && <HelpButton
                    key = 'help'
                    showLabel = { true } />
        ];
    }

    /**
     * Returns the buttons to be displayed in main or the overflow menu.
     *
     * @param {Set} buttons - A set containing the buttons to be displayed
     * in the toolbar beside the main audio/video & hanugup.
     * @returns {Object}
     */
    _getAdditionalButtons(buttons) {
        const {
            _chatOpen,
            _desktopSharingEnabled,
            _desktopSharingDisabledTooltipKey,
            _raisedHand,
            _screensharing,
            t,
            _isMobile
        } = this.props;

        const overflowMenuAdditionalButtons = [];
        const mainMenuAdditionalButtons = [];

        if (this._showDesktopSharingButton()) {
            buttons.has('desktop')
                ? mainMenuAdditionalButtons.push(<ToolbarButton
                    accessibilityLabel = { t('toolbar.accessibilityLabel.shareYourScreen') }
                    disabled = { !_desktopSharingEnabled }
                    icon = { IconShareDesktop }
                    key = 'desktop'
                    onClick = { this._onToolbarToggleScreenshare }
                    toggled = { _screensharing }
                    tooltip = { t(_desktopSharingEnabled
                        ? 'dialog.shareYourScreen' : _desktopSharingDisabledTooltipKey) } />)
                : overflowMenuAdditionalButtons.push(<OverflowMenuItem
                    accessibilityLabel = { t('toolbar.accessibilityLabel.shareYourScreen') }
                    icon = { IconShareDesktop }
                    iconId = 'share-desktop'
                    key = 'desktop'
                    onClick = { this._onToolbarToggleScreenshare }
                    text = { t(`toolbar.${_screensharing ? 'stopScreenSharing' : 'startScreenSharing'}`) } />);
        }

        // if (this._shouldShowButton('chat')) {
        //     buttons.has('chat')
        //         ? mainMenuAdditionalButtons.push(<div
        //             className = 'toolbar-button-with-badge'
        //             key = 'chatcontainer'>
        //             <ToolbarButton
        //                 accessibilityLabel = { t('toolbar.accessibilityLabel.chat') }
        //                 icon = { IconChat }
        //                 key = 'chat'
        //                 onClick = { this._onToolbarToggleChat }
        //                 toggled = { _chatOpen }
        //                 tooltip = { t('toolbar.chat') } />
        //             <ChatCounter />
        //         </div>) : overflowMenuAdditionalButtons.push(<OverflowMenuItem
        //             accessibilityLabel = { t('toolbar.accessibilityLabel.chat') }
        //             icon = { IconChat }
        //             key = 'chat'
        //             onClick = { this._onToolbarToggleChat }
        //             text = { t(`toolbar.${_chatOpen ? 'closeChat' : 'openChat'}`) } />);
        // }

        if (this._shouldShowButton('raisehand')) {
            buttons.has('raisehand')
                ? mainMenuAdditionalButtons.push(<ToolbarButton
                    accessibilityLabel = { t('toolbar.accessibilityLabel.raiseHand') }
                    icon = { IconRaisedHand }
                    key = 'raisehand'
                    onClick = { this._onToolbarToggleRaiseHand }
                    toggled = { _raisedHand }
                    tooltip = { t(`toolbar.${_raisedHand ? 'lowerYourHand' : 'raiseYourHand'}`) } />)
                : overflowMenuAdditionalButtons.push(<OverflowMenuItem
                    accessibilityLabel = { t('toolbar.accessibilityLabel.raiseHand') }
                    icon = { IconRaisedHand }
                    key = 'raisehand'
                    onClick = { this._onToolbarToggleRaiseHand }
                    text = { t(`toolbar.${_raisedHand ? 'lowerYourHand' : 'raiseYourHand'}`) } />);
        }

        if (this._shouldShowButton('tileview')) {
            buttons.has('tileview')
                ? mainMenuAdditionalButtons.push(
                    <TileViewButton
                        key = 'tileview'
                        showLabel = { false } />)
                : overflowMenuAdditionalButtons.push(
                    <TileViewButton
                        key = 'tileview'
                        showLabel = { true } />);
        }
        mainMenuAdditionalButtons.push(
            <InfoDialogButton/>);
            
        if (this._shouldShowButton('invite')) {
            // buttons.has('invite')
            //     ? mainMenuAdditionalButtons.push(
            //         <ToolbarButton
            //             accessibilityLabel = { t('toolbar.accessibilityLabel.invite') }
            //             icon = { IconInviteMore }
            //             key = 'invite'
            //             onClick = { this._onToolbarOpenInvite }
            //             tooltip = { t('toolbar.invite') } />)
            //     : overflowMenuAdditionalButtons.push(
            //         <OverflowMenuItem
            //             accessibilityLabel = { t('toolbar.accessibilityLabel.invite') }
            //             icon = { IconInviteMore }
            //             key = 'invite'
            //             onClick = { this._onToolbarOpenInvite }
            //             text = { t('toolbar.invite') } />
            //     ); this._shouldShowButton('participantView')
            
         
        }

    !_isMobile && mainMenuAdditionalButtons.push(
            <ToolbarButton
                accessibilityLabel={t('toolbar.accessibilityLabel.participants')}
                icon={IconParticipants}
                onClick={this._onToolbarToggleParticipantsPane}
                toggled={this.props._participantsPaneOpen}
                tooltip={t('toolbar.participants')} />)

        return {
            mainMenuAdditionalButtons,
            overflowMenuAdditionalButtons
        };
    }

    /**
     * Renders the Audio controlling button.
     *
     * @returns {ReactElement}
     */
    _renderAudioButton() {
        //|| this.props._localParticipant.role==PARTICIPANT_ROLE.MODERATOR
        return this._shouldShowButton('microphone') && (this.props.AudioBtnVisiblity )
            ? <AudioSettingsButton
                key = 'asb'
                visible = { true } />
            : null;
    }

    /**
     * Renders the Video controlling button.
     *
     * @returns {ReactElement}
     */
    _renderVideoButton() {
        //|| this.props._localParticipant.role==PARTICIPANT_ROLE.MODERATOR
        return this._shouldShowButton('camera') && (this.props.VideoBtnVisiblity)
        ? isMobileBrowser() ?
            <ToggleCameraButton
                key='tvb'
                visible={true} />

            :
            <VideoSettingsButton
                key='vsb'
                visible={true} />
        : null;
    }

    /**
     * Renders the toolbox content.
     *
     * @returns {ReactElement}
     */
    _renderToolboxContent() {
        const {
            _clientWidth,
            _isMobile,
            _overflowMenuVisible,
            t
        } = this.props;

        const buttonSet = getToolbarAdditionalButtons(_clientWidth, _isMobile);
        const toolbarAccLabel = 'toolbar.accessibilityLabel.moreActionsMenu';
        const showOverflowMenuButton = buttonSet.has('overflow');
        const containerClassName = `toolbox-content${_isMobile ? ' toolbox-content-mobile' : ''}`;
        let overflowMenuAdditionalButtons = [];
        let mainMenuAdditionalButtons = [];


        if (showOverflowMenuButton) {
            ({ overflowMenuAdditionalButtons, mainMenuAdditionalButtons } = this._getAdditionalButtons(this.props._visibleButtons));
        }

        return (
            <div className = { containerClassName }>
                <div className = 'toolbox-content-wrapper'>
                    {/* <InviteMore /> */}
                    <div className = 'toolbox-content-items'>
                        { this._renderAudioButton() }
                        { this._renderVideoButton() }
                        { mainMenuAdditionalButtons }
                        {showOverflowMenuButton && <OverflowMenuButton
                            isOpen={_overflowMenuVisible}
                            customClass='OverFlowMenu'
                            onVisibilityChange={this._onSetOverflowVisible}>
                            <ul
                                aria-label={t(toolbarAccLabel)}
                                className='overflow-menu'>
                                {this._renderOverflowMenuContent(overflowMenuAdditionalButtons)}
                            </ul>
                        </OverflowMenuButton>}   
  
                        {this._shouldShowButton('endcallforall') 
                            &&APP.conference.participantCount >1              
                            && <EndCallOptionsDialog/> } 

                            {this._shouldShowButton('endcallforall') 
                        && APP.conference.participantCount ==1           
                        &&<HangupButton
                            customClass = 'hangup-button'
                            visible = { this._shouldShowButton('hangup') } /> }  

                            {!this._shouldShowButton('endcallforall')                  
                            &&<HangupButton
                            customClass = 'hangup-button'
                            visible = { this._shouldShowButton('hangup') } />} 
                    </div>
                </div>
            </div>
        );
    }

    _shouldShowButton: (string) => boolean;

    /**
     * Returns if a button name has been explicitly configured to be displayed.
     *
     * @param {string} buttonName - The name of the button, as expected in
     * {@link interfaceConfig}.
     * @private
     * @returns {boolean} True if the button should be displayed.
     */
    _shouldShowButton(buttonName) {
        return this.props._visibleButtons.has(buttonName);
    }


    getQuizInformation() {
        var apiDomain=this.props._defaultAPIUrl;
        fetch(apiDomain+"/quiz/getQuizNameMeetingCode/"+APP.conference.roomName)
        .then(res => res.json())
        .then(
          (result) => {  
              if(result.length >0){
              result.forEach(element => { this.setState({quizIds: element })});             
              fetch(apiDomain+"quiz/getQuizQuestionFullData/"+this.state.quizIds.quizid)
              .then(res => res.json())
              .then(
                (result) => { 
                    this.setState({ _isQuizInfoReceived:true});
                    if(result.quizAndPollNews.length >0){                             
                    this.setState({isQuiz : true})                          
                   }   
                  })        
               }     
      }) 
    }



    _getFeaturesForMeeting(meetingCode) {
        if(meetingCode!='undefined')
        {
            const apiDomain = getAPIURL(APP.store.getState());
            axios.get(apiDomain+`/anonymous/features/` + meetingCode)
            .then(res => {
                const feature = res.data;
                let data = feature.features;
                if(data!=null)
                {
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].feature_id == 1) {
                            this.setState({ isCloseCaptionEnabled: true });
                        }
                    }
                    this.setState({ isStatusReceived: true });
    
                }

            })

        }
    }

       /**
     * 
     */
        isSubtitleRequired() {
            const { _conference } = this.props;
            if (_conference) {
                //
                let confName = _conference.getName();
                console.log(confName);
                if (!this.state.isStatusReceived) {
                    this._getFeaturesForMeeting(confName);
                }
                return this.state.isCloseCaptionEnabled;
            }
    
    
            return false;
        }

}

/**
 * Maps (parts of) the redux state to {@link Toolbox}'s React {@code Component}
 * props.
 *
 * @param {Object} state - The redux store/state.
 * @private
 * @returns {{}}
 */
function _mapStateToProps(state) {
    const { conference, locked } = state['features/base/conference'];
    let desktopSharingEnabled = JitsiMeetJS.isDesktopSharingEnabled();
    const {
        callStatsID,
        enableFeaturesBasedOnToken
    } = state['features/base/config'];
    const {
        fullScreen,
        overflowMenuVisible
    } = state['features/toolbox'];
    const localParticipant = getLocalParticipant(state);
    const localRecordingStates = state['features/local-recording'];
    const localVideo = getLocalVideoTrack(state['features/base/tracks']);
    const { clientWidth } = state['features/base/responsive-ui'];
    const sharedVideoStatus = state['features/shared-video'].status;
    let desktopSharingDisabledTooltipKey;

    if (enableFeaturesBasedOnToken) {
        // we enable desktop sharing if any participant already have this
        // feature enabled
        desktopSharingEnabled = getParticipants(state)
            .find(({ features = {} }) =>
                String(features['screen-sharing']) === 'true') !== undefined;
        desktopSharingDisabledTooltipKey = 'dialog.shareYourScreenDisabled';
    }


    const buttons = new Set(interfaceConfig.TOOLBAR_BUTTONS);

    return {
        _chatOpen: false,
        _clientWidth: clientWidth,
        _conference: conference,
        _desktopSharingEnabled: desktopSharingEnabled,
        _desktopSharingDisabledTooltipKey: desktopSharingDisabledTooltipKey,
        _dialog: Boolean(state['features/base/dialog'].component),
        _feedbackConfigured: Boolean(callStatsID),
        _isProfileDisabled: Boolean(state['features/base/config'].disableProfile),
        _isMobile: isMobileBrowser(),
        _isVpaasMeeting: isVpaasMeeting(state),
        _fullScreen: fullScreen,
        _tileViewEnabled: shouldDisplayTileView(state),
        _localParticipantID: localParticipant.id,
        _localRecState: localRecordingStates,
        _locked: locked,
        _overflowMenuVisible: overflowMenuVisible,
        _raisedHand: localParticipant.raisedHand,
        _screensharing: localVideo && localVideo.videoType === 'desktop',
        _visible: isToolboxVisible(state),
        _visibleButtons: equals(visibleButtons, buttons) ? visibleButtons : buttons,
        AudioBtnVisiblity:state['features/webinar']._isAudioBtnVisible,
        VideoBtnVisiblity:state['features/webinar']._isVideoBtnVisible,
        ScreenShareBtnVisiblity:state['features/webinar']._ScreenShareBtnVisiblity,
        _localParticipant:localParticipant,
        _sharingVideo: sharedVideoStatus === 'playing'
        || sharedVideoStatus === 'start'
        || sharedVideoStatus === 'pause',
        _isQuizRunning: state['features/reduxPoll'].isPollStarted ,
        _isPollRunning: state['features/voting'].isVotePollStarted,
        _participantsPaneOpen: state['features/webinar']._isWbParticipantPaneOpen,
        _isHost: isLocalParticipantModerator(state),
        _isNotesOpen: state['features/NoteP'].isOpen,
        _defaultAPIUrl:getAPIURL(state)

    };
}

export default translate(connect(_mapStateToProps)(WebinarToolbox));
