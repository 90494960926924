import React ,{useState,useEffect}from 'react';
import {Button } from 'react-bootstrap';
import  '../components/Quiz.css';

function LaunchPoll(props) {  
    const questions =props.data

	const [currentQuestionIndex, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
    const nextQuestion = currentQuestionIndex + 1;
    let radiocheck="";  
    let errorLog="";


    if(props.validatedata.length == nextQuestion){

        props.validatedata.map((e1, i) => {
            if (props.data.filter(e2 => e2.id === e1.id).length > 0) {  
             radiocheck=props.chekedvalue;
            } 
          })
    }
    
	const handleAnswerOptionClick = (id) => {        
     if(props.validatedata.length <= 0){       
        alert('Please give your answers.!')      
         return false;        
         
     }
     let newArray = props.validatedata.filter((item) => item.id === id);
     if(newArray.length <= 0 ){ 
        radiocheck=props.chekedvalue;                  
         alert('Please give your answers.!')     
         return false;
     }
		if (nextQuestion < questions.length) {
            setCurrentQuestion(nextQuestion);            
              
		} else {
			setShowScore(true);
		}
	};
  
    return (		
				<>           
             { questions[currentQuestionIndex].questionType == "Error" ? <h2> Poll data not found...</h2>: 
                <div>
					<div >
						<div className="st_1">
							<span>Question {currentQuestionIndex + 1}</span>/{questions.length} {errorLog} 
                        
						</div>                     
						<div className="st_1 st_2">{questions[currentQuestionIndex].question}</div>                    
					  </div>       

					  <div className="st_3">
				  		{questions[currentQuestionIndex].answers.map((answerOption,index) => (
							<>                            
                      
                            {questions[currentQuestionIndex].answers != "" ? <div className="d-flex"> <input  className="radio-control"  
                             name={answerOption}  value={answerOption} key={questions[currentQuestionIndex].id+"_"+index}  type="radio"  checked={radiocheck== answerOption} 
                             onChange={(ans)=>props.setRadioAnswers(questions[currentQuestionIndex].id, ans, questions[currentQuestionIndex].question)} /> <label className="label-control">{answerOption}</label></div> : null}

							</>
						))}
					</div>
                     

                {nextQuestion < questions.length ? <button type="button" className="btn btn-primary btn_next" onClick={() => {handleAnswerOptionClick(questions[currentQuestionIndex].id)}}>Next</button>:
                <button type="button" className="btn btn-primary btn_submit" onClick={() => {props.submitAnswer()}}>Submit</button>
                }    
                </div> }
                
                     
				</>
	);

}

export default LaunchPoll 


