// @flow

import React, { Component } from "react";
import axios from "axios";
import {
    ACTION_SHORTCUT_TRIGGERED,
    createShortcutEvent,
    createToolbarEvent,
    sendAnalytics,
} from "../../../analytics";
import { getToolbarButtons } from "../../../base/config";
import { openDialog, toggleDialog } from "../../../base/dialog";
import { isMobileBrowser } from "../../../base/environment/utils";
import { translate } from "../../../base/i18n";
import {
    IconChat,
    IconCodeBlock,
    IconDeviceDocument,
    IconExitFullScreen,
    IconFeedback,
    IconFullScreen,
    IconInviteMore,
    IconPresentation,
    IconRaisedHand,
    IconRec,
    IconShareDesktop,
    IconVote,
    IconPolls,
    IconShareVideo,
    IconParticipants,
    CallEndForAll,
    IconCursor,
    Notepad,
} from "../../../base/icons";
import JitsiMeetJS from "../../../base/lib-jitsi-meet";
import {
    getLocalParticipant,
    getParticipants,
    isLocalParticipantModerator,
    isParticipantModerator,
    participantUpdated,
} from "../../../base/participants";
import { connect, equals } from "../../../base/redux";
import { OverflowMenuItem } from "../../../base/toolbox/components";
import { getLocalVideoTrack, toggleScreensharing } from "../../../base/tracks";
import { isVpaasMeeting } from "../../../billing-counter/functions";
import { ChatCounter, clearMessages, toggleChat } from "../../../chat";
//import { InviteMore } from '../../../conference';
import { EmbedMeetingDialog } from "../../../embed-meeting";
import { SharedDocumentButton } from "../../../etherpad";
import { openFeedbackDialog } from "../../../feedback";
import { openKeyboardShortcutsDialog } from "../../../keyboard-shortcuts";
import { LobbyModeButton } from "../../../lobby";
import { LocalRecordingInfoDialog } from "../../../local-recording";
import { LiveStreamButton, RecordButton } from "../../../recording";
import { SecurityDialogButton } from "../../../security";
import {
    SETTINGS_TABS,
    SettingsButton,
    openSettingsDialog,
} from "../../../settings";
import { SharedVideoButton } from "../../../shared-video/components";
import { toggleSharedVideo } from "../../../shared-video";
import { SpeakerStats } from "../../../speaker-stats";
import { ClosedCaptionButton } from "../../../subtitles";
import {
    TileViewButton,
    shouldDisplayTileView,
    toggleTileView,
} from "../../../video-layout";
import {
    OverflowMenuVideoQualityItem,
    VideoQualityDialog,
} from "../../../video-quality";
//import { VideoBlurButton } from '../../../blur';
//import { VideoBackgroundButton } from '../../../virtual-background';

import { VideoBackgroundButton } from "../../../virtual-background";
import { checkBlurSupport } from "../../../virtual-background/functions";
import {
    setFullScreen,
    setOverflowMenuVisible,
    setToolbarHovered,
    setToolboxVisible,
} from "../../actions";
import { getToolbarAdditionalButtons, isToolboxVisible } from "../../functions";
import DownloadButton from "../DownloadButton";
import HangupButton from "../HangupButton";
import HelpButton from "../HelpButton";
import MuteEveryoneButton from "../MuteEveryoneButton";
import MuteEveryonesVideoButton from "../MuteEveryonesVideoButton";

import AudioSettingsButton from "./AudioSettingsButton";
import OverflowMenuButton from "./OverflowMenuButton";
import OverflowMenuProfileItem from "./OverflowMenuProfileItem";
import ToggleCameraButton from "./ToggleCameraButton";
import ToolbarButton from "./ToolbarButton";
import VideoSettingsButton from "./VideoSettingsButton";
import EndCallOptionsDialog from "./EndCallOptionsDialog";
import {
    openPollDialogMessage,
    stopPoll,
    ToggleVotingPoll,
} from "../../../voting";
import { toggleReduxPoll } from "../../../reduxPoll";
import { openPollDialog, openQuizDialog, stopQuiz } from "../../../reduxPoll";
import VideoMuteButton from "../VideoMuteButton";
import { showScheduleBanner, toggleEndCallBanner } from "../../../callend";
import { openNotepad } from "../../../notepad";
import { toggleNotes } from "../../../NoteP";
import {
    beginAddPeople,
    InfoDialogButton,
    isAddPeopleEnabled,
    isDialOutEnabled,
} from "../../../invite";

import {
    close as closeParticipantsPane,
    open as openParticipantsPane,
} from "../../../participants-pane/actions";
import { getParticipantsPaneOpen } from "../../../participants-pane/functions";
import { isToolbarButtonEnabled } from "../../../base/config/functions.web";
import { setToolboxAlwaysVisible } from "../../actions.native";
import InviteTeamIcon from "@atlaskit/icon/glyph/invite-team";
import UploadIcon from "@atlaskit/icon/glyph/upload";
import "./Toolbox.css";
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    ModalTransition,
} from "@atlaskit/modal-dialog";
import Button, { ButtonGroup } from "@atlaskit/button";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { blue, N500 } from "@atlaskit/theme/colors";

import CopyIcon from "./copy.svg";
import CheckIcon from "./check.svg";
import Icon from "@atlaskit/icon";
import ArrowDownIcon from "@atlaskit/icon/glyph/arrow-down";
import ArrowRightIcon from "@atlaskit/icon/glyph/arrow-right";
import CopyIcon1 from "@atlaskit/icon/glyph/copy";
import EmailIcon from "@atlaskit/icon/glyph/email";
import SendIcon from "@atlaskit/icon/glyph/send";
import ChevronDownCircleIcon from "@atlaskit/icon/glyph/chevron-down-circle";

import StarFilledIcon from "@atlaskit/icon/glyph/star-filled";
import { Field } from "@atlaskit/form";
import Textfield from "@atlaskit/textfield";
import Files from "react-files";
import {
    OverflowMenuScreenshotItem,
    ScreenshotDialog,
} from "../../../screenshot/components";
import { getAPIURL } from "../../../base/conference";
import ChevronUpCircleIcon from "@atlaskit/icon/glyph/chevron-up-circle";

// import Button from '@atlaskit/button';

/**
 * The type of the React {@code Component} props of {@link Toolbox}.
 */
type Props = {
    /**
     * Whether or not the chat feature is currently displayed.
     */
    _chatOpen: boolean,

    /**
     * The width of the client.
     */
    _clientWidth: number,

    /**
     * The {@code JitsiConference} for the current conference.
     */
    _conference: Object,

    /**
     * The tooltip key to use when screensharing is disabled. Or undefined
     * if non to be shown and the button to be hidden.
     */
    _desktopSharingDisabledTooltipKey: boolean,

    /**
     * Whether or not screensharing is initialized.
     */
    _desktopSharingEnabled: boolean,

    /**
     * Whether or not a dialog is displayed.
     */
    _dialog: boolean,

    /**
     * Whether or not call feedback can be sent.
     */
    _feedbackConfigured: boolean,

    /**
     * Whether or not the app is currently in full screen.
     */
    _fullScreen: boolean,

    /**
     * Whether or not the app is running in mobile browser.
     */
    _isMobile: boolean,

    /**
     * Whether or not the profile is disabled.
     */
    _isProfileDisabled: boolean,

    /**
     * Whether or not the tile view is enabled.
     */
    _tileViewEnabled: boolean,

    /**
     * Whether or not the current meeting belongs to a JaaS user.
     */
    _isVpaasMeeting: boolean,

    /**
     * The ID of the local participant.
     */
    _localParticipantID: String,

    /**
     * The subsection of Redux state for local recording
     */
    _localRecState: Object,

    /**
     * The value for how the conference is locked (or undefined if not locked)
     * as defined by room-lock constants.
     */
    _locked: boolean,

    /**
     * Whether or not the overflow menu is visible.
     */
    _overflowMenuVisible: boolean,

    /**
     * Whether or not the local participant's hand is raised.
     */
    _raisedHand: boolean,

    /**
     * Whether or not the local participant is screensharing.
     */
    _screensharing: boolean,

    /**
     * Whether or not the local participant is sharing a YouTube video.
     */
    _sharingVideo: boolean,

    /**
     * Flag showing whether toolbar is visible.
     */
    _visible: boolean,

    /**
     * Array with the buttons which this Toolbox should display.
     */
    _visibleButtons: Set<string>,

    /**
     * Invoked to active other features of the app.
     */
    dispatch: Function,

    /**
     * Invoked to obtain translated strings.
     */
    t: Function,
    _isQuizRunning: boolean,

    _isPollRunning: boolean,
    /**
     * Whether or not the local participant is sharing a YouTube video.
     */
    /**
     * Whether or not the participants pane is open.
     */
    _participantsPaneOpen: boolean,
    /**
     * Handler to check if a button is enabled.
     */
    _shouldShowButton: Function,
    /**
     * Whether Audio Btn Should be visible
     */
    AudioBtnVisiblity: boolean,
    /**
     * Whether Video Btn Should be visible
     */

    VideoBtnVisiblity: boolean,
    /**
     * Whether ScreenShare Btn Should be visible
     */
    ScreenShareBtnVisiblity: boolean,
    _isHost: boolean,
    screenshotPermission: boolean,
    filePermission: boolean,
    _defaultAPIUrl: string,
};

declare var APP: Object;
const visibleButtons = new Set(interfaceConfig.TOOLBAR_BUTTONS);

/**
 * Implements the conference toolbox on React/Web.
 *
 * @extends Component
 */
class Toolbox extends Component<Props> {
    /**
     * Initializes a new {@code Toolbox} instance.
     *
     * @param {Props} props - The read-only React {@code Component} props with
     * which the new instance is to be initialized.
     */
    constructor(props: Props) {
        super(props);

        // Bind event handlers so they are only bound once per instance.
        this._onMouseOut = this._onMouseOut.bind(this);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onSetOverflowVisible = this._onSetOverflowVisible.bind(this);
        this._onTabIn = this._onTabIn.bind(this);

        this._onShortcutToggleChat = this._onShortcutToggleChat.bind(this);
        this._onShortcutToggleFullScreen =
            this._onShortcutToggleFullScreen.bind(this);
        this._onShortcutToggleRaiseHand =
            this._onShortcutToggleRaiseHand.bind(this);
        this._onShortcutToggleScreenshare =
            this._onShortcutToggleScreenshare.bind(this);
        this._onShortcutToggleVideoQuality =
            this._onShortcutToggleVideoQuality.bind(this);
        this._onToolbarOpenFeedback = this._onToolbarOpenFeedback.bind(this);
        this._onToolbarOpenInvite = this._onToolbarOpenInvite.bind(this);
        this._onToolbarOpenKeyboardShortcuts =
            this._onToolbarOpenKeyboardShortcuts.bind(this);
        this._onToolbarOpenSpeakerStats =
            this._onToolbarOpenSpeakerStats.bind(this);
        this._onToolbarOpenEmbedMeeting =
            this._onToolbarOpenEmbedMeeting.bind(this);
        this._onToolbarOpenVideoQuality =
            this._onToolbarOpenVideoQuality.bind(this);
        this._onToolbarToggleChat = this._onToolbarToggleChat.bind(this);
        this._onToolbarToggleFullScreen =
            this._onToolbarToggleFullScreen.bind(this);
        this._onToolbarToggleProfile = this._onToolbarToggleProfile.bind(this);
        this._onToolbarToggleRaiseHand =
            this._onToolbarToggleRaiseHand.bind(this);
        this._onToolbarToggleScreenshare =
            this._onToolbarToggleScreenshare.bind(this);
        this._onToolbarOpenLocalRecordingInfoDialog =
            this._onToolbarOpenLocalRecordingInfoDialog.bind(this);
        this._onShortcutToggleTileView =
            this._onShortcutToggleTileView.bind(this);
        this._onToolbarVoting = this._onToolbarVoting.bind(this);
        this._onToolbarReduxPoll = this._onToolbarReduxPoll.bind(this);
        this._onToolbarCallEnd = this._onToolbarCallEnd.bind(this);
        this._onToolbarNotepad = this._onToolbarNotepad.bind(this);
        this._onToolbarToggleNotes = this._onToolbarToggleNotes.bind(this);
        this._stopQuiz = this._stopQuiz.bind(this);
        this._onToolbarToggleSharedVideo =
            this._onToolbarToggleSharedVideo.bind(this);
        this._onToolbarToggleParticipantsPane =
            this._onToolbarToggleParticipantsPane.bind(this);
        this._onToolbarShowCollabs = this._onToolbarShowCollabs.bind(this);
        this._onToolbarOpenScreenshot =
            this._onToolbarOpenScreenshot.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.state = {
            windowWidth: window.innerWidth,
            quizIds: [],
            isQuiz: false,
            _isQuizInfoReceived: false,
            lastCheck: 0,
            pollid: "",
            isPoll: false,
            count: "",
            cursorShared: false,
            lastCheckForPoll: 0,
            isStatusReceived: false,
            isFeatureRequesting: false,
            inviteModalOpen: false,
            iconType: CopyIcon,
            shareToogle: false,
            embeddToggle: false,
            confirmModalOpen: false,
            sendMailDetails: false,
            senderEmailAddress: "",
            senderName: "",
            joinLink: "",
            recipients: [],
            uploadModalOpen: false,
            documents: [],
            isUploading: false,
            deletingDocIds: [],
            maxFileSizeError: "",
            uploadPopupOpen: false,
            popUpHeight: true,
        };
    }

    /**
     * Sets keyboard shortcuts for to trigger ToolbarButtons actions.
     *
     * @inheritdoc
     * @returns {void}
     */
    componentDidMount() {
        /**
         * Force toolbar to remain visible
         */
       // dispatch(setToolboxAlwaysVisible(true));

        const KEYBOARD_SHORTCUTS = [
            this._shouldShowButton("videoquality") && {
                character: "A",
                exec: this._onShortcutToggleVideoQuality,
                helpDescription: "toolbar.callQuality",
            },
            this._shouldShowButton("chat") && {
                character: "C",
                exec: this._onShortcutToggleChat,
                helpDescription: "keyboardShortcuts.toggleChat",
            },
            this._shouldShowButton("desktop") && {
                character: "D",
                exec: this._onShortcutToggleScreenshare,
                helpDescription: "keyboardShortcuts.toggleScreensharing",
            },
            this._shouldShowButton("participants-pane") && {
                character: "P",
                exec: this._onShortcutToggleParticipantsPane,
                helpDescription: "keyboardShortcuts.toggleParticipantsPane",
            },
            this._shouldShowButton("raisehand") && {
                character: "R",
                exec: this._onShortcutToggleRaiseHand,
                helpDescription: "keyboardShortcuts.raiseHand",
            },
            this._shouldShowButton("fullscreen") && {
                character: "S",
                exec: this._onShortcutToggleFullScreen,
                helpDescription: "keyboardShortcuts.fullScreen",
            },
            this._shouldShowButton("tileview") && {
                character: "W",
                exec: this._onShortcutToggleTileView,
                helpDescription: "toolbar.tileViewToggle",
            },
        ];

        KEYBOARD_SHORTCUTS.forEach((shortcut) => {
            if (typeof shortcut === "object") {
                APP.keyboardshortcut.registerShortcut(
                    shortcut.character,
                    null,
                    shortcut.exec,
                    shortcut.helpDescription
                );
            }
        });
    }

    /**
     * Update the visibility of the {@code OverflowMenuButton}.
     *
     * @inheritdoc
     */
    componentDidMount() { }

    componentDidUpdate(prevProps) {
        // Ensure the dialog is closed when the toolbox becomes hidden.
        if (prevProps._overflowMenuVisible && !this.props._visible) {
            this._onSetOverflowVisible(false);
        }
        {
            this._shouldShowButton("endcallforall") &&
                APP.conference.participantCount == 1 && (
                    <HangupButton
                        customClass="hangup-button"
                        visible={this._shouldShowButton("hangup")}
                    />
                );
        }

        {
            !this._shouldShowButton("endcallforall") && (
                <HangupButton
                    customClass="hangup-button"
                    visible={this._shouldShowButton("hangup")}
                />
            );
        }

        if (
            prevProps._overflowMenuVisible &&
            !prevProps._dialog &&
            this.props._dialog
        ) {
            this._onSetOverflowVisible(false);
            this.props.dispatch(setToolbarHovered(false));
        }

        if (
            (!this.state._isQuizInfoReceived ||
                typeof this.state._isQuizInfoReceived == "undefined") &&
            this.isUpdateRequired()
        ) {
            this.setState({ lastCheck: new Date().getTime() });
            this.getQuizInformation();
        }

        if (
            !this.state.isPoll &&
            typeof APP.conference.roomName != "undefined" &&
            this.isUpdateRequiredForPoll()
        ) {
            this.setState({ lastCheckForPoll: new Date().getTime() });
            this.loadPollData();
        }
    }

    loadPollData() {
        var apiDomain = this.props._defaultAPIUrl;
        fetch(apiDomain + "/poll/getPollData/" + APP.conference.roomName)
            .then((res) => res.json())
            .then((result) => {
                if (result.pollData != null) {
                    this.setState({ pollid: result.pollData.id });
                    fetch(
                        apiDomain +
                        "/poll/getPollQuestionFullData/" +
                        this.state.pollid
                    )
                        .then((res) => res.json())
                        .then((result) => {
                            if (result.pollQuestionAns != null) {
                                this.setState({ isPoll: true });
                            }
                        });
                }
            });
    }

    isUpdateRequired() {
        const diff = new Date().getTime() - this.state.lastCheck;
        const isRequied = diff > 30 * 1000;
        return isRequied;
    }

    isUpdateRequiredForPoll() {
        const diff = new Date().getTime() - this.state.lastCheckForPoll;
        const isRequied = diff > 30 * 1000;
        return isRequied;
    }
    /**
     * Removes keyboard shortcuts registered by this component.
     *
     * @inheritdoc
     * @returns {void}
     */
    componentWillUnmount() {
        ["A", "C", "D", "R", "S"].forEach((letter) =>
            APP.keyboardshortcut.unregisterShortcut(letter)
        );
    }

    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */

    handleHover = () => {
        let btn = document.getElementById("copy-button");
        let icon = document.getElementById("copy-icon");
        btn.style.background = "#1677ba";
        icon.style.background = "#1677ba";
        btn.innerText = "Copy meeting link";
    };

    handleMouseOut = (meetingName) => {
        let btn = document.getElementById("copy-button");
        let icon = document.getElementById("copy-icon");
        btn.style.background = "#0052CC";
        icon.style.background = "#0052CC";
        btn.innerText = `https://meet.dialtm.in/${meetingName}`;
    };

    onClickHandler = (meetingName) => {
        let btn = document.getElementById("copy-button");
        let icon = document.getElementById("copy-icon");
        let copyingText = `https://meet.dialtm.in/${meetingName}`;
        var input = document.createElement("input");
        input.setAttribute("value", copyingText);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand("copy");
        document.body.removeChild(input);

        btn.style.background = "green";
        icon.style.background = "green";
        btn.innerText = "Link copied to clipboard";
        this.setState({
            iconType: CheckIcon,
        });
        setTimeout(() => {
            this.setState({
                iconType: CopyIcon,
            });
            btn.innerText = `https://meet.dialtm.in/${this.state.meetingRoom}`;
            btn.style.background = "#0052CC";
            icon.style.background = "#0052CC";
        }, 1000);
    };

    copyHandler = (meetingName) => {
        let copyingText = `https://meet.dialtm.in/${meetingName}`;
        var input = document.createElement("input");
        input.setAttribute("value", copyingText);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand("copy");
        document.body.removeChild(input);
    };

    emailOpenHandler = (e) => {
        const meetingName = window.location.pathname.substr(
            1,
            window.location.pathname.length
        );
        this.setState({
            confirmModalOpen: true,
            joinLink: `https://meet.dialtm.in/${meetingName}`,
        });
        // window.location = "mailto:no-reply@example.com";
        // e.preventDefault();
    };

    gmailOpenHandler = (e, meetingName) => {
        const body = `You are invited to join a meeting.
        Join the meeting:
        https://meet.jit.si/${meetingName}
        
        One tap audio Dial In: +1.512.647.1431,,836137450#
        
        Looking for a different dial-in number?
        See meeting dial-in numbers: https://meet.jit.si/static/dialInInfo.html?room=${meetingName}
        
        
        If also dialing-in through a room phone, join without connecting to audio: https://meet.jit.si/${meetingName}#config.startSilent=true`;

        const sub = `Join Jitsi Meet meeting`;
        const url = `https://mail.google.com/mail/?view=cm&fs=1&su=${sub}&body=${body}`;
        window.open(url, "_blank");
        e.preventDefault();
    };

    outlookOpenHandler = (e, meetingName) => {
        let url = `https://outlook.office.com/mail/deeplink/compose?subject=Join%20Jitsi%20Meet%20meeting&body=You%20are%20invited%20to%20join%20a%20meeting.%0AJoin%20the%20meeting%3A%0Ahttps%3A%2F%2Fmeet.jit.si%2FAcidEasesEscapeSomewhat%0A%0AOne%20tap%20audio%20Dial%20In%3A%20%2B1.512.647.1431%2C%2C836137450%23%0A%0ALooking%20for%20a%20different%20dial-in%20number%3F%0ASee%20meeting%20dial-in%20numbers%3A%20https%3A%2F%2Fmeet.jit.si%2Fstatic%2FdialInInfo.html%3Froom%3DAcidEasesEscapeSomewhat%0A%0A%0AIf%20also%20dialing-in%20through%20a%20room%20phone%2C%20join%20without%20connecting%20to%20audio%3A%20https%3A%2F%2Fmeet.jit.si%2FAcidEasesEscapeSomewhat%23config.startSilent%3Dtrue`;
        window.open(url, "_blank");
        e.preventDefault();
    };

    yahooOpenHandler = (e, meetingName) => {
        const body = `
        You are invited to join a meeting.
        Join the meeting:
        https://meet.jit.si/${meetingName}
        
        One tap audio Dial In: +1.512.647.1431,,836137450#
        
        Looking for a different dial-in number?
        See meeting dial-in numbers: https://meet.jit.si/static/dialInInfo.html?room=${meetingName}
        
        
        If also dialing-in through a room phone, join without connecting to audio: https://meet.jit.si/${meetingName}#config.startSilent=true`;

        const sub = `Join Jitsi Meet meeting`;
        const url = `http://compose.mail.yahoo.com/?subject=${sub}&body=${body}`;
        window.open(url, "_blank");
        e.preventDefault();
    };

    embeddCopyHandler = () => {
        const meetingName = window.location.pathname.substr(
            1,
            window.location.pathname.length
        );
        let src = `https://meet.jit.si/${meetingName}`;
        const copyingText = `<iframe allow="camera; microphone; fullscreen; display-capture; autoplay" src=${src} style="height: 100%; width: 100%; border: 0px;"></iframe>`;
        var input = document.createElement("input");
        input.setAttribute("value", copyingText);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand("copy");
        document.body.removeChild(input);
    };

    phoneCopyHandler = () => {
        const meetingName = window.location.pathname.substr(
            1,
            window.location.pathname.length
        );
        let src = `https://meet.jit.si/${meetingName}`;
        const copyingText = `Dial-in: +1.512.647.1431 PIN: 1594246972#`;
        var input = document.createElement("input");
        input.setAttribute("value", copyingText);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand("copy");
        document.body.removeChild(input);
    };

    moreHandler = (e, meetngName) => {
        e.preventDefault();
        window.open(
            `https://meet.jit.si/static/dialInInfo.html?room=${meetngName}`,
            "_blank"
        );
    };

    emailHanlder = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    };

    emailSendHandler = () => {
        let valErr = false;

        const { senderEmailAddress, senderName, joinLink, recipients } =
            this.state;
        if (
            !senderEmailAddress.length ||
            !senderName.length ||
            !joinLink.length ||
            !recipients.length
        ) {
            valErr = true;
            this.setState({
                emailValErr: true,
            });
        }

        var apiDomain = this.props._defaultAPIUrl;
        if (!valErr) {
            axios({
                url: apiDomain + "/inviteUsers/inCall",
                method: "POST",
                data: {
                    senderEmailAddress,
                    senderName,
                    joinLink,
                    recipients: recipients.split(","),
                },
            });
        }
    };

    cancelSend = () => {
        this.setState({
            sendMailDetails: false,
            inviteModalOpen: false,
            confirmModalOpen: false,
        });
    };

    onFilesChange = (e) => {
        var files = e.target.files;
        console.log(files);
        var filesArr = Array.prototype.slice.call(files);
        console.log(filesArr);
        let maxFileSize = 25 * 1024 * 1024;
        for (let i = 0; i < filesArr.length; i++) {
            if (filesArr[i].size > maxFileSize) {
                this.setState({
                    maxFileSizeError: "Please Select a file smaller than 25MB",
                });
                setTimeout(() => {
                    this.setState({
                        maxFileSizeError: "",
                    });
                }, 4000);
                return;
            }
        }
        this.setState({ documents: [...this.state.documents, ...filesArr] });
    };

    onFilesError = (error, file) => {
        console.log("error code " + error.code + ": " + error.message);
    };

    docDeleteHandler = (f) => {
        this.setState({
            documents: this.state.documents.filter((x) => x !== f),
        });
    };

    uploadFiles() {
        // debugger;
        this.setState({
            isUploading: true,
            uploadModalOpen: false,
            uploadPopupOpen: true,
        });
        const formData = new FormData();
        let docs = this.state.documents;
        const files = [];
        for (let i = 0; i < docs.length; i++) {
            formData.append("files", docs[i]);
        }
        // console.log("files", files)
        var apiDomain = this.props._defaultAPIUrl;
        const UPLOAD_ENDPOINT =
            apiDomain + `/snapshot/uploadSnapFiles/${APP.conference.roomName}`;
        console.log("APP.conference.roomName", APP.conference.roomName);
        axios
            .post(UPLOAD_ENDPOINT, formData, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((response) => {
                console.log(response.data);
                const saveDocsPayload = {
                    userRefId: this.props._localParticipantID,
                    sessionRefId: "NA",
                    roomRefId: APP.conference.roomName,
                    capturerUserRefId: this.props._localParticipantID,
                    filePaths: response.data.data,
                };

                var apiDomain = this.props._defaultAPIUrl;
                axios
                    .post(
                        apiDomain + "/snapshot/saveSnapshotData",
                        saveDocsPayload,
                        {
                            headers: {
                                "content-type": "application/json",
                            },
                        }
                    )
                    .then((response) => {
                        this.setState({
                            uploadModalOpen: false,
                            isUploading: false,
                        });
                        setTimeout(() => {
                            this.setState({
                                uploadPopupOpen: false,
                            });
                        }, 1500);
                    });
            });
    }

    render() {
        const { _chatOpen, _visible, _visibleButtons, _isMobile } = this.props;
        const rootClassNames = `new-toolbox ${_visible ? "visible" : ""} ${_visibleButtons.size ? "visible" : ""
            } ${_chatOpen ? "shift-right" : ""} ${_isMobile ? "mobile" : ""}`;

        // {        // if(this.props._participantsPaneOpen)

        //     var width=$(".participants_pane-content").width();
        //     $("#remoteVideos").css({
        //         'right':width
        //     });
        // }
        // else
        // {
        //     $("#remoteVideos").css({
        //         'right':0
        //     });
        // }

        // console.log(this.state.documents)

        const meetingName = window.location.pathname.substr(
            1,
            window.location.pathname.length
        );
        let src = `https://meet.jit.si/${meetingName}`;

        const b = `<iframe allow="camera; microphone; fullscreen; display-capture; autoplay" src=${src} style="height: 100%; width: 100%; border: 0px;"></iframe>`;

        return (
            <div
                className={rootClassNames}
                id="new-toolbox"
                onFocus={this._onTabIn}
                onMouseOut={this._onMouseOut}
                onMouseOver={this._onMouseOver}
            >
                {this._renderToolboxContent()}
                <ModalTransition>
                    {this.state.inviteModalOpen &&
                        !this.state.confirmModalOpen && (
                            <Modal
                                onClose={() =>
                                    this.setState({
                                        inviteModalOpen: false,
                                    })
                                }
                                width="small"
                                className="xyz"
                                shouldCloseOnOverlayClick={false}
                            >
                                <ModalHeader>
                                    <ModalTitle>Invite more people</ModalTitle>
                                    <Button
                                        appearance="link"
                                        onClick={() =>
                                            this.setState({
                                                inviteModalOpen: false,
                                            })
                                        }
                                    >
                                        <CrossIcon
                                            label="Close Modal"
                                            primaryColor={N500}
                                        />
                                    </Button>
                                </ModalHeader>
                                <ModalBody>
                                    <div className="copy-container">
                                        <p
                                            style={{
                                                fontSize: 15,
                                                marginBottom: 2,
                                            }}
                                        >
                                            Share the meeting link to invite
                                            others
                                        </p>
                                        <div
                                            className="w-100 copy-button-container"
                                            onMouseOver={this.handleHover}
                                            onMouseOut={() =>
                                                this.handleMouseOut(meetingName)
                                            }
                                            onClick={() =>
                                                this.onClickHandler(meetingName)
                                            }
                                        >
                                            <ButtonGroup
                                                className="w-100 btn-grp"
                                                style={{
                                                    width: "100%",
                                                    color: "#fff",
                                                }}
                                            >
                                                <Button
                                                    shouldFitContainer={true}
                                                    appearance="primary"
                                                    className="mt-5 w-80"
                                                    id="copy-button"
                                                >
                                                    <span className="abcdd">
                                                        meet.jit.si/
                                                        {meetingName}
                                                    </span>
                                                </Button>
                                                <Button
                                                    shouldFitContainer={true}
                                                    appearance="primary"
                                                    className="w-20"
                                                    id="copy-icon"
                                                >
                                                    <Icon
                                                        glyph={
                                                            this.state.iconType
                                                        }
                                                        size="small"
                                                    />
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                    <div className="share_container">
                                        <div className="w-100 share-button-container">
                                            <ButtonGroup
                                                className="w-100 btn-grp"
                                                style={{
                                                    width: "100%",
                                                    color: "#fff",
                                                }}
                                            >
                                                <Button
                                                    shouldFitContainer={true}
                                                    id="share-button"
                                                    onClick={() =>
                                                        this.setState({
                                                            shareToogle:
                                                                !this.state
                                                                    .shareToogle,
                                                        })
                                                    }
                                                >
                                                    <span className="abcdd">
                                                        Share meeting invitation
                                                    </span>
                                                </Button>
                                                <Button
                                                    shouldFitContainer={true}
                                                    className="w-20"
                                                    id="share-icon"
                                                    onClick={() =>
                                                        this.setState({
                                                            shareToogle:
                                                                !this.state
                                                                    .shareToogle,
                                                        })
                                                    }
                                                >
                                                    {this.state.shareToogle ? (
                                                        <ArrowDownIcon size="small" />
                                                    ) : (
                                                        <ArrowRightIcon size="small" />
                                                    )}
                                                </Button>
                                            </ButtonGroup>
                                            {this.state.shareToogle ? (
                                                <div className="mail_container">
                                                    <Button
                                                        style={{ padding: 4 }}
                                                        onClick={() =>
                                                            this.copyHandler(
                                                                meetingName
                                                            )
                                                        }
                                                    >
                                                        {/* <CopyIcon1 size="medium" /> */}
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                fill="#AB7C94"
                                                                d="M4 4C4 2.89543 4.89543 2 6 2H14C15.1046 2 16 2.89543 16 4H6V18C4.89543 18 4 17.1046 4 16V4ZM10 8V20H18V8H10ZM10 6H18C19.1046 6 20 6.89543 20 8V20C20 21.1046 19.1046 22 18 22H10C8.89543 22 8 21.1046 8 20V8C8 6.89543 8.89543 6 10 6Z"
                                                            />
                                                        </svg>
                                                    </Button>
                                                    <Button
                                                        style={{ padding: 4 }}
                                                        onClick={(e) =>
                                                            this.emailOpenHandler(
                                                                e
                                                            )
                                                        }
                                                    >
                                                        {" "}
                                                        <EmailIcon size="medium" />
                                                    </Button>
                                                    <Button
                                                        style={{ padding: 4 }}
                                                        onClick={(e) =>
                                                            this.gmailOpenHandler(
                                                                e,
                                                                meetingName
                                                            )
                                                        }
                                                    >
                                                        {/* <SendIcon size="medium" /> */}
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                fill="#AB7C94"
                                                                d="M20.2359 10.3544H19.56V10.32H12V13.68H16.7479C16.054 15.6356 14.1935 17.04 12 17.04C9.21583 17.04 6.95998 14.7841 6.95998 12C6.95998 9.21583 9.21583 6.95998 12 6.95998C13.2846 6.95998 14.4543 7.44396 15.3436 8.23638L17.7192 5.86076C16.2197 4.46294 14.2132 3.59998 12 3.59998C7.36029 3.59998 3.59998 7.36029 3.59998 12C3.59998 16.6397 7.36029 20.4 12 20.4C16.6397 20.4 20.4 16.6397 20.4 12C20.4 11.4372 20.3426 10.8876 20.2359 10.3544Z"
                                                                fill="#A4B8D1"
                                                            />
                                                        </svg>
                                                    </Button>
                                                    <Button
                                                        style={{ padding: 4 }}
                                                        onClick={(e) =>
                                                            this.outlookOpenHandler(
                                                                e,
                                                                meetingName
                                                            )
                                                        }
                                                    >
                                                        {/* <SendIcon size="medium" /> */}
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                fill="#AB7C94"
                                                                d="M3 6L14.0138 2L20.0213 3.5V20.5L14.0138 22L3 18L14.0138 19.5V5L7.00501 6.5V16.5L3 18V6Z"
                                                                fill="#A4B8D1"
                                                            />
                                                        </svg>
                                                    </Button>
                                                    <Button
                                                        style={{ padding: 4 }}
                                                        onClick={(e) =>
                                                            this.yahooOpenHandler(
                                                                e,
                                                                meetingName
                                                            )
                                                        }
                                                    >
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill="#AB7C94"
                                                                d="M4 3H9.10397L12.076 10.6035L15.0865 3H20.0561L12.573 21H7.57197L9.62033 16.2303L4 3Z"
                                                                fill="#A4B8D1"
                                                            />
                                                        </svg>

                                                        {/* <SendIcon size="medium" /> */}
                                                    </Button>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {/* <div className="embedd_container" onClick={() => {
                                    this.setState({
                                        embeddToggle: !this.state.embeddToggle,
                                        inviteModalOpen: false
                                    })
                                }}>
                                    <Button
                                        shouldFitContainer={true}
                                    >
                                        <span className="abcdd">Embedd this meeting</span>
                                    </Button>
                                </div> */}
                                </ModalBody>

                                <ModalFooter>
                                    <div className="footer_container">
                                        <div className="footer_up">
                                            <p>
                                                <span>Dial-in: </span>
                                                +1.512.647.1431
                                            </p>
                                            <p>
                                                <span>PIN: </span>1594246972#
                                            </p>
                                            <span
                                                style={{ cursor: "pointer" }}
                                                onClick={this.phoneCopyHandler}
                                            >
                                                <CopyIcon1 size="medium" />
                                            </span>
                                        </div>
                                        <div className="footer_down">
                                            <p
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) =>
                                                    this.moreHandler(
                                                        e,
                                                        meetingName
                                                    )
                                                }
                                            >
                                                More numbers
                                            </p>
                                        </div>
                                    </div>
                                </ModalFooter>
                            </Modal>
                        )}
                </ModalTransition>

                <ModalTransition>
                    {this.state.embeddToggle && (
                        <Modal
                            onClose={() =>
                                this.setState({
                                    embeddToggle: false,
                                })
                            }
                            width="small"
                            shouldCloseOnOverlayClick={false}
                        >
                            <ModalHeader>
                                <ModalTitle>Embedd this meeting</ModalTitle>
                                <Button
                                    appearance="link"
                                    onClick={() =>
                                        this.setState({
                                            embeddToggle: false,
                                        })
                                    }
                                >
                                    <CrossIcon
                                        label="Close Modal"
                                        primaryColor={N500}
                                    />
                                </Button>
                            </ModalHeader>
                            <ModalBody>
                                <textarea
                                    className="embedd_field"
                                    value={b}
                                    rows="8"
                                    readOnly
                                ></textarea>
                            </ModalBody>
                            <ModalFooter>
                                <div className="embedd_footer">
                                    <Button
                                        iconAfter={
                                            <CopyIcon1
                                                label="Copy"
                                                size="medium"
                                            />
                                        }
                                        appearance="primary"
                                        className="embedd_copy"
                                        onClick={this.embeddCopyHandler}
                                    >
                                        Copy
                                    </Button>
                                </div>
                            </ModalFooter>
                        </Modal>
                    )}
                </ModalTransition>

                <ModalTransition>
                    {this.state.confirmModalOpen && (
                        <Modal
                            onClose={() =>
                                this.setState({
                                    confirmModalOpen: false,
                                })
                            }
                            shouldCloseOnOverlayClick={false}
                        >
                            {this.state.sendMailDetails ? (
                                <React.Fragment>
                                    <ModalHeader>
                                        <ModalTitle>
                                            Please give email details
                                        </ModalTitle>
                                        <Button
                                            appearance="link"
                                            onClick={() =>
                                                this.setState({
                                                    confirmModalOpen: false,
                                                    sendMailDetails: false,
                                                    inviteModalOpen: false,
                                                })
                                            }
                                        >
                                            <CrossIcon
                                                label="Close Modal"
                                                primaryColor={N500}
                                            />
                                        </Button>
                                    </ModalHeader>
                                    <ModalBody>
                                        <Field
                                            label="Email"
                                            name="senderEmailAddress"
                                            defaultValue=""
                                        >
                                            {({ fieldProps }) => (
                                                <Textfield
                                                    autoComplete="off"
                                                    {...fieldProps}
                                                    value={
                                                        this.state
                                                            .senderEmailAddress
                                                    }
                                                    onChange={(e) =>
                                                        this.emailHanlder(e)
                                                    }
                                                />
                                            )}
                                        </Field>
                                        <Field
                                            label="Name"
                                            name="senderName"
                                            defaultValue=""
                                        >
                                            {({ fieldProps }) => (
                                                <Textfield
                                                    autoComplete="off"
                                                    {...fieldProps}
                                                    onChange={(e) =>
                                                        this.emailHanlder(e)
                                                    }
                                                    value={
                                                        this.state.senderName
                                                    }
                                                />
                                            )}
                                        </Field>
                                        <Field
                                            label="Name"
                                            name="joinLink"
                                            defaultValue={this.state.joinLink}
                                        >
                                            {({ fieldProps }) => (
                                                <Textfield
                                                    autoComplete="off"
                                                    value={this.state.joinLink}
                                                    {...fieldProps}
                                                    isDisabled
                                                />
                                            )}
                                        </Field>
                                        <Field
                                            label="Recipients"
                                            name="recipients"
                                            defaultValue=""
                                        >
                                            {({ fieldProps }) => (
                                                <React.Fragment>
                                                    <Textfield
                                                        autoComplete="off"
                                                        {...fieldProps}
                                                        onChange={(e) =>
                                                            this.emailHanlder(e)
                                                        }
                                                        value={
                                                            this.state
                                                                .recipients
                                                        }
                                                    />
                                                    <small>
                                                        Please give recepients
                                                        name with comma(,)
                                                    </small>
                                                </React.Fragment>
                                            )}
                                        </Field>
                                        {this.state.emailValErr ? (
                                            <p>Please fill all the details!</p>
                                        ) : null}
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            appearance="subtle"
                                            onClick={() =>
                                                this.setState({
                                                    confirmModalOpen: false,
                                                    sendMailDetails: false,
                                                    inviteModalOpen: false,
                                                })
                                            }
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            appearance="primary"
                                            onClick={this.emailSendHandler}
                                        >
                                            Send
                                        </Button>
                                    </ModalFooter>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <ModalHeader>
                                        <ModalTitle>
                                            Would you like to send email from
                                            our domain?
                                        </ModalTitle>
                                        <Button
                                            appearance="link"
                                            onClick={() =>
                                                this.setState({
                                                    confirmModalOpen: false,
                                                    sendMailDetails: false,
                                                    inviteModalOpen: false,
                                                })
                                            }
                                        >
                                            <CrossIcon
                                                label="Close Modal"
                                                primaryColor={N500}
                                            />
                                        </Button>
                                    </ModalHeader>
                                    <ModalFooter>
                                        <Button
                                            appearance="subtle"
                                            onClick={this.cancelSend}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            appearance="primary"
                                            onClick={() =>
                                                this.setState({
                                                    sendMailDetails: true,
                                                    inviteModalOpen: false,
                                                })
                                            }
                                        >
                                            Yes
                                        </Button>
                                    </ModalFooter>
                                </React.Fragment>
                            )}
                        </Modal>
                    )}
                </ModalTransition>

                {this.state.uploadModalOpen && (
                    <ModalTransition>
                        {
                            <Modal
                                onClose={() =>
                                    this.setState({
                                        uploadModalOpen: false,
                                    })
                                }
                                width="small"
                                className="xyz"
                                shouldCloseOnOverlayClick={false}
                            >
                                <ModalHeader>
                                    <ModalTitle>Upload Documents</ModalTitle>
                                    <Button
                                        appearance="link"
                                        onClick={() =>
                                            this.setState({
                                                uploadModalOpen: false,
                                            })
                                        }
                                    >
                                        <CrossIcon
                                            label="Close Modal"
                                            primaryColor={N500}
                                        />
                                    </Button>
                                </ModalHeader>
                                <ModalBody>
                                    <React.Fragment>
                                        <div className="files">
                                            <label className="custom-file-upload">
                                                <input
                                                    type="file"
                                                    onChange={
                                                        this.onFilesChange
                                                    }
                                                    multiple
                                                />
                                                Click to select files
                                            </label>
                                        </div>
                                        <div>
                                            {this.state.documents.length > 0 ? (
                                                <h6 className="up_label">
                                                    Uploaded documents
                                                </h6>
                                            ) : null}
                                            {this.state.documents.map(
                                                (doc, i) => (
                                                    <div className="document_container">
                                                        <h5 title={doc.name}>
                                                            {doc.name.slice(
                                                                0,
                                                                4
                                                            )}{" "}
                                                            {doc.name.length > 4
                                                                ? "..."
                                                                : null}
                                                        </h5>
                                                        <p
                                                            onClick={() =>
                                                                this.docDeleteHandler(
                                                                    doc
                                                                )
                                                            }
                                                        >
                                                            ✕
                                                        </p>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </React.Fragment>
                                    <p style={{ color: "red", marginTop: 5 }}>
                                        {this.state.maxFileSizeError}
                                    </p>
                                </ModalBody>

                                <ModalFooter>
                                    {this.state.documents.length > 0 ? (
                                        <Button onClick={this.uploadFiles}>
                                            Submit
                                        </Button>
                                    ) : null}
                                </ModalFooter>
                            </Modal>
                        }
                    </ModalTransition>
                )}

                {this.state.uploadPopupOpen ? (
                    <div className="doc_up_popup">
                        <div className="doc_up_header">
                            <h4>
                                Uploading {this.state.documents.length} items
                            </h4>
                            <div className="doc_up_header_icons">
                                <div
                                    onClick={() =>
                                        this.setState({
                                            popUpHeight:
                                                !this.state.popUpHeight,
                                        })
                                    }
                                >
                                    {this.state.popUpHeight ? (
                                        <ChevronDownCircleIcon />
                                    ) : (
                                        <ChevronUpCircleIcon />
                                    )}
                                </div>
                                <div
                                    onClick={() =>
                                        this.setState({
                                            uploadPopupOpen: false,
                                        })
                                    }
                                >
                                    <CrossIcon />
                                </div>
                            </div>
                        </div>
                        <div
                            className="doc_up_body"
                            style={{
                                height: this.state.popUpHeight ? "auto" : 0,
                                display: this.state.popUpHeight
                                    ? "block"
                                    : "none",
                            }}
                        >
                            {this.state.isUploading ? (
                                <>
                                    {this.state.documents.map((doc, i) => (
                                        <div className="doc_container">
                                            <h5 title={doc.name}>
                                                {doc.name.slice(0, 4)}{" "}
                                                {doc.name.length > 4
                                                    ? "..."
                                                    : null}
                                            </h5>
                                            <img
                                                src="https://flevix.com/wp-content/uploads/2019/07/Untitled-2.gif"
                                                width="75"
                                            />
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <h3
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    Uploaded
                                </h3>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }

    /**
     * Closes the overflow menu if opened.
     *
     * @private
     * @returns {void}
     */
    _closeOverflowMenuIfOpen() {
        const { dispatch, _overflowMenuVisible } = this.props;

        _overflowMenuVisible && dispatch(setOverflowMenuVisible(false));
    }

    /**
     * Callback invoked to display {@code FeedbackDialog}.
     *
     * @private
     * @returns {void}
     */
    _doOpenFeedback() {
        const { _conference } = this.props;

        this.props.dispatch(openFeedbackDialog(_conference));
    }

    /**
     * Callback invoked to display {@code FeedbackDialog}.
     *
     * @private
     * @returns {void}
     */
    _doOpenEmbedMeeting() {
        this.props.dispatch(openDialog(EmbedMeetingDialog));
    }

    /**
     * Dispatches an action to display {@code KeyboardShortcuts}.
     *
     * @private
     * @returns {void}
     */
    _doOpenKeyboardShorcuts() {
        this.props.dispatch(openKeyboardShortcutsDialog());
    }

    /**
     * Callback invoked to display {@code SpeakerStats}.
     *
     * @private
     * @returns {void}
     */
    _doOpenSpeakerStats() {
        this.props.dispatch(
            openDialog(SpeakerStats, {
                conference: this.props._conference,
            })
        );
    }

    /**
     * Dispatches an action to open the video quality dialog.
     *
     * @private
     * @returns {void}
     */
    _doOpenVideoQuality() {
        this.props.dispatch(openDialog(VideoQualityDialog));
    }

    /**
     *  show screenshot capture dialog
     */

    _doOpenScreenshot() {
        this.props.dispatch(openDialog(ScreenshotDialog));
    }
    /**
     * Dispatches an action to toggle the display of chat.
     *
     * @private
     * @returns {void}
     */
    _doToggleChat() {
        this.props.dispatch(toggleChat());
    }

    _doToggleNotes() {
        this.props.dispatch(toggleNotes());
    }
    _doToggleReduxPoll() {
        this.props.dispatch(toggleReduxPoll());
        this.props.dispatch(openQuizDialog());
    }

    /**
     * Dispatches an action to toggle YouTube video sharing.
     *
     * @private
     * @returns {void}
     */
    _doToggleSharedVideo() {
        this.props.dispatch(toggleSharedVideo());
    }

    _onToolbarToggleNotes: () => void;
    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * the display of chat.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleNotes() {
        sendAnalytics(
            createToolbarEvent("toggle.notes", {
                enable: !this.props._NoteOpen,
            })
        );
        this._doToggleNotes();
    }

    _stopQuiz: () => void;
    _stopQuiz() {
        this.props.dispatch(stopQuiz());
    }

    _onToolbarReduxPoll: () => void;
    _onToolbarReduxPoll() {
        if (!this.props._isQuizRunning) {
            sendAnalytics(
                createToolbarEvent("toggle.poll", {
                    enable: !this.props._showPoll,
                })
            );
            this._doToggleReduxPoll();
            APP.conference.ShowPollForAll();
        } else {
            this._stopQuiz();
        }
    }

    //---------------------call end for all -------
    _onToolbarCallEnd: () => void;
    _onToolbarCallEnd() {
        this._doToggleCallEnd();
    }

    // ---------------- Notepad code here --------
    _onToolbarNotepad: () => void;
    _onToolbarNotepad() {
        this._doToggleNotepad();
    }

    /**
     * Dispatches an action to toggle screensharing.
     *
     * @private
     * @returns {void}
     */
    _doToggleFullScreen() {
        const fullScreen = !this.props._fullScreen;

        this.props.dispatch(setFullScreen(fullScreen));
    }

    /**
     * Dispatches an action to show or hide the profile edit panel.
     *
     * @private
     * @returns {void}
     */
    _doToggleProfile() {
        this.props.dispatch(openSettingsDialog(SETTINGS_TABS.PROFILE));
    }
    // ------ poll message dialog  ---
    _doToggleVoting() {
        this.props.dispatch(openPollDialogMessage());
        this.props.dispatch(ToggleVotingPoll());
    }

    _onShortcutToggleParticipantsPane: () => void;

    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling the display of the participants pane.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleParticipantsPane() {
        sendAnalytics(
            createShortcutEvent("toggle.participants-pane", {
                enable: !this.props._participantsPaneOpen,
            })
        );

        this._onToolbarToggleParticipantsPane();
    }

    /**
     * Dispatches an action to toggle the local participant's raised hand state.
     *
     * @private
     * @returns {void}
     */
    _doToggleRaiseHand() {
        const { _localParticipantID, _raisedHand } = this.props;
        const newRaisedStatus = !_raisedHand;

        this.props.dispatch(
            participantUpdated({
                // XXX Only the local participant is allowed to update without
                // stating the JitsiConference instance (i.e. participant property
                // `conference` for a remote participant) because the local
                // participant is uniquely identified by the very fact that there is
                // only one local participant.

                id: _localParticipantID,
                local: true,
                raisedHand: newRaisedStatus,
            })
        );

        APP.API.notifyRaiseHandUpdated(_localParticipantID, newRaisedStatus);
    }

    /**
     * Dispatches an action to toggle screensharing.
     *
     * @private
     * @returns {void}
     */
    _doToggleScreenshare() {
        if (this.props._desktopSharingEnabled) {
            this.props.dispatch(toggleScreensharing());
        }
    }

    /**
     * Dispatches an action to toggle the video quality dialog.
     *
     * @private
     * @returns {void}
     */
    _doToggleVideoQuality() {
        this.props.dispatch(toggleDialog(VideoQualityDialog));
    }

    /**
     * Dispaches an action to toggle tile view.
     *
     * @private
     * @returns {void}
     */
    _doToggleTileView() {
        this.props.dispatch(toggleTileView());
    }

    _onMouseOut: () => void;

    /**
     * Dispatches an action signaling the toolbar is not being hovered.
     *
     * @private
     * @returns {void}
     */
    _onMouseOut() {
        this.props.dispatch(setToolbarHovered(false));
    }

    _onMouseOver: () => void;

    /**
     * Dispatches an action signaling the toolbar is being hovered.
     *
     * @private
     * @returns {void}
     */
    _onMouseOver() {
        this.props.dispatch(setToolbarHovered(true));
    }

    _onSetOverflowVisible: (boolean) => void;

    /**
     * Sets the visibility of the overflow menu.
     *
     * @param {boolean} visible - Whether or not the overflow menu should be
     * displayed.
     * @private
     * @returns {void}
     */
    _onSetOverflowVisible(visible) {
        this.props.dispatch(setOverflowMenuVisible(visible));
    }

    _onShortcutToggleChat: () => void;

    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling the display of chat.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleChat() {
        sendAnalytics(
            createShortcutEvent("toggle.chat", {
                enable: !this.props._chatOpen,
            })
        );

        this._doToggleChat();
    }

    _onShortcutToggleVideoQuality: () => void;

    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling the display of Video Quality.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleVideoQuality() {
        sendAnalytics(createShortcutEvent("video.quality"));

        this._doToggleVideoQuality();
    }

    _onShortcutToggleTileView: () => void;

    /**
     * Dispatches an action for toggling the tile view.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleTileView() {
        sendAnalytics(
            createShortcutEvent("toggle.tileview", {
                enable: !this.props._tileViewEnabled,
            })
        );

        this._doToggleTileView();
    }

    _onShortcutToggleFullScreen: () => void;

    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling full screen mode.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleFullScreen() {
        sendAnalytics(
            createShortcutEvent("toggle.fullscreen", {
                enable: !this.props._fullScreen,
            })
        );

        this._doToggleFullScreen();
    }

    _onShortcutToggleRaiseHand: () => void;

    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling raise hand.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleRaiseHand() {
        sendAnalytics(
            createShortcutEvent(
                "toggle.raise.hand",
                ACTION_SHORTCUT_TRIGGERED,
                { enable: !this.props._raisedHand }
            )
        );

        this._doToggleRaiseHand();
    }

    _onShortcutToggleScreenshare: () => void;

    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling screensharing.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleScreenshare() {
        sendAnalytics(
            createToolbarEvent("screen.sharing", {
                enable: !this.props._screensharing,
            })
        );

        this._doToggleScreenshare();
    }

    _onTabIn: () => void;

    /**
     * Toggle the toolbar visibility when tabbing into it.
     *
     * @returns {void}
     */
    _onTabIn() {
        if (!this.props._visible) {
            this.props.dispatch(showToolbox());
        }
    }

    _onToolbarOpenFeedback: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * display of feedback.
     *
     * @private
     * @returns {void}
     */
    _onToolbarOpenFeedback() {
        sendAnalytics(createToolbarEvent("feedback"));

        this._doOpenFeedback();
    }

    _onToolbarOpenInvite: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for opening
     * the modal for inviting people directly into the conference.
     *
     * @private
     * @returns {void}
     */
    _onToolbarOpenInvite() {
        sendAnalytics(createToolbarEvent("invite"));
        this.props.dispatch(beginAddPeople());
    }

    _onToolbarToggleParticipantsPane: () => void;

    _onToolbarToggleParticipantsPane() {
        const { dispatch, _participantsPaneOpen } = this.props;

        if (_participantsPaneOpen) {
            dispatch(closeParticipantsPane());
        } else {
            dispatch(openParticipantsPane());
        }
    }
    _onToolbarShowCollabs: () => void;

    _onToolbarShowCollabs() {
        collab.init();
        if (!this.state.cursorShared) {
            $("#ofmeet-cursor").addClass("toggled");
            collab.startSharing(this.getNickColor());
        } else {
            $("#ofmeet-cursor").removeClass("toggled");
            collab.stopSharing();
        }

        this.setState({ cursorShared: !this.state.cursorShared });
    }

    _onToolbarOpenKeyboardShortcuts: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for opening
     * the modal for showing available keyboard shortcuts.
     *
     * @private
     * @returns {void}
     */
    _onToolbarOpenKeyboardShortcuts() {
        sendAnalytics(createToolbarEvent("shortcuts"));

        this._doOpenKeyboardShorcuts();
    }

    _onToolbarOpenEmbedMeeting: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for opening
     * the embed meeting modal.
     *
     * @private
     * @returns {void}
     */
    _onToolbarOpenEmbedMeeting() {
        sendAnalytics(createToolbarEvent("embed.meeting"));

        this._doOpenEmbedMeeting();
    }

    _onToolbarOpenSpeakerStats: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for opening
     * the speaker stats modal.
     *
     * @private
     * @returns {void}
     */
    _onToolbarOpenSpeakerStats() {
        sendAnalytics(createToolbarEvent("speaker.stats"));

        this._doOpenSpeakerStats();
    }

    _onToolbarOpenVideoQuality: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * open the video quality dialog.
     *
     * @private
     * @returns {void}
     */
    _onToolbarOpenVideoQuality() {
        sendAnalytics(createToolbarEvent("video.quality"));

        this._doOpenVideoQuality();
    }

    _onToolbarOpenScreenshot: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * open the video quality dialog.
     *
     * @private
     * @returns {void}
     */
    _onToolbarOpenScreenshot() {
        this._doOpenScreenshot();
    }

    _onToolbarToggleChat: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * the display of chat.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleChat() {
        sendAnalytics(
            createToolbarEvent("toggle.chat", {
                enable: !this.props._chatOpen,
            })
        );
        this._closeOverflowMenuIfOpen();
        this._doToggleChat();
    }

    _onToolbarToggleFullScreen: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * full screen mode.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleFullScreen() {
        sendAnalytics(
            createToolbarEvent("toggle.fullscreen", {
                enable: !this.props._fullScreen,
            })
        );
        this._closeOverflowMenuIfOpen();
        this._doToggleFullScreen();
    }

    _onToolbarToggleProfile: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for showing
     * or hiding the profile edit panel.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleProfile() {
        sendAnalytics(createToolbarEvent("profile"));

        this._doToggleProfile();
    }

    _onToolbarToggleRaiseHand: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * raise hand.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleRaiseHand() {
        sendAnalytics(
            createToolbarEvent("raise.hand", {
                enable: !this.props._raisedHand,
            })
        );

        this._doToggleRaiseHand();
    }
    _stopPoll: () => void;
    _stopPoll() {
        this.props.dispatch(stopPoll());
        this.setState({ isPoll });
    }

    //------------------- Voting -----------------------
    _onToolbarVoting: () => void;
    _onToolbarVoting() {
        if (!this.props._isPollRunning) {
            sendAnalytics(
                createToolbarEvent("toggle.vote", {
                    enable: !this.props._voting,
                })
            );
            this._doToggleVoting();
            APP.conference.ShowVotePollForAll();
        } else {
            this._stopPoll();
        }
    }
    _onToolbarToggleScreenshare: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * screensharing.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleScreenshare() {
        if (!this.props._desktopSharingEnabled) {
            return;
        }

        sendAnalytics(
            createShortcutEvent(
                "toggle.screen.sharing",
                ACTION_SHORTCUT_TRIGGERED,
                { enable: !this.props._screensharing }
            )
        );

        this._closeOverflowMenuIfOpen();
        this._doToggleScreenshare();
    }

    _onToolbarToggleSharedVideo: () => void;

    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * the sharing of a YouTube video.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleSharedVideo() {
        sendAnalytics(
            createToolbarEvent("shared.video.toggled", {
                enable: !this.props._sharingVideo,
            })
        );

        this._doToggleSharedVideo();
    }

    _onToolbarOpenLocalRecordingInfoDialog: () => void;

    /**
     * Opens the {@code LocalRecordingInfoDialog}.
     *
     * @private
     * @returns {void}
     */
    _onToolbarOpenLocalRecordingInfoDialog() {
        sendAnalytics(createToolbarEvent("local.recording"));

        this.props.dispatch(openDialog(LocalRecordingInfoDialog));
    }

    /**
     * Returns true if the desktop sharing button should be visible and
     * false otherwise.
     *
     * @returns {boolean}
     */
    _showDesktopSharingButton() {
        const {
            _desktopSharingEnabled,
            _desktopSharingDisabledTooltipKey,
            ScreenShareBtnVisiblity,
        } = this.props;

        return (
            (_desktopSharingEnabled || _desktopSharingDisabledTooltipKey) &&
            this._shouldShowButton("desktop") &&
            ScreenShareBtnVisiblity
        );
    }

    /**
     * Returns true if the embed meeting button is visible and false otherwise.
     *
     * @returns {boolean}
     */
    _isEmbedMeetingVisible() {
        return (
            !this.props._isVpaasMeeting &&
            !this.props._isMobile &&
            this._shouldShowButton("embedmeeting")
        );
    }

    /**
     * Returns true if the profile button is visible and false otherwise.
     *
     * @returns {boolean}
     */
    _isProfileVisible() {
        return (
            !this.props._isProfileDisabled && this._shouldShowButton("profile")
        );
    }

    /**
     * Renders the list elements of the overflow menu.
     *
     * @private
     * @param {Array < React$Element >} additionalButtons - Additional buttons to be displayed.
     * @returns {Array < React$Element >}
     */
    _renderOverflowMenuContent(additionalButtons: Array<React$Element<any>>) {
        const {
            _feedbackConfigured,
            _fullScreen,
            _isMobile,
            _screensharing,
            t,
            _sharingVideo,
        } = this.props;

        const group1 = [
            ...additionalButtons,

            this._shouldShowButton("toggle-camera") && (
                <ToggleCameraButton key="toggle-camera" showLabel={true} />
            ),
            this._shouldShowButton("videoquality") && (
                <OverflowMenuVideoQualityItem
                    key="videoquality"
                    onClick={this._onToolbarOpenVideoQuality}
                />
            ),
            this.props.screenshotPermission && (
                <OverflowMenuScreenshotItem
                    key="screenShot"
                    onClick={this._onToolbarOpenScreenshot}
                />
            ),

            this.state.isPoll
            &&
              this._shouldShowButton("voting-poll") && 
            (
                <OverflowMenuItem
                    accessibilityLabel={t("toolbar.accessibilityLabel.vote")}
                    icon={IconVote}
                    key="vote"
                    onClick={this._onToolbarVoting}
                    // text = { t('toolbar.vote') } />,
                    text={
                        this.props._isPollRunning
                            ? t("toolbar.stopPoll")
                            : t("toolbar.startPoll")
                    }
                />
            ),
            this._shouldShowButton("fullscreen") && !_isMobile && (
                <OverflowMenuItem
                    accessibilityLabel={t(
                        "toolbar.accessibilityLabel.fullScreen"
                    )}
                    icon={_fullScreen ? IconExitFullScreen : IconFullScreen}
                    key="fullscreen"
                    onClick={this._onToolbarToggleFullScreen}
                    text={
                        _fullScreen
                            ? t("toolbar.exitFullScreen")
                            : t("toolbar.enterFullScreen")
                    }
                />
            ),
            /**
             * Button for NotPad functionality
             */
            this._shouldShowButton("notepad") && (
                <OverflowMenuItem
                    accessibilityLabel={t("toolbar.accessibilityLabel.notepad")}
                    icon={Notepad}
                    key="notepad"
                    onClick={this._onToolbarToggleNotes}
                    text={t("toolbar.notepad")}
                />
            ),
            /**
             * Button for poll start functionality
             */
            this.state.isQuiz &&
              this._shouldShowButton("Quiz") && 
            (
                <OverflowMenuItem
                    accessibilityLabel={t("toolbar.accessibilityLabel.poll")}
                    icon={IconPolls}
                    key="poll"
                    onClick={this._onToolbarReduxPoll}
                    text={
                        this.props._isQuizRunning
                            ? t("toolbar.stopQuiz")
                            : t("toolbar.startQuiz")
                    }
                />
            ),
            this.isSubtitleRequired() &&
            this._shouldShowButton("closedcaptions") && (
                <ClosedCaptionButton
                    key="closed-captions"
                    showLabel={true}
                />
            ),
            this._shouldShowButton("recording") && this.props._isHost && (
                <RecordButton key="record" showLabel={true} />
            ),
            this._shouldShowButton("localrecording") && (
                <OverflowMenuItem
                    accessibilityLabel={t(
                        "toolbar.accessibilityLabel.localRecording"
                    )}
                    icon={IconRec}
                    key="localrecording"
                    onClick={this._onToolbarOpenLocalRecordingInfoDialog}
                    text={t("localRecording.dialogTitle")}
                />
            ),
            this._shouldShowButton("mute-everyone") && this.props._isHost && (
                <MuteEveryoneButton key="mute-everyone" showLabel={true} />
            ),
            this._shouldShowButton("livestreaming") && (
                <LiveStreamButton key="livestreaming" showLabel={true} />
            ),
        ];

        const group2 = [
            // this._shouldShowButton('sharedvideo')
            // && <SharedVideoButton
            //     key='sharedvideo'
            //     showLabel={true} />,

            this._shouldShowButton("sharedvideo") && (
                <OverflowMenuItem
                    accessibilityLabel={t(
                        "toolbar.accessibilityLabel.sharedvideo"
                    )}
                    icon={IconShareVideo}
                    key="sharedvideo"
                    onClick={this._onToolbarToggleSharedVideo}
                    text={
                        _sharingVideo
                            ? t("toolbar.stopSharedVideo")
                            : t("toolbar.sharedvideo")
                    }
                />
            ),
            this._shouldShowButton("etherpad") && (
                <SharedDocumentButton key="etherpad" showLabel={true} />
            ),
            // (this._shouldShowButton('select-background') || this._shouldShowButton('videobackgroundblur'))
            // && <VideoBackgroundButton
            //     key={'select-background'}
            //     showLabel={true}
            //     visible={!_screensharing && checkBlurSupport()} />,
            //   this._shouldShowButton('videobackgroundblur') &&
            // <VideoBlurButton
            //     key='videobackgroundblur'
            //     showLabel={true}
            //     visible={!_screensharing} />,
            //    <VideoBackgroundButton
            //     key='videobackgroundvirtual'
            //     showLabel={true}
            //     visible={!_screensharing} />,
            // (this._shouldShowButton('select-background') || this._shouldShowButton('videobackgroundblur'))
            // &&
            <VideoBackgroundButton
                key={"select-background"}
                showLabel={true}
                visible={!_screensharing && checkBlurSupport()}
            />,
            this._shouldShowButton("stats") && (
                <OverflowMenuItem
                    accessibilityLabel={t(
                        "toolbar.accessibilityLabel.speakerStats"
                    )}
                    icon={IconPresentation}
                    key="stats"
                    onClick={this._onToolbarOpenSpeakerStats}
                    text={t("toolbar.speakerStats")}
                />
            ),
        ];

        return [
            this._isProfileVisible() && (
                <OverflowMenuProfileItem
                    key="profile"
                    onClick={this._onToolbarToggleProfile}
                />
            ),
            this._isProfileVisible() && (
                <hr className="overflow-menu-hr" key="hr1" />
            ),

            ...group1,
            group1.some(Boolean) && (
                <hr className="overflow-menu-hr" key="hr2" />
            ),

            ...group2,
            group2.some(Boolean) && (
                <hr className="overflow-menu-hr" key="hr3" />
            ),

            this._shouldShowButton("settings") && (
                <SettingsButton key="settings" showLabel={true} />
            ),
            this._shouldShowButton("shortcuts") && !_isMobile && (
                <OverflowMenuItem
                    accessibilityLabel={t(
                        "toolbar.accessibilityLabel.shortcuts"
                    )}
                    icon={IconDeviceDocument}
                    key="shortcuts"
                    onClick={this._onToolbarOpenKeyboardShortcuts}
                    text={t("toolbar.shortcuts")}
                />
            ),
            // this._isEmbedMeetingVisible()
            //     && <OverflowMenuItem
            //         accessibilityLabel = { t('toolbar.accessibilityLabel.embedMeeting') }
            //         icon = { IconCodeBlock }
            //         key = 'embed'
            //         onClick = { this._onToolbarOpenEmbedMeeting }
            //         text = { t('toolbar.embedMeeting') } />,
            this._shouldShowButton("feedback") && _feedbackConfigured && (
                <OverflowMenuItem
                    accessibilityLabel={t(
                        "toolbar.accessibilityLabel.feedback"
                    )}
                    icon={IconFeedback}
                    key="feedback"
                    onClick={this._onToolbarOpenFeedback}
                    text={t("toolbar.feedback")}
                />
            ),
            this._shouldShowButton("download") && (
                <DownloadButton key="download" showLabel={true} />
            ),
            this._shouldShowButton("help") && (
                <HelpButton key="help" showLabel={true} />
            ),
        ];
    }

    /**
     * Returns the buttons to be displayed in main or the overflow menu.
     *
     * @param {Set} buttons - A set containing the buttons to be displayed
     * in the toolbar beside the main audio/video & hanugup.
     * @returns {Object}
     */
    _getAdditionalButtons(buttons) {
        const {
            _chatOpen,
            _desktopSharingEnabled,
            _desktopSharingDisabledTooltipKey,
            _raisedHand,
            _isMobile,
            _screensharing,
            t,
        } = this.props;

        const overflowMenuAdditionalButtons = [];
        const mainMenuAdditionalButtons = [];

        if (this._showDesktopSharingButton()) {
            buttons.has("desktop") && !_isMobile
                ? mainMenuAdditionalButtons.push(
                    <ToolbarButton
                        accessibilityLabel={t(
                            "toolbar.accessibilityLabel.shareYourScreen"
                        )}
                        disabled={!_desktopSharingEnabled}
                        icon={IconShareDesktop}
                        key="desktop"
                        onClick={this._onToolbarToggleScreenshare}
                        toggled={_screensharing}
                        tooltip={t(
                            _desktopSharingEnabled
                                ? "dialog.shareYourScreen"
                                : _desktopSharingDisabledTooltipKey
                        )}
                    />
                )
                : overflowMenuAdditionalButtons.push(
                    <OverflowMenuItem
                        accessibilityLabel={t(
                            "toolbar.accessibilityLabel.shareYourScreen"
                        )}
                        icon={IconShareDesktop}
                        iconId="share-desktop"
                        key="desktop"
                        onClick={this._onToolbarToggleScreenshare}
                        text={t(
                            `toolbar.${_screensharing
                                ? "stopScreenSharing"
                                : "startScreenSharing"
                            }`
                        )}
                    />
                );
        }

        if (this._shouldShowButton("chat")) {
            buttons.has("chat")
                ? mainMenuAdditionalButtons.push(
                    <div
                        className="toolbar-button-with-badge"
                        key="chatcontainer"
                    >
                        <ToolbarButton
                            accessibilityLabel={t(
                                "toolbar.accessibilityLabel.chat"
                            )}
                            icon={IconChat}
                            key="chat"
                            onClick={this._onToolbarToggleChat}
                            toggled={_chatOpen}
                            tooltip={t("toolbar.chat")}
                        />
                        <ChatCounter />
                    </div>
                )
                : overflowMenuAdditionalButtons.push(
                    <OverflowMenuItem
                        accessibilityLabel={t(
                            "toolbar.accessibilityLabel.chat"
                        )}
                        icon={IconChat}
                        key="chat"
                        onClick={this._onToolbarToggleChat}
                        text={t(
                            `toolbar.${_chatOpen ? "closeChat" : "openChat"}`
                        )}
                    />
                );
        }

        if (this._shouldShowButton("raisehand")) {
            buttons.has("raisehand") && !_isMobile
                ? mainMenuAdditionalButtons.push(
                    <ToolbarButton
                        accessibilityLabel={t(
                            "toolbar.accessibilityLabel.raiseHand"
                        )}
                        icon={IconRaisedHand}
                        key="raisehand"
                        onClick={this._onToolbarToggleRaiseHand}
                        toggled={_raisedHand}
                        tooltip={t(
                            `toolbar.${_raisedHand
                                ? "lowerYourHand"
                                : "raiseYourHand"
                            }`
                        )}
                    />
                )
                : overflowMenuAdditionalButtons.push(
                    <OverflowMenuItem
                        accessibilityLabel={t(
                            "toolbar.accessibilityLabel.raiseHand"
                        )}
                        icon={IconRaisedHand}
                        key="raisehand"
                        onClick={this._onToolbarToggleRaiseHand}
                        text={t(
                            `toolbar.${_raisedHand
                                ? "lowerYourHand"
                                : "raiseYourHand"
                            }`
                        )}
                    />
                );
        }

        if (this._shouldShowButton("tileview")) {
            buttons.has("tileview") && !_isMobile
                ? mainMenuAdditionalButtons.push(
                    <TileViewButton key="tileview" showLabel={false} />
                )
                : overflowMenuAdditionalButtons.push(
                    <TileViewButton key="tileview" showLabel={true} />
                );
        }

        if (this._shouldShowButton("invitePeople")) {
            buttons.has("invite") &&
                overflowMenuAdditionalButtons.push(
                    <div
                        className="invite_main"
                        onClick={() =>
                            this.setState({
                                inviteModalOpen: true,
                            })
                        }
                    >
                        {" "}
                        <InviteTeamIcon
                            label="Invite People"
                            size="medium"
                        />{" "}
                        <li className="invite_li">Invite People</li>
                    </div>
                );
        }

        this.props.filePermission &&
            overflowMenuAdditionalButtons.push(
                <OverflowMenuItem
                    accessibilityLabel={"Upload Documents"}
                    icon={UploadIcon}
                    onClick={() =>
                        this.setState({ uploadModalOpen: true, documents: [] })
                    }
                    text={"Upload Documents"}
                />
            );

        this._shouldShowButton("participantView") &&
            !_isMobile &&
            mainMenuAdditionalButtons.push(
                <ToolbarButton
                    accessibilityLabel={t(
                        "toolbar.accessibilityLabel.participants"
                    )}
                    icon={IconParticipants}
                    onClick={this._onToolbarToggleParticipantsPane}
                    toggled={this.props._participantsPaneOpen}
                    tooltip={t("toolbar.participants")}
                />
            );

        // _screensharing&& !_isMobile
        // mainMenuAdditionalButtons.push(
        //     <ToolbarButton
        //         accessibilityLabel={t('toolbar.accessibilityLabel.participants')}
        //         icon={IconCursor}
        //         onClick={this._onToolbarShowCollabs}
        //         tooltip='Highlight Cursor' />)

        // _isMobile?
        // overflowMenuAdditionalButtons.push(
        //     <InfoDialogButton />):
        mainMenuAdditionalButtons.push(<InfoDialogButton />);

        this._shouldShowButton("lobby") &&
            isParticipantModerator &&
            mainMenuAdditionalButtons.push(<LobbyModeButton />);

        return {
            mainMenuAdditionalButtons,
            overflowMenuAdditionalButtons,
        };
    }

    /**
     * Renders the Audio controlling button.
     *
     * @returns {ReactElement}
     */
    _renderAudioButton() {
        return this._shouldShowButton("microphone") &&
            this.props.AudioBtnVisiblity ? (
            <AudioSettingsButton key="asb" visible={true} />
        ) : null;
    }

    /**
     * Renders the Video controlling button.
     *
     * @returns {ReactElement}
     */
    _renderVideoButton() {
        console.log("this.props._sharingVideo", this.props._sharingVideo);
        return this._shouldShowButton("camera") &&
            this.props.VideoBtnVisiblity ? (
            isMobileBrowser() ? (
                <ToggleCameraButton key="tvb" visible={true} />
            ) : (
                <VideoSettingsButton key="vsb" visible={true} />
            )
        ) : null;
    }

    /**
     * Renders the toolbox content.
     *
     * @returns {ReactElement}
     */
    _renderToolboxContent() {
        const { _clientWidth, _isMobile, _overflowMenuVisible, t } = this.props;

        const buttonSet = getToolbarAdditionalButtons(_clientWidth, _isMobile);
        const toolbarAccLabel = "toolbar.accessibilityLabel.moreActionsMenu";
        const showOverflowMenuButton = buttonSet.has("overflow");
        const containerClassName = `toolbox-content${_isMobile ? " toolbox-content-mobile" : ""
            }`;
        let overflowMenuAdditionalButtons = [];
        let mainMenuAdditionalButtons = [];

        if (showOverflowMenuButton) {
            ({ overflowMenuAdditionalButtons, mainMenuAdditionalButtons } =
                this._getAdditionalButtons(this.props._visibleButtons));
        }

        if (this._shouldShowButton("lobby")) {
            //     buttonsRight.push('lobby');
        }

        return (
            <div className={containerClassName}>
                <div className="toolbox-content-wrapper">
                    {/* <InviteMore /> */}
                    <div className="toolbox-content-items">
                        {this._renderAudioButton()}
                        {this._renderVideoButton()}
                        {mainMenuAdditionalButtons}

                        {showOverflowMenuButton &&
                            !this.state.inviteModalOpen &&
                            !this.state.embeddToggle && (
                                <OverflowMenuButton
                                    isOpen={_overflowMenuVisible}
                                    customClass="OverFlowMenu"
                                    onVisibilityChange={
                                        this._onSetOverflowVisible
                                    }
                                >
                                    <ul
                                        aria-label={t(toolbarAccLabel)}
                                        className="overflow-menu"
                                    >
                                        {this._renderOverflowMenuContent(
                                            overflowMenuAdditionalButtons
                                        )}
                                    </ul>
                                </OverflowMenuButton>
                            )}

                        {/* <HangupButton
                            customClass = 'hangup-button'
                            visible = { this._shouldShowButton('hangup') } /> */}

                        {this._shouldShowButton("endcallforall") &&
                            APP.conference.participantCount > 1 && (
                                <EndCallOptionsDialog />
                            )}

                        {this._shouldShowButton("endcallforall") &&
                            APP.conference.participantCount == 1 && (
                                <HangupButton
                                    customClass="hangup-button"
                                    visible={this._shouldShowButton("hangup")}
                                />
                            )}

                        {!this._shouldShowButton("endcallforall") && (
                            <HangupButton
                                customClass="hangup-button"
                                visible={this._shouldShowButton("hangup")}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }

    _shouldShowButton: (string) => boolean;

    /**
     * Returns if a button name has been explicitly configured to be displayed.
     *
     * @param {string} buttonName - The name of the button, as expected in
     * {@link interfaceConfig}.
     * @private
     * @returns {boolean} True if the button should be displayed.
     */
    _shouldShowButton(buttonName) {
        return this.props._visibleButtons.has(buttonName);
    }

    getNickColor(reset = false) {
        // if (!reset && storage.getItem('ofmeet.settings.nickColor')) {
        //     return storage.getItem('ofmeet.settings.nickColor');
        // } else {
        let hsl = tinycolor(tinycolor.random()).toHsl();
        hsl.l = hsl.l * 0.5 + 0.2;

        let color = tinycolor(hsl).toHexString();

        // storage.setItem('ofmeet.settings.nickColor', color);
        return color;
        //}
    }

    getQuizInformation() {
        var apiDomain = this.props._defaultAPIUrl;
        try {
            fetch(
                apiDomain +
                "/quiz/getQuizNameMeetingCode/" +
                APP.conference.roomName
            )
                .then((res) => res.json())
                .then((result) => {
                    if (result.length > 0) {
                        result.forEach((element) => {
                            this.setState({ quizIds: element });
                        });
                        fetch(
                            apiDomain +
                            "/quiz/getQuizQuestionFullData/" +
                            this.state.quizIds.quizid
                        )
                            .then((res) => res.json())
                            .then((result) => {
                                this.setState({ _isQuizInfoReceived: true });
                                if (result.quizAndPollNews.length > 0) {
                                    this.setState({ isQuiz: true });
                                }
                            });
                    }
                });
        } catch (e) { }
    }

    _getFeaturesForMeeting(meetingCode) {
        this.setState({ isFeatureRequesting: true });
        if (meetingCode != "undefined") {
            const apiDomain = getAPIURL(APP.store.getState());
            axios
                .get(apiDomain + `/anonymous/features/` + meetingCode)
                .then((res) => {
                    this.setState({ isFeatureRequesting: false });
                    const feature = res.data;
                    let data = feature.features;
                    if (data != null) {
                        for (var i = 0; i < data.length; i++) {
                            if (data[i].feature_id == 1) {
                                this.setState({ isCloseCaptionEnabled: true });
                            }
                        }
                        this.setState({ isStatusReceived: true });
                    }
                });
        }
    }

    /**
     *
     */
    isSubtitleRequired() {
        const { _conference } = this.props;
        if (_conference) {
            //
            let confName = _conference.getName();
            if (
                !this.state.isStatusReceived &&
                !this.state.isFeatureRequesting
            ) {
                this._getFeaturesForMeeting(confName);
            }
            return this.state.isCloseCaptionEnabled;
        }

        return false;
    }
}

/**
 * Maps (parts of) the redux state to {@link Toolbox}'s React {@code Component}
 * props.
 *
 * @param {Object} state - The redux store/state.
 * @private
 * @returns {{}}
 */
function _mapStateToProps(state) {
    const { conference, locked } = state["features/base/conference"];
    let desktopSharingEnabled = JitsiMeetJS.isDesktopSharingEnabled();
    const { callStatsID, enableFeaturesBasedOnToken } =
        state["features/base/config"];
    const { fullScreen, overflowMenuVisible } = state["features/toolbox"];
    const localParticipant = getLocalParticipant(state);
    const localRecordingStates = state["features/local-recording"];
    const localVideo = getLocalVideoTrack(state["features/base/tracks"]);
    const { clientWidth } = state["features/base/responsive-ui"];
    const sharedVideoStatus = state["features/shared-video"].status;
    let desktopSharingDisabledTooltipKey;

    if (enableFeaturesBasedOnToken) {
        // we enable desktop sharing if any participant already have this
        // feature enabled
        desktopSharingEnabled =
            getParticipants(state).find(
                ({ features = {} }) =>
                    String(features["screen-sharing"]) === "true"
            ) !== undefined;
        desktopSharingDisabledTooltipKey = "dialog.shareYourScreenDisabled";
    }
    // NB: We compute the buttons again here because if URL parameters were used to
    // override them we'd miss it.
    //    _isMobile: isMobileBrowser(),
    const buttons = new Set(interfaceConfig.TOOLBAR_BUTTONS);

    return {
        _chatOpen: state["features/chat"].isOpen,
        _clientWidth: clientWidth,
        _conference: conference,
        _desktopSharingEnabled: desktopSharingEnabled,
        _desktopSharingDisabledTooltipKey: desktopSharingDisabledTooltipKey,
        _dialog: Boolean(state["features/base/dialog"].component),
        _feedbackConfigured: Boolean(callStatsID),
        _isProfileDisabled: Boolean(
            state["features/base/config"].disableProfile
        ),
        _isMobile: isMobileBrowser(),
        _isVpaasMeeting: isVpaasMeeting(state),
        _fullScreen: fullScreen,
        _tileViewEnabled: shouldDisplayTileView(state),
        _localParticipantID: localParticipant.id,
        _localRecState: localRecordingStates,
        _locked: locked,
        _overflowMenuVisible: overflowMenuVisible,
        _raisedHand: localParticipant.raisedHand,
        _screensharing: localVideo && localVideo.videoType === "desktop",
        _visible: isToolboxVisible(state),
        // _visibleButtons: getToolbarButtons(state),
        _sharingVideo:
            sharedVideoStatus === "playing" ||
            sharedVideoStatus === "start" ||
            sharedVideoStatus === "pause",
        _visibleButtons: equals(visibleButtons, buttons)
            ? visibleButtons
            : buttons,
        _isQuizRunning: state["features/reduxPoll"].isPollStarted,
        _isPollRunning: state["features/voting"].isVotePollStarted,
        _participantsPaneOpen: getParticipantsPaneOpen(state),
        _shouldShowButton: (buttonName) =>
            isToolbarButtonEnabled(buttonName)(state),
        AudioBtnVisiblity: state["features/webinar"]._isAudioBtnVisible,
        VideoBtnVisiblity: state["features/webinar"]._isVideoBtnVisible,
        ScreenShareBtnVisiblity:
            state["features/webinar"]._ScreenShareBtnVisiblity,
        _isHost: isLocalParticipantModerator(state),
        screenshotPermission: state["features/webinar"].screenshotPermission,
        filePermission: state["features/webinar"].filePermission,
        _defaultAPIUrl: getAPIURL(state),
    };
}

export default translate(connect(_mapStateToProps)(Toolbox));
