// @flow

//import { IconMicrophone } from '../../../base/icons';
import { AbstractButton } from '../../../base/toolbox';
import { IconMicDisabled, IconMicrophone } from '../../../base/icons';
import { SEND_MUTE_COMMAND } from '../../constants';
import { connect } from '../../../base/redux';
type Props = {
    userId:string
       
};


/**
 * An abstract implementation of a button for toggling audio mute.
 */
 class ParticipantAudioMuteButton<P: Props, S: *>
    extends AbstractButton<P, S> {

    icon = IconMicrophone;
    toggledIcon = IconMicDisabled;

 

    constructor(props: Props) {
        super(props);

        this.state = {
            audioAvailable: false,
            audioMuted: true
        };
        APP.conference.addUserStatChangeListener(this);

    }



    onParticipantState(data)
    {
        var _participantId = data.participantId;
        if(_participantId==null)
        {
            _participantId = data.jitsiTrack.ownerEndpointId;
        }

        if(_participantId == this.props._id)
        {
       
            if(data.jitsiTrack.type=="audio")
            {

                var videoStarted = data.jitsiTrack.videoStarted;
                var videoType = data.jitsiTrack.videoType;
                var muted = data.jitsiTrack.muted;
                this._setAudioMuted(muted);
            }
            // try {
            //     APP.conference.sendEndpointMessage(this.props._id,{
            //         name: SEND_MUTE_COMMAND,
            //         text:{'audio_mute':muted}
            //      });
            //     } 
            // catch (err) {
            //        console.log("Error while sending command for audio :: "+err);
            //     }
        }
      
    }


    /**
     * Handles clicking / pressing the button, and toggles the audio mute state
     * accordingly.
     *
     * @override
     * @protected
     * @returns {void}
     */
    _handleClick() {
       // alert(this.props._id);
        try {
        APP.conference.sendEndpointMessage(this.props._id,{
            name: SEND_MUTE_COMMAND,
            text:{'audio_mute':!this._isAudioMuted()}
         });
        } catch (err) {
           console.log("Error while sending command for audio :: "+err);
          // APP.conference.endCallForAll();
        }

        this._setAudioMuted(!this._isAudioMuted());
    }

    /**
     * Helper function to be implemented by subclasses, which must return a
     * boolean value indicating if audio is muted or not.
     *
     * @protected
     * @returns {boolean}
     */
    _isAudioMuted() {
        // To be implemented by subclass.
        return this.state.audioMuted;
    }

    /**
     * Indicates whether this button is in toggled state or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _isToggled() {
        return this._isAudioMuted();
    }

    /**
     * Helper function to perform the actual setting of the audio mute / unmute
     * action.
     *
     * @param {boolean} audioMuted - Whether video should be muted or not.
     * @protected
     * @returns {void}
     */
    _setAudioMuted(_audioMuted: boolean) { // eslint-disable-line no-unused-vars
        // To be implemented by subclass.
       this.setState({audioMuted:_audioMuted});
        
    }
}


function _mapStateToProps(state) {
   const { iAmSipGateway } = state['features/base/config'];
   const localParticipant = getLocalParticipant(state);
    return {
        _className: className,
        _columns: gridDimensions.columns,
        _currentLayout: getCurrentLayout(state),
        _filmstripOnly: isFilmstripOnly,
        _filmstripWidth: filmstripWidth,
        _overflowMenuVisible: popmenu_isVisible,
        _visibleMenuId:visibleMenuId,
        _hideScrollbar: Boolean(iAmSipGateway),
        _hideToolbar: Boolean(iAmSipGateway),
        _hovered: hovered,
        _rows: gridDimensions.rows,
        _videosClassName: videosClassName,
        _visible: visible,
        _participants:participants,
        _localParticipant:localParticipant
    };
}

export default ParticipantAudioMuteButton ;//connect(_mapStateToProps)(ParticipantAudioMuteButton);
