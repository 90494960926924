// @flow
import React, { Component } from 'react';
import InlineDialog from '@atlaskit/inline-dialog';
import { connect } from '../../../base/redux';
import { HangupButton } from '..';
import { appNavigate } from '../../../app';
import { disconnect } from '../../../base/connection/actions.native';
import {
  AbstractButton,
  type AbstractButtonProps
} from '../../../base/toolbox';

import "../../../../../css/_endoptions.css";

type Props = AbstractButtonProps & {

  /**
   * Component's children (the audio button).
   */
  children: React$Node,

  /**
   * Flag controlling the visibility of the popup.
   */
  isOpen: boolean,

  /**
   * Callback executed when the popup closes.
   */
  onClose: Function,
  dispatch: Function,
}

const styles: React.CSSProperties = {
 padding: '50px'
};

class EndCallOptionsDialog extends AbstractButton<Props, State> {
  state = {
    dialogOpen: false,
    placementIndex: 0
  };



  toggleDialog = () => this.setState({ dialogOpen: !this.state.dialogOpen });

  endCallForMe = () => {
    if (navigator.product === 'ReactNative') {
      this.props.dispatch(appNavigate(undefined));
    } else {
      // this.props.dispatch(leaveRoomAndDisconnect(false));
      APP.conference.hangup();
    }
  };

  endCallForAll = () => {  
     APP.conference.endCallForAll();
    };

  render() {
    return (
      <div className='toolbox-button-wth-dialog hangup-button' >
        <InlineDialog style={styles}
          
          onClose={() => {
            this.setState({ dialogOpen: false });
          }}
          content={
            <div 
            style={{
             padding:0,             
              width: '200px'             
            }}  >
              <div className='end-call'
                onClick={this.endCallForMe}>                               
                End Call For Me
            </div>
              <div className='end-call-all'             
             
                onClick={this.endCallForAll}>
                End Call For All
            </div>
            </div>
          }
          isOpen={this.state.dialogOpen}
          position='top center'
        >
          <HangupButton
            disabled={false}
            onClick={this.toggleDialog}
          />
        </InlineDialog>
      </div>
    );
  }
}


/**
 * Function that maps parts of Redux state tree into component props.
 *
 * @param {Object} state - Redux state.
 * @returns {Object}
 */
function _mapStateToProps(state: Object, ownProps: Props) {
  return {

  };
}

const mapDispatchToProps = {

};

export default connect(_mapStateToProps)(EndCallOptionsDialog);
