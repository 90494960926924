// @flow

import { Component } from 'react';
import type { Dispatch } from 'redux';
import { toggleNotes } from '../actions';

/**
 * The type of the React {@code Component} props of {@code AbstractNote}.
 */
export type Props = {

    /**
     * True if the chat window should be rendered.
     */
    _isOpen: boolean,
    /**
     * Function to toggle the chat window.
     */
    _onToggleNote: Function,

    /**
     * The Redux dispatch function.
     */
    dispatch: Dispatch<any>,

    /**
     * Function to be used to translate i18n labels.
     */
    t: Function
};

/**
 * Implements an abstract chat panel.
 */
export default class AbstractNote<P: Props> extends Component<P> {}

export function _mapDispatchToProps(dispatch: Dispatch<any>) {
    return {
        /**
         * Toggles the chat window.
         *
         * @returns {Function}
         */
        _onToggleNotes() {
            dispatch(toggleNotes());
        },

       
    };
}
export function _mapStateToProps(state: Object) {
    const { isOpen, messages } = state['features/NoteP'];   
    return {
        _isOpen: isOpen 
    };
}
