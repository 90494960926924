// @flow

/**
 * The height of the filmstrip in narrow aspect ratio, or width in wide.
 */
export const FILMSTRIP_SIZE = 90;

/**
 * The aspect ratio of a tile in tile view.
 */
export const TILE_ASPECT_RATIO = 16 / 9;


export const SEND_MUTE_COMMAND="send_mute_command";
export const SEND_MUTEVIDEO_COMMAND="send_mute_video_command";
export const SEND_SCREENSHARE_COMMAND="send_screenshare_command";