// @flow
import { ReducerRegistry } from '../base/redux';

import {
    TOGGLE_NOTES
} from './actionTypes';

const DEFAULT_STATE = {
    isOpen: false   
};

ReducerRegistry.register('features/NoteP', (state = DEFAULT_STATE, action) => {
    switch (action.type) {

    case TOGGLE_NOTES:
        return updateChatState(state);
    }

    return state;
});

/**
 * Updates the chat status on opening the chat view.
 *
 * @param {Object} state - The Redux state of the feature.
 * @returns {Object}
 */
function updateChatState(state) {
    return {
        ...state,
        isOpen: !state.isOpen       
    };
}
