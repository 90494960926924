
import React, { Component } from 'react';
import { connect } from '../../base/redux';
import type { Dispatch } from 'redux';
import { Dialog } from '../../base/dialog';
import { translate } from '../../base/i18n';
import Banner from '@atlaskit/banner';


/**
 * The scores to display for selecting. The score is the index in the array and
 * the value of the index is a translation key used for display in the dialog.
 *
 * @types {string[]}
 */


/**
 * The type of the React {@code Component} props of {@link callEnd}.
 */
type Props = {

    /**
     * The cached feedback message, if any, that was set when closing a previous
     * instance of {@code callEnd}.
     */
    _message: string,

    /**
     * The cached feedback score, if any, that was set when closing a previous
     * instance of {@code callEnd}.
     */
    _score: number,

    /**
     * The JitsiConference that is being rated. The conference is passed in
     * because feedback can occur after a conference has been left, so
     * references to it may no longer exist in redux.
     */
    conference: Object,

    openModal:Object,

    /**
     * Invoked to signal feedback submission or canceling.
     */
    dispatch: Dispatch<any>,

    /**
     * Callback invoked when {@code callEnd} is unmounted.
     */
    onClose: Function,

    /**
     * Invoked to obtain translated strings.
     */
    t: Function,
    _isOpen:Boolean
  
};


class EndCallBanner extends Component<Props, State> {

    constructor(props: Props) {
        super(props);       
        const { _message, _score,_isOpen } = this.props;
        const waitTime= 60;
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.state = {
            isOpen:this.props._isOpen,
            _message:"Host has ended the call.Your call will be ended automatically in ",
            time: 0,
            isOn: false,
            start: 0,
            _bannerMessage:'Host has ended the call.Your call will be ended automatically in 30 seconds.' ,
            seconds: 0                 
        };

    }
    componentDidUpdate()
    {
        if(this.props._isOpen && this.state.seconds ==0)
        {
            this.setState({
                seconds:30
            },()=>{
                this.startTimer();
            });
        }
    }

     forHumans ( seconds ) {
        var levels = [
            [Math.floor(seconds / 31536000), 'years'],
            [Math.floor((seconds % 31536000) / 86400), 'days'],
            [Math.floor(((seconds % 31536000) % 86400) / 3600), 'hours'],
            [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'minutes'],
            [(((seconds % 31536000) % 86400) % 3600) % 60, 'seconds'],
        ];
        var returntext = '';
    
        for (var i = 0, max = levels.length; i < max; i++) {
            if ( levels[i][0] === 0 ) continue;
            returntext += ' ' + levels[i][0] + ' ' + (levels[i][0] === 1 ? levels[i][1].substr(0, levels[i][1].length-1): levels[i][1]);
        };
        return returntext.trim();
    }

    secondsToTime(secs){
        let hours = Math.floor(secs / (60 * 60));
    
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
    
        let obj = {
          "h": hours,
          "m": minutes,
          "s": seconds
        };
        return obj;
      }

      
    startTimer()
    {
          if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
          }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        let message = null;
         this.secondsToTime(seconds);
         message= this.state._message+" "+this.forHumans(seconds)+".";
        
        this.setState({
          seconds: seconds,
          _bannerMessage:message
        });
        
        // Check if we're at zero.
        if (seconds == 0) { 
          clearInterval(this.timer);
          APP.conference.hangupAfterConfirm();
        }
      }

    stopTimer() {
        this.setState({isOn: false})
        clearInterval(this.timer)
      }

    render()
    {
        return( 
          <Banner
            appearance="error"
            isOpen ={this.props._isOpen}
          >
           <div style = {{color:'white'}}>{this.state._bannerMessage}</div> 
          </Banner>);
    }

}

   function _mapStateToProps(state) {
       const { message, score,isOpen } = state['features/callend'];
       
       return {
           _message: message,
           _score: score,
           _isOpen:isOpen
       };
   }
   
   export default translate(connect(_mapStateToProps)(EndCallBanner));
   