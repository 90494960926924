// @flow

import { SET_E2EE_KEY, TOGGLE_E2EE } from './actionTypes';

/**
 * Dispatches an action to set the E2EE key.
 *
 * @param {string|undefined} key - The new key to be used for E2EE.
 * @returns {Object}
 */
export function setE2EEKey(key: ?string) {
    return {
        type: SET_E2EE_KEY,
        key
    };
}

/**
 * Dispatches an action to enable / disable E2EE.
 *
 * @param {boolean} enabled - Whether E2EE is to be enabled or not.
 * @returns {Object}
 */
 export function toggleE2EE(enabled: boolean) {
    return {
        type: TOGGLE_E2EE,
        enabled
    };
}

