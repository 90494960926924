// @flow

import React from 'react';
import Transition from 'react-transition-group/Transition';

import { translate } from '../../../base/i18n';
import { Icon, IconClose } from '../../../base/icons';
import { connect } from '../../../base/redux';
import  '../web/notes.css';

import AbstractNote, {
    _mapDispatchToProps,
    _mapStateToProps,
    type Props
} from '../AbstractNote';
import TextArea from './TextArea';


/**
 * React Component for holding the chat feature in a side panel that slides in
 * and out of view.
 */
class NoteP extends AbstractNote<Props> {

    /**
     * Whether or not the {@code Chat} component is off-screen, having finished
     * its hiding animation.
     */
    _isExited: boolean;

    /**
     * Reference to the React Component for displaying chat messages. Used for
     * scrolling to the end of the chat messages.
     */
    _messageContainerRef: Object;

    /**
     * Initializes a new {@code Chat} instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props: Props) {
        super(props);
        this.state = {         
            notesText: "",
            inputValue:""                
        };
        this._isExited = true;
        this._messageContainerRef = React.createRef();

        // Bind event handlers so they are only bound once for every instance.
        this._renderPanelContent = this._renderPanelContent.bind(this);

        // Bind event handlers so they are only bound once for every instance.
        this._onChatInputResize = this._onChatInputResize.bind(this);
    }

    render() {
        return (
            <Transition
                in = { this.props._isOpen }
                timeout = { 500 }>
                { this._renderPanelContent }
            </Transition>
        );
    }

    _onChatInputResize: () => void;
 
    /**
     * Callback invoked when {@code ChatInput} changes height. Preserves
     * displaying the latest message if it is scrolled to.
     *
     * @private
     * @returns {void}
     */
    _onChatInputResize() {
        this._messageContainerRef.current.maybeUpdateBottomScroll();
    }

    /**
     * Returns a React Element for showing chat messages and a form to send new
     * chat messages.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderChat() {
        return (
            <>             
                {/* <TextArea onSearch = { this.state.inputValue } 
                changeInput={this.state.changeInput} /> */}
                <TextArea onSearch = { this.state.inputValue } 
                 onNameChange={this.changeInput} />
            </>
        );
    }
    changeInput = (e) => {
        let value = e.target.value;       
          this.setState({
            notesText: this.state.notesText.concat(' ')
          })
        this.setState({inputValue:value})        
      }

    /**
     * Instantiates a React Element to display at the top of {@code Chat} to
     * close {@code Chat}.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderChatHeader() {
        return (
            <div className = 'chat-header'>
                <h1>Notes</h1>
                {/* <input type="text" id="searching" placeholder="Searching...."  onChange={this.changeInput} value={this.props.inputValue}  /> */}
                <div
                  
                    className = 'chat-close'
                    onClick = { this.props._onToggleNotes}>
                    <Icon src = { IconClose } />
                </div>
            </div>
        );
    }

    _renderPanelContent: (string) => React$Node | null;


    _renderPanelContent(state) {
        this._isExited = state === 'exited';

        const { _isOpen, _showNamePrompt } = this.props;
        const ComponentToRender = !_isOpen && state === 'exited'
            ? null
            : (
                <>
                    { this._renderChatHeader() }
                    { _showNamePrompt
                        ? <DisplayNameForm /> : this._renderChat() }
                </>
            );
        let className = '';

        if (_isOpen) {
            className = 'slideInExt';
        } else if (this._isExited) {
            className = 'invisible';
        }

        return (
            <div
                className = { `sideToolbarContainer notebook-main  ${className}`}
                id = 'sideToolbarContainer'>
                { ComponentToRender }
            </div>
        );
    }

   
}

export default translate(connect(_mapStateToProps, _mapDispatchToProps)(NoteP));
