// @flow

import React, { Component } from 'react';
import InlineDialog from '@atlaskit/inline-dialog';
import {
    joinConference as joinConferenceAction,
    joinConferenceWithoutAudio as joinConferenceWithoutAudioAction,
    setSkipPrejoin as setSkipPrejoinAction,
    setJoinByPhoneDialogVisiblity as setJoinByPhoneDialogVisiblityAction,
    setBrandPageVisible
} from '../actions';
import { getRoomName } from '../../base/conference';
import { Icon, IconPhone, IconVolumeOff } from '../../base/icons';
import { translate } from '../../base/i18n';
import { connect } from '../../base/redux';
import { getDisplayName, updateSettings } from '../../base/settings';
import ActionButton from './buttons/ActionButton';
import {
    isJoinByPhoneButtonVisible,
    isDeviceStatusVisible,
    isJoinByPhoneDialogVisible,
    isSessionAllowed,
    getActiveVideoTrack,
    isBrandPageRequired
} from '../functions';
import { isGuest } from '../../invite';
import CopyMeetingUrl from './preview/CopyMeetingUrl';
import DeviceStatus from './preview/DeviceStatus';
import DeviceStatusNew from './preview/DeviceStatusNew';
import ParticipantName from './preview/ParticipantName';
import Preview from './preview/Preview';
import { VideoSettingsButton, AudioSettingsButton } from '../../toolbox';
import JoinByPhoneDialog from './dialogs/JoinByPhoneDialog';
import '../Prejoin.css';
import { getLocalVideoTrack } from '../../base/tracks';
import BrandParticipantName from './preview/BrandParticipantName';


type Props = {

    /**
     * Flag signaling if the device status is visible or not.
     */
    deviceStatusVisible: boolean,

    /**
     * If join by phone button should be visible.
     */
    hasJoinByPhoneButton: boolean,

    /**
     * Flag signaling if a user is logged in or not.
     */
    isAnonymousUser: boolean,

    /**
     * Joins the current meeting.
     */
    joinConference: Function,

    /**
     * Joins the current meeting without audio.
     */
    joinConferenceWithoutAudio: Function,

    /**
     * The name of the user that is about to join.
     */
    name: string,

    /**
     * Updates settings.
     */
    updateSettings: Function,

    /**
     * The name of the meeting that is about to be joined.
     */
    roomName: string,

    /**
     * Sets visibility of the prejoin page for the next sessions.
     */
    setSkipPrejoin: Function,

    /**
     * Sets visibility of the 'JoinByPhoneDialog'.
     */
    setJoinByPhoneDialogVisiblity: Function,

    /**
     * If 'JoinByPhoneDialog' is visible or not.
     */
    showDialog: boolean,

    /**
     * Used for translation.
     */
    t: Function,
    /**
         * Whether Audio Btn Should be visible
        */
    AudioBtnVisiblity: boolean,
    /**
     * Whether Video Btn Should be visible
     */
    VideoBtnVisiblity: boolean,
    /* True if the preview overlay should be muted, false otherwise.
    */
    videoMuted?: boolean,
    /**
     * The JitsiLocalTrack to display.
     */
    videoTrack: ?Object,

};

type State = {

    /**
     * Flag controlling the visibility of the 'join by phone' buttons.
     */
    showJoinByPhoneButtons: boolean
}

/**
 * This component is displayed before joining a meeting.
 */
class BrandPrejoin extends Component<Props, State> {
    /**
     * Initializes a new {@code Prejoin} instance.
     *
     * @inheritdoc
     */
    constructor(props) {
        super(props);

        this.state = {
            showJoinByPhoneButtons: false
        };

        this._closeDialog = this._closeDialog.bind(this);
        this._showDialog = this._showDialog.bind(this);
        this._onCheckboxChange = this._onCheckboxChange.bind(this);
        this._onDropdownClose = this._onDropdownClose.bind(this);
        this._onOptionsClick = this._onOptionsClick.bind(this);
        this._setName = this._setName.bind(this);
        this._proceedToJoin = this._proceedToJoin.bind(this);
    }

    componentDidMount() {

    }

    _onCheckboxChange: () => void;

    /**
     * Handler for the checkbox.
     *
     * @param {Object} e - The synthetic event.
     * @returns {void}
     */
    _onCheckboxChange(e) {
        this.props.setSkipPrejoin(e.target.checked);
    }

    _onDropdownClose: () => void;

    /**
     * Closes the dropdown.
     *
     * @returns {void}
     */
    _onDropdownClose() {
        this.setState({
            showJoinByPhoneButtons: false
        });
    }

    _onOptionsClick: () => void;

    /**
     * Displays the join by phone buttons dropdown.
     *
     * @param {Object} e - The synthetic event.
     * @returns {void}
     */
    _onOptionsClick(e) {
        e.stopPropagation();

        this.setState({
            showJoinByPhoneButtons: !this.state.showJoinByPhoneButtons
        });
    }

    _setName: () => void;

    /**
     * Sets the guest participant name.
     *
     * @param {string} displayName - Participant name.
     * @returns {void}
     */
    _setName(displayName) {
        this.props.updateSettings({
            displayName
        });
    }

    _closeDialog: () => void;

    /**
     * Closes the join by phone dialog.
     *
     * @returns {undefined}
     */
    _closeDialog() {
        this.props.setJoinByPhoneDialogVisiblity(false);
    }

    _showDialog: () => void;

    /**
     * Displays the dialog for joining a meeting by phone.
     *
     * @returns {undefined}
     */
    _showDialog() {
        this.props.setJoinByPhoneDialogVisiblity(true);
        this._onDropdownClose();
    }


    _proceedToJoin: () => void;

    /**
     * Closes the join by phone dialog.
     *
     * @returns {undefined}
     */
    _proceedToJoin() {
        APP.store.dispatch(setBrandPageVisible(false));
        this.props.joinConference();
    }

    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const {
            deviceStatusVisible,
            hasJoinByPhoneButton,
            isAnonymousUser,
            joinConference,
            joinConferenceWithoutAudio,
            name,
            showDialog,
            t,
            videoMuted, videoTrack,
            prejoinRoomData
        } = this.props;

        const logoUrl = prejoinRoomData && prejoinRoomData.slogourl ? prejoinRoomData.slogourl : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPz20HPQi1J_20LOekkuCllnqI0njFpDDR8QzCcjtWohy6LEO7oyHdxmsdrQ3BPuf4O7k&usqp=CAU"

        const stagline = prejoinRoomData && prejoinRoomData.stagline ? prejoinRoomData.stagline :
            ""

        const { _closeDialog, _onCheckboxChange, _onDropdownClose, _onOptionsClick, _setName, _showDialog } = this;
        const { showJoinByPhoneButtons } = this.state;

        return (
            <div className='prejoin-full-page'>
                <img className="prejoin_logo" src="https://dialtm.in/assets/images/dialtm.svg" />
                <img className="prejoin_right_logo" src={logoUrl ? logoUrl : ""} />
                {/* <Preview name = { name } /> */}
                <div className='prejoin_container mobile-full-page'>
                    <div className='prejoin_left'>
                        <Preview
                            videoMuted={videoMuted}
                            videoTrack={videoTrack} />


                        <div className='prejoin-btn-container'>
                            <AudioSettingsButton visible={this.props.AudioBtnVisiblity} />
                            <VideoSettingsButton visible={this.props.VideoBtnVisiblity} />
                        </div>
                        {deviceStatusVisible && <DeviceStatusNew />}
                    </div>
                    <div className='prejoin_right'>
                        <div className='p_joning_label'>
                            <h2 className='p_joning_label'>Welcome to Dialtm</h2>
                            <p className='p_joning_label'>
                                {stagline ? stagline : ""}
                            </p>
                        </div>
                        <h2 className='ready_lebel'>Ready to Join?</h2>
                        <BrandParticipantName
                            isEditable={isAnonymousUser}
                            joinConference={this._proceedToJoin}
                            setName={_setName}
                            value={name} />

                        <InlineDialog
                            content={<div className='prejoin-preview-dropdown-btns'>
                                <div
                                    className='prejoin-preview-dropdown-btn'
                                    onClick={joinConferenceWithoutAudio}>
                                    <Icon
                                        className='prejoin-preview-dropdown-icon'
                                        size={24}
                                        src={IconVolumeOff} />
                                    {t('prejoin.joinWithoutAudio')}
                                </div>
                                {hasJoinByPhoneButton && <div
                                    className='prejoin-preview-dropdown-btn'
                                    onClick={_showDialog}>
                                    <Icon
                                        className='prejoin-preview-dropdown-icon'
                                        size={24}
                                        src={IconPhone} />
                                    {t('prejoin.joinAudioByPhone')}
                                </div>}
                            </div>}
                            isOpen={showJoinByPhoneButtons}
                            onClose={_onDropdownClose}>
                            {this.props.isLicenseEnabled && <ActionButton
                                disabled={!name}
                                hasOptions={true}
                                onClick={this._proceedToJoin}
                                onOptionsClick={_onOptionsClick}
                                type='primary'>
                                {t('prejoin.joinMeeting')}
                            </ActionButton>}
                        </InlineDialog>
                        <CopyMeetingUrl />
                    </div>
                </div>

            </div>
        );
    }
}

/**
 * Maps (parts of) the redux state to the React {@code Component} props.
 *
 * @param {Object} state - The redux state.
 * @returns {Object}
 */
function mapStateToProps(state): Object {
    return {
        isAnonymousUser: isGuest(state),
        deviceStatusVisible: isDeviceStatusVisible(state),
        name: getDisplayName(state),
        roomName: getRoomName(state),
        showDialog: isJoinByPhoneDialogVisible(state),
        hasJoinByPhoneButton: isJoinByPhoneButtonVisible(state),
        isLicenseEnabled: isSessionAllowed(state),
        AudioBtnVisiblity: state['features/webinar']._isAudioBtnVisible,
        VideoBtnVisiblity: state['features/webinar']._isVideoBtnVisible,
        videoMuted: state['features/base/media'].video.muted,
        videoTrack: getActiveVideoTrack(state)

    };
}

const mapDispatchToProps = {
    joinConferenceWithoutAudio: joinConferenceWithoutAudioAction,
    joinConference: joinConferenceAction,
    setJoinByPhoneDialogVisiblity: setJoinByPhoneDialogVisiblityAction,
    setSkipPrejoin: setSkipPrejoinAction,
    updateSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(translate(BrandPrejoin));
