// @flow

import React, { Component } from 'react';
import { DialogWithTabs, hideDialog } from '../../base/dialog';
import { connect } from '../../base/redux';
//import ProfileTab from '../../settings/components/web/ProfileTab';
import { getMoreTabProps, getProfileTabProps } from '../../settings/functions';
//import { submitMoreTab, submitProfileTab } from '../../actions';
import { SETTINGS_TABS } from '../../settings/constants';
import UserInfoTab from './UserInfoTab';
import Dialog from '../../base/dialog/components/web/Dialog';
import { FieldTextStateless } from '@atlaskit/field-text';
import { openPollDialog, setQuizUser } from '../actions';
import validator from 'validator'
declare var APP: Object;
declare var interfaceConfig: Object;

/**
 * The type of the React {@code Component} props of
 * {@link ConnectedSettingsDialog}.
 */
type Props = {

    /**
     * Which settings tab should be initially displayed. If not defined then
     * the first tab will be displayed.
     */
    defaultTab: string,

    /**
     * Information about the tabs to be rendered.
     */
    _tabs: Array<Object>,
    t: Function,
    /**
     * Invoked to save changed settings.
     */
    dispatch: Function
};

/**
 * A React {@code Component} for displaying a dialog to modify local settings
 * and conference-wide (moderator) settings. This version is connected to
 * redux to get the current settings.
 *
 * @extends Component
 */
class QuizUserDialog extends Component<Props> {
    static defaultProps = {
        displayName: '',
        email: ''
    };



    /**
     * Initializes a new {@code ConnectedSettingsDialog} instance.
     *
     * @param {Props} props - The React {@code Component} props to initialize
     * the new {@code ConnectedSettingsDialog} instance with.
     */
    constructor(props: Props) {
        super(props);
        this.state = {
            displayName: '',
            email: ''
        }
        // Bind event handlers so they are only bound once for every instance.
        this.onSubmit = this.onSubmit.bind(this);
        this._onChange = this._onChange.bind(this);
    }

    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { _tabs, defaultTab, dispatch } = this.props;
        const onSubmit = this._closeDialog;
        const defaultTabIdx
            = _tabs.findIndex(({ name }) => name === defaultTab);
        const tabs = _tabs.map(tab => {
            return {
                ...tab,
                onMount: tab.onMount
                    ? (...args) => dispatch(tab.onMount(...args))
                    : undefined,
                submit: (...args) => tab.submit
                    && dispatch(tab.submit(...args))
            };
        });

        return (
            // <DialogWithTabs
            //     closeDialog = { this._closeDialog }
            //     cssClassName = 'settings-dialog'
            //     isModal = { false }        
            //     defaultTab = {
            //         defaultTabIdx === -1 ? undefined : defaultTabIdx
            //     }
            //     onSubmit = { onSubmit }
            //     tabs = { tabs }
            //     titleKey = 'settings.title' />

            <Dialog
                // disableBlanketClickDismiss={true}
                onSubmit={this.onSubmit}
                cancelDisabled={true}
                // backdrop='static'
                isModal={false}
                okKey='continue'
                titleKey='Host has started a quiz'>
                <div>
                    <div className='profile-edit'>
                        <div className='profile-edit-field'>
                            <FieldTextStateless
                                autoFocus={true}
                                compact={true}
                                id='setDisplayName'
                                label='Enter your name'
                                // eslint-disable-next-line react/jsx-no-bind
                                onChange={
                                    ({ target: { value } }) =>
                                        this._onChange({ displayName: value })
                                }
                                //placeholder = { t('settings.name') }
                                shouldFitContainer={true}
                                type='text'
                            // value = { this.props.displayName } 
                            />
                        </div>
                        <div className='profile-edit-field'>
                            <FieldTextStateless
                                compact={true}
                                id='setEmail'
                                label='Enter email address'
                                // eslint-disable-next-line react/jsx-no-bind
                                onChange={
                                    ({ target: { value } }) =>
                                        this._onChange({ email: value })
                                }
                                // placeholder = { t('profile.setEmailInput') }
                                shouldFitContainer={true}
                                type='text'
                            //  value = {  this.props.email }
                            />
                        </div>
                    </div>

                </div>
            </Dialog>
        );
    }

    _onChange: (Object) => void;

    /**
     * Uses the onTabStateChange function to pass the changed state of the
     * controlled tab component to the controlling DialogWithTabs component.
     *
     * @param {Object} change - Object that contains the changed property and
     * value.
     * @returns {void}
     */
    _onChange(change) {
        if (change.displayName) {
            this.setState({ displayName: change.displayName });
            //alert(this.state.displayName);
        }
        if (change.email) {
            this.setState({ email: change.email });
            //alert(this.state.email);

        }
    }


    onSubmit: () => void;

    /**
     * Callback invoked to close the dialog without saving changes.
     *
     * @private
     * @returns {void}
     */
    onSubmit() {

        if (this.state.displayName != '' && this.state.email != '') {
            if (validator.isEmail(this.state.email)) {
                this.props.dispatch(hideDialog());
                this.props.dispatch(setQuizUser(this.state.displayName, this.state.email));
                this.props.dispatch(openPollDialog());
            }
            else {
                alert("Please enter valid email address.");
            }
        }
        else {
            alert("Please enter your name and email address.");
        }
    }
}

/**
 * Maps (parts of) the Redux state to the associated props for the
 * {@code ConnectedSettingsDialog} component.
 *
 * @param {Object} state - The Redux state.
 * @private
 * @returns {{
 *     tabs: Array<Object>
 * }}
 */
function _mapStateToProps(state) {

    const tabs = [];




    tabs.push({
        name: SETTINGS_TABS.PROFILE,
        component: UserInfoTab,
        label: 'profile.title',
        props: getProfileTabProps(state),
        styles: 'settings-pane profile-pane',
        //  submit: submitProfileTab
    });



    return { _tabs: tabs };
}

export default connect(_mapStateToProps)(QuizUserDialog);
