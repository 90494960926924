
import React, { Component } from 'react';
import { connect } from '../../base/redux';
import type { Dispatch } from 'redux';
import { Dialog } from '../../base/dialog';
import { translate } from '../../base/i18n';
import { cancelPoll } from '../actions';
declare var APP: Object;
declare var interfaceConfig: Object;

const scoreAnimationClass
    = interfaceConfig.ENABLE_FEEDBACK_ANIMATION ? 'shake-rotate' : '';

/**
 * The scores to display for selecting. The score is the index in the array and
 * the value of the index is a translation key used for display in the dialog.
 *
 * @types {string[]}
 */


/**
 * The type of the React {@code Component} props of {@link callEnd}.
 */
type Props = {

    /**
     * The cached feedback message, if any, that was set when closing a previous
     * instance of {@code callEnd}.
     */
    _message: string,

    /**
     * The cached feedback score, if any, that was set when closing a previous
     * instance of {@code callEnd}.
     */
    _score: number,

    /**
     * The JitsiConference that is being rated. The conference is passed in
     * because feedback can occur after a conference has been left, so
     * references to it may no longer exist in redux.
     */
    conference: Object,

    openModal:Object,

    /**
     * Invoked to signal feedback submission or canceling.
     */
    dispatch: Dispatch<any>,

    /**
     * Callback invoked when {@code callEnd} is unmounted.
     */
    onClose: Function,

    /**
     * Invoked to obtain translated strings.
     */
    t: Function
};

/**
 * The type of the React {@code Component} state of {@link callEnd}.
 */


/**
 * A React {@code Component} for displaying a dialog to rate the current
 * conference quality, write a message describing the experience, and submit
 * the feedback.
 *
 * @extends Component
 */
class endcallconfermation extends Component<Props, State> {
    /**
     * An array of objects with click handlers for each of the scores listed in
     * the constant SCORES. This pattern is used for binding event handlers only
     * once for each score selection icon.
     */
    _scoreClickConfigurations: Array<Object>;

    /**
     * Initializes a new {@code callEnd} instance.
     *
     * @param {Object} props - The read-only React {@code Component} props with
     * which the new instance is to be initialized.
     */
    constructor(props: Props) {
        super(props);       
        const { _message, _score } = this.props;
        this.state = {
            /**
             * The currently entered feedback message.
             *
             * @type {string}
             */
            isLoaded:false,
            item:'',
            show:false,            
                title:''                    
        };

     
        this._onCancel = this._onCancel.bind(this);     
        this._onSubmit = this._onSubmit.bind(this);      
    }
   
    componentWillUnmount() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }
    
 

       
    render() {                
        return (
            <Dialog
            okKey = 'dialog.Yes'
                onSubmit = { this._onSubmit }
                onCancel = { this._onCancel }                      
                titleKey = 'callend.rateExperience'
                width = 'small'
                >                                  
                 
            </Dialog>
        );
    }

    _onCancel: () => boolean;

    /**
     * Dispatches an action notifying feedback was not submitted. The submitted
     * score will have one added as the rest of the app does not expect 0
     * indexing.
     *
     * @private
     * @returns {boolean} Returns true to close the dialog.
     */
    _onCancel() {
        const { message, score } = this.state;
        const scoreToSubmit = score > -1 ? score + 1 : score;

        this.props.dispatch(cancelPoll(scoreToSubmit, message));

        return true;
    }
    _onSubmit: () => void;

    /**
     * Dispatches the entered feedback for submission. The submitted score will
     * have one added as the rest of the app does not expect 0 indexing.
     *
     * @private
     * @returns {boolean} Returns true to close the dialog.
     */
    _onSubmit() {
        APP.conference.hangupAfterConfirm();
        return true;
    }

   
}

/**
 * Maps (parts of) the Redux state to the associated {@code callEnd}'s
 * props.
 *callend
 * @param {Object} state - The Redux state.
 * @private
 * @returns {{
 * }}
 */
function _mapStateToProps(state) {
    const { message, score } = state['features/endcall-confermation-dialog'];

    return {
        /**
         * The cached feedback message, if any, that was set when closing a
         * previous instance of {@code callEnd}.
         *
         * @type {string}
         */
        _message: message,

        /**
         * The currently selected score selection index.
         *
         * @type {number}
         */
        _score: score
    };
}

export default translate(connect(_mapStateToProps)(endcallconfermation));
