import axios from "axios";
import { jitsiLocalStorage } from "js-utils";
import React, { Component } from "react";
import { PureComponent } from "react";
import { getAPIURL, getRoomName } from "../../base/conference";

import { Dialog } from "../../base/dialog";
import { connect } from "../../base/redux";
import { captureLargeVideoScreenshot } from "../../large-video";
import CapturedScreenshots from "./CapturedScreenshots";
import ReactLoading from "react-loading";
import { getLocalParticipant } from "../../base/participants";
import VideoLayout from "../../../../modules/UI/videolayout/VideoLayout";
import Modal, {
    ModalBody,
    ModalHeader,
    ModalTitle,
    ModalTransition,
} from "@atlaskit/modal-dialog";
import Button from "@atlaskit/button";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { N500 } from "@atlaskit/theme/colors";
import EditorWarningIcon from "@atlaskit/icon/glyph/editor/warning";

/**
 * Implements a React {@link Component} which displays the component
 * {@code VideoQualitySlider} in a dialog.
 *
 * @extends Component
 */
class ScreenshotDialog<P: Props = Props> extends PureComponent<P, State> {
    constructor(props: P) {
        super(props);

        type Props = {
            _conferenceName: "",
            localParticipantId: null,
            _defaultAPIUrl: string,
        };
        this.state = {
            /**
             * The score selection index which is currently being hovered. The
             * value -1 is used as a sentinel value to match store behavior of
             * using -1 for no score having been selected.
             *
             * @type {number}
             */
            mousedOverScore: -1,
            storedImages: [],
            localImages: [],
            options: [],
            isUploading: false,
            selected_resource: {
                url: "/images/virtual-background/background-6.jpg",
                selected: true,
            },
            selected_resource_type: "image",
            images: [
                // {
                //     url: "/images/virtual-background/background-2.jpg",
                //     selected: true
                // },
                // {
                //     url: "/images/virtual-background/background-3.jpg",
                //     selected: false
                // },
                // {
                //     url: "/images/virtual-background/background-4.jpg",
                //     selected: false
                // },
                // {
                //     url: "/images/virtual-background/background-5.jpg",
                //     selected: false
                // },
                // {
                //     url: "/images/virtual-background/background-6.jpg",
                //     selected: false
                // },
                // {
                //     url: "/images/virtual-background/background-7.jpg",
                //     selected: false
                // }
            ],
            captureError: false,
        };
        this._captureScreenshot = this._captureScreenshot.bind(this);
        this._updateView = this._updateView.bind(this);
    }

    _captureScreenshot: () => void;

    _captureScreenshot() {
        var isVideoPlaying = VideoLayout.isLargeVideoPlaying();
        if (!isVideoPlaying) {
            this.setState({
                captureError: true,
            });
        } else {
            console.log("will capture screenshot");
            APP.store
                .dispatch(captureLargeVideoScreenshot())
                .then((dataURL) => {
                    let error;
                    if (!dataURL) {
                        error = new Error("No large video found!");
                    }
                    var roomName = this.props._conferenceName;
                    fetch(dataURL)
                        .then((res) => res.blob())
                        .then((blob) => {
                            var based64 = dataURL;
                            based64 = based64.replace(
                                "data:image/png;base64,",
                                ""
                            );
                            // const data = new FormData()
                            // data.append('files', based64);

                            const participantId = this.props.localParticipantId;
                            const payLoad = {
                                userRefId: participantId,
                                sessionRefId: "session213",
                                roomRefId: roomName,
                                capturerUserRefId: participantId,
                                files: [based64],
                            };

                            this.setState({ isUploading: true });
                            this.forceUpdate();
                            var apiDomain = this.props._defaultAPIUrl;
                            axios
                                .post(
                                    apiDomain +
                                        "/snapshot/saveEncodedSnapShots/" +
                                        roomName,
                                    payLoad
                                )
                                .then((res) => {
                                    // then print response status
                                    const varStoredImages =
                                        this.state.storedImages;
                                    varStoredImages.push({ data: dataURL });
                                    this.setState({ images: varStoredImages });
                                    // jitsiLocalStorage.setItem('screenshots', JSON.stringify(varStoredImages));
                                    this.setState({ isUploading: false });
                                    this.forceUpdate();
                                });
                        });
                });
        }
    }

    _updateView: () => void;

    _updateView() {
        this.setState({ images1: "" });
    }

    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        return (
            <React.Fragment>
                <ModalTransition>
                    {this.state.captureError && (
                        <Modal
                            onClose={() =>
                                this.setState({
                                    captureError: false,
                                })
                            }
                            width="small"
                            shouldCloseOnOverlayClick={false}
                        >
                            <ModalHeader>
                                <ModalTitle>Turn on video</ModalTitle>
                                <Button
                                    appearance="link"
                                    onClick={() =>
                                        this.setState({
                                            captureError: false,
                                        })
                                    }
                                >
                                    <CrossIcon
                                        label="Close Modal"
                                        primaryColor={N500}
                                    />
                                </Button>
                            </ModalHeader>
                            <ModalBody>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginBottom: "2em",
                                    }}
                                >
                                    <EditorWarningIcon />
                                    <span>
                                        Please turn on video before capturing
                                        screenshot!
                                    </span>
                                </div>
                            </ModalBody>
                        </Modal>
                    )}
                </ModalTransition>
                <Dialog
                    okKey="Capture"
                    onSubmit={this._captureScreenshot}
                    width="large"
                    titleKey="Capture Screenshot"
                >
                    <div className="feedback-dialog">
                        <div className="virtual-background-dialog">
                            {this.state.images.map((item, index) => {
                                return (
                                    <div
                                        className="selectable you"
                                        onClick={() => {
                                            this.selectImage(item, index);
                                        }}
                                    >
                                        <img
                                            className="thumbnail"
                                            src={item.data}
                                        />
                                    </div>
                                );
                            })}
                            {this.state.isUploading && (
                                <div
                                    className="imageCapturing"
                                    style={{ height: "100%", width: "100%" }}
                                >
                                    <ReactLoading color="blue" />
                                </div>
                            )}
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}
function _mapStateToProps(state) {
    const localParticipant = getLocalParticipant(state);

    return {
        _conferenceName: getRoomName(state),
        localParticipantId: localParticipant.id,
        _defaultAPIUrl:getAPIURL(state)
    };
}

export default connect(_mapStateToProps)(ScreenshotDialog);
