import { SET_ACTIVE_MODAL_ID } from '../base/modal';
import { PARTICIPANT_JOINED } from '../base/participants';
import { ReducerRegistry } from '../base/redux';
import { cancelQuizDialog } from './actions';

import {
   
    SHOW_POLL,
    STOP_QUIZ,
    SET_QUIZ_USER
} from './actionTypes';

const DEFAULT_STATE = {
    show: false,
    isPollStarted:false,
    quizUserName:'',
    quizUserEmail:''
}

ReducerRegistry.register('features/reduxPoll', (state = DEFAULT_STATE, action) => {
    switch (action.type) {    
    case  SHOW_POLL :
        return updatePollState(state);
    case STOP_QUIZ:
        return cancelQuiz();
    case PARTICIPANT_JOINED: 
        return processForJoined(state,action);
    case SET_QUIZ_USER:
        return setUserInfo(state,action.username,action.email);
    }
    return state;
});

function processForJoined(state,action)
{
    const { participant } = action;
    const { id, local, name } = participant;
    if(!local)
    {
        sendQuizRequest(state,id);
    }
    return{
        ...state
    }
}
/**
 * Updates the chat status on opening the chat view.
 *
 * @param {Object} state - The Redux state of the feature.
 * @returns {Object}
 */
function updatePollState(state) {
    return {
        ...state,
        show: !state.show,
        isPollStarted:true
    };
}

function sendQuizInformation(id)
{
    debugger;
    APP.conference.showPollForNewMember(id);
}

function cancelQuiz(state)
{
    return {
        ...state,
        show: false,
        isPollStarted:false
    }; 
}

function sendQuizRequest(state,id)
{
    if(state.isPollStarted)
    {
        debugger;
        setTimeout( () => {sendQuizInformation(id)},10000);

    }
}

function setUserInfo(state,_username,_email)
{
    return {
        ...state,
        quizUserName: _username,
        quizUserEmail:_email
    };   
}