import { SET_ACTIVE_MODAL_ID } from '../base/modal';
import { ReducerRegistry } from '../base/redux';
import { PARTICIPANT_JOINED } from '../base/participants';

import {
   
    SHOW_VOTE_POLL,
    STOP_POLL,
} from './actionTypes';

const DEFAULT_STATE = {
    show: false,
    isVotePollStarted:false,
}

ReducerRegistry.register('features/voting', (state = DEFAULT_STATE, action) => {
    switch (action.type) {    
    case  SHOW_VOTE_POLL :
        return updatePollState(state);
        case PARTICIPANT_JOINED: 
        return processForJoined(state,action);
        case STOP_POLL:
            return cancelQuiz();
    }

    return state;
});


function processForJoined(state,action)
{
    const { participant } = action;
    const { id, local, name } = participant;
    if(!local)
    {
        sendQuizRequest(state,id);
      
    }
    return{
        ...state
    }
}

function cancelQuiz(state)
{
    return {
        ...state,
        show: false,
        isVotePollStarted:false
    }; 
}

function sendQuizRequest(state,id)
{
    if(state.isVotePollStarted)
    {

        setTimeout(()=>{
            APP.conference.showVotingPollForNewMember(id);

        },10000);
    }
}

/**
 * Updates the chat status on opening the chat view.
 *
 * @param {Object} state - The Redux state of the feature.
 * @returns {Object}
 */
function updatePollState(state) {
    return {
        ...state,
        show: !state.show,
        isVotePollStarted:true
        
    };
}