// @flow

import { translate } from '../../../base/i18n';
import { IconSwitchCamera } from '../../../base/icons';
import { MEDIA_TYPE, toggleCameraFacingMode } from '../../../base/media';
import { connect } from '../../../base/redux';
import { AbstractButton } from '../../../base/toolbox';
import type { AbstractButtonProps } from '../../../base/toolbox';
import { isLocalTrackMuted } from '../../../base/tracks';
import { getCurrentCameraDeviceId } from '../../../base/settings';
import {
    getUserSelectedCameraDeviceId,
    getUserSelectedMicDeviceId
} from '../../../base/settings';
import {
    PostMessageTransportBackend,
    Transport
} from '../../../../../modules/transport';
import {
    getAvailableDevices,
    getCurrentDevices,
    isDeviceChangeAvailable,
    isDeviceListAvailable,
    isMultipleAudioInputSupported,
    setAudioInputDevice,
    setAudioOutputDevice,
    setVideoInputDevice,
    setVideoInputDeviceAndUpdateSettings
} from '../../../base/devices';
import JitsiMeetJS from '../../../base/lib-jitsi-meet';
import {
    VIDEO_MUTISM_AUTHORITY,
    setVideoMuted
} from '../../../base/media';

/**
 * The type of the React {@code Component} props of {@link ToggleCameraButton}.
 */
type Props = AbstractButtonProps & {

    /**
     * Whether the current conference is in audio only mode or not.
     */
    _audioOnly: boolean,

    /**
     * Whether video is currently muted or not.
     */
    _videoMuted: boolean,

    /**
     * The redux {@code dispatch} function.
     */
    dispatch: Function
};

/**
 * An implementation of a button for toggling the camera facing mode.
 */
class ToggleCameraButton extends AbstractButton<Props, *> {
    accessibilityLabel = 'toolbar.accessibilityLabel.toggleCamera';
    icon = IconSwitchCamera;
    label = 'toolbar.toggleCamera';

    /**
     * Handles clicking / pressing the button.
     *
     * @override
     * @protected
     * @returns {void}
     */
    _handleClick() {

        const { mediaDevices,mediaStreamsMetaData ,stopMediaStream} = JitsiMeetJS;
        var cameras=[];
        if (mediaDevices.isDeviceListAvailable()
                && mediaDevices.isDeviceChangeAvailable()) {
            mediaDevices.enumerateDevices(devices => {
                console.log(devices);
               // dispatch(updateDeviceList(devices));
              //  resolve(devices);

            
              devices.forEach(device => {
                  if(device.kind=="videoinput")   
                  {
                    cameras.push(device);
                  } 
                });


            const selectedCamera = getUserSelectedCameraDeviceId(APP.store.getState());

             var selectedDirection=null;   
            // check current selection
            cameras.forEach(cam=>{
             var label =   cam.label;
             
             if(cam.deviceId==selectedCamera)
             {
                selectedDirection =  label.includes("front")?"front":"back";  
             }
             
            });

            //this.props.dispatch(setVideoMuted(true));
           
            cameras.forEach(cam=>{
                if(cam.deviceId!=selectedCamera && !cam.label.includes(selectedDirection))
                {
                    // mediaStreamsMetaData.forEach(({ stream }) => {
                    //     stopMediaStream(stream);
                    // });
                   
                    this.props.dispatch(setVideoInputDeviceAndUpdateSettings(cam.deviceId));
                }
            });
            APP.API.notifyOnCameraSwitched(selectedDirection);

            });

            
        }

        // getAvailableDevices().then(function(result) {
        //     // here you can use the result of promiseB
        //     console.log(result);
        // });

        // var devices =getAvailableDevices();
        // getAvailableDevices().then(devices =>{

        //     devices.forEach(element => {
                
        //     });

        // });
       // this.props.dispatch(toggleCameraFacingMode());
    }

    /**
     * Indicates whether this button is disabled or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _isDisabled() {
        return this.props._audioOnly || this.props._videoMuted;
    }
}

/**
 * Maps (parts of) the redux state to the associated props for the
 * {@code ToggleCameraButton} component.
 *
 * @param {Object} state - The Redux state.
 * @private
 * @returns {{
 *     _audioOnly: boolean,
 *     _videoMuted: boolean
 * }}
 */
function _mapStateToProps(state): Object {
    const { enabled: audioOnly } = state['features/base/audio-only'];
    const tracks = state['features/base/tracks'];

    return {
        _audioOnly: Boolean(audioOnly),
        _videoMuted: isLocalTrackMuted(tracks, MEDIA_TYPE.VIDEO)
    };
}

export default translate(connect(_mapStateToProps)(ToggleCameraButton));
