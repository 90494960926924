import React, { Component } from 'react';
import LaunchPoll from './LaunchPoll';
import {connect} from 'react-redux';
import {toggleReduxPoll} from '../middleware';


const mapDispatchToProps = dispatch => ({
    hideModal: () => dispatch(toggleReduxPoll()),
    showModal: () => {
      dispatch(toggleReduxPoll())
    }
  })
class PollModal extends React.Component{
constructor(props){debugger
    super(props)
    this.state={
        show:false
    }
}
  handelModel() {
   this.setState({show: ! this.props.showModal() })     
  }
    render(){

        let single="";    
        if(this.props.data !== ""){
           
            this.props.data.map(ele =>{debugger
            if(ele.question != " " || ele.question != null ||ele.questionType != undefined)
                {
                    single=   <LaunchPoll data={this.props.data}
                    setRadioAnswers={this.props.setRadioAnswers}
                    chekedvalue={this.props.chekedvalue}
                    hiddenValue={this.props.hiddenValue}
                    onchangeCheckBox={this.props.onchangeCheckBox}
                    setFreetextAns={this.props.setFreetextAns}
                    clickNextButton={this.props.handleAnswerOptionClick}
                    validatedata={this.props.validatedata}
                    submitAnswer={this.props.submitAnswer}             
                   
            />
                }
            })
       }        
      return(
           <>   
        {single}
        </>);
    }
}


export default connect(null, mapDispatchToProps)(PollModal)